// import * as React from 'react';

// import Button from '@mui/material/Button';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';

// function ButtonField(props) {
//   const {
//     setOpen,
//     label,
//     id,
//     disabled,
//     InputProps: { ref } = {},
//     inputProps: { 'aria-label': ariaLabel } = {},
//   } = props;

//   return (
//     <Button
//       variant="contained"
//       id={id}
//       disabled={disabled}
//       ref={ref}
//       aria-label={ariaLabel}
//       onClick={() => setOpen?.((prev) => !prev)}
//     >
//       {label ? `Current date: ${label}` : <CalendarTodayOutlinedIcon fontSize="medium" />}
//     </Button>
//   );
// }

// function ButtonDatePicker(props) {
//   const [open, setOpen] = React.useState(false);

//   return (
//     <DatePicker
//       slots={{ ...props.slots, field: ButtonField }}
//       slotProps={{ ...props.slotProps, field: { setOpen } }}
//       {...props}
//       open={open}
//       onClose={() => setOpen(false)}
//       onOpen={() => setOpen(true)}
//     />
//   );
// }

// export default function PickerWithButtonField() {
//   const [value, setValue] = React.useState(null);

//   return (
//     <LocalizationProvider dateAdapter={AdapterDayjs}>
//       <ButtonDatePicker
//         label={value == null ? null : value.format('MM/DD/YYYY')}
//         value={value}
//         onChange={(newValue) => setValue(newValue)}
//       />
//     </LocalizationProvider>
//   );
// }


import React, { useState } from "react";
import { Hidden, IconButton, alpha } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { ReactComponent as Calender } from "../../../assets/images/calendar.svg";
import { ReactComponent as Clock } from "../../../assets/images/clock.svg";
import dayjs from "dayjs"; // Import dayjs
import theme from "src/config/theme";
import { ReactComponent as ArrowDown } from '../../../assets/images/arrow-down.svg';
import { ReactComponent as ArrowLeft } from '../../../assets/images/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../../assets/images/arrow-right.svg';


const DateTimePicker = ({ label, value, onChange, type = "date", sx = {}, minDate = null }) => {
  const [open, setOpen] = useState(false); // State to handle open

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} >
      {type === "date" ? (
        <DatePicker
          value={value ? dayjs(value) : null} // Ensure dayjs object
          onChange={(newValue) => {
            setOpen(false); // Close picker on selection
            onChange(newValue);
          }}
          format="DD/MM/YYYY"
          minDate={minDate ? dayjs(minDate) : null}
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          slots={{
            switchViewIcon: ArrowDown,
            leftArrowIcon: ArrowLeft,
            rightArrowIcon: ArrowRight
          }}
          slotProps={{
            textField: {
              fullWidth: true,
              label: label,
              readOnly: true, // Prevent manual typing
              sx: {
                borderRadius: "12px",
                "& .MuiOutlinedInput-root": {
                  height: "52px",
                  display: "flex",
                  alignItems: "center",
                  ...sx,
                },
              },
              InputProps: {
                endAdornment: (
                  <IconButton
                    onClick={() => setOpen(true)} // Open Picker
                    sx={{
                      padding: 0,
                      minWidth: "auto",
                      "& .MuiSvgIcon-root": {
                        fill: "red",
                      },
                    }}
                  >
                    <Calender width={24} height={24} />
                  </IconButton>
                ),
              },
            },
            switchViewButton: {
              sx: {
                "& .MuiSvgIcon-root": {
                  width: 24,
                  height: 24,
                  display: 'none'
                }
              }
            },
            leftArrowButton: {
              sx: {
                "& .MuiSvgIcon-root": {
                  width: 20,
                  height: 20,
                }
              }
            },
            rightArrowButton: {
              sx: {
                "& .MuiSvgIcon-root": {
                  width: 20,
                  height: 20,
                }
              }
            },
            desktopPaper: {
              sx: {
                "& .MuiDateCalendar-root": {
                  backgroundColor: theme.palette.gery.white,
                  border: `1px solid ${alpha(theme.palette.gery.black, 0.1)}`,
                  borderRadius: '12px',
                  boxShadow: theme.shadows[4],
                  // width: '280px',
                  // height: 'auto',
                  // padding: '18px 16px 22px 16px',
                  // gap: '14px'
                },

                "& .MuiPickersCalendarHeader-root": {
                  // width: "248px",
                  // height: "24px",
                  // display: "flex",
                  // padding: 0,
                  // marginTop: 0,
                  // position: "relative",

                  "&::after": {
                    content: '""',
                    position: "absolute",
                    // bottom: "-12px",
                    left: 0,
                    width: "100%",
                    height: "1px", // Line thickness
                    backgroundColor: alpha(theme.palette.gery.black, 0.1),
                    marginTop: '45px'
                  },
                },

                "& .MuiPickersFadeTransitionGroup-root": {
                  // overflow: 'hidden',

                },


                "& .MuiPickersDay-root.Mui-selected": {
                  // backgroundColor: "#6a1b9a !important", // Change selected date color
                  // color: "#fff",
                  // width: '40px',
                },
                "& .MuiDayCalendar-weekDayLabel": {
                  ...theme.typography.smallRegular,
                  color: alpha(theme.palette.gery.black, 0.7),


                },
                "& .MuiPickersDay-root": {
                  ...theme.typography.smallRegular,
                  color: alpha(theme.palette.gery.black, 0.7),
                  // width: '32px',
                  // height: '20px',

                  "&.Mui-selected": {
                    color: "#fff !important",
                  },

                  // desktopPaper: {
                  //   sx: {
                  //     "& .MuiDayCalendar-weekContainer": {
                  //       margin: '1px 0',
                  //       gap: '14px',
                  //     },
                  //   },
                  // },

                },
              },
            },
          }}
        />
      ) : (
        <TimePicker
          value={value ? dayjs(value) : null} // Ensure dayjs object
          onChange={(newValue) => {
            setOpen(false); // Close picker on selection
            onChange(newValue);
          }}
          format="hh:mm A"
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          slotProps={{
            textField: {
              fullWidth: true,
              label: label,
              readOnly: true, // Prevent manual typing
              sx: {
                height: "52px",
                borderRadius: "12px",
                "& .MuiOutlinedInput-root": {
                  height: "52px",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "12px",
                  ...sx,
                },
              },
              InputProps: {
                endAdornment: (
                  <IconButton
                    onClick={() => setOpen(true)} // Open Picker
                    sx={{
                      padding: 0,
                      minWidth: "auto",
                      "& .MuiSvgIcon-root": {
                        fill: "red",
                      },
                    }}
                  >
                    <Clock width={24} height={24} />
                  </IconButton>
                ),
              },
            },
          }}
        />
      )}
    </LocalizationProvider>
  );
};

export default DateTimePicker;



