import { styled } from "@mui/material/styles";
import { Avatar, Box, Typography, alpha, Chip, IconButton } from "@mui/material";
import theme from "src/config/theme";

export const CardContainer = styled(Box)({

    width: '300px',
    height: '445px',
    borderRadius: '12px',
    border: `1px solid ${theme.palette.primary.light1}`,
    boxShadow: theme.shadows[1],
    backgroundColor: theme.palette.gery.white,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    // gap: '15px',
    padding: '10px 0',
    justifyContent: 'space-around',


})
export const CardHeader = styled(Box)({
    width: '209px',
    height: '82px',
    // backgroundColor: theme.palette.primary.light4,
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    padding: '20px',
})
export const ProfileContainer = styled(Avatar)({
    width: '82px',
    height: '82px',
    padding: '10px',
    borderRadius: '40px',
    backgroundColor: theme.palette.primary.light4,
    ...theme.typography.h1,
    color: theme.palette.primary.main,
})
export const InnerContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    width: '111px',
    height: '46px',
    gap: '4px',
})
export const Title = styled(Typography)({
    ...theme.typography.h5,
})

export const Year_Gender = styled(Box)({
    display: 'flex',
    gap: '6px',
    width: '200px',
    height: '20px',
    alignItems: 'center',
})
export const Title2 = styled(Typography)({
    ...theme.typography.smallRegular,
    color: `${alpha(theme.palette.gery.black, 0.7)}`,

})
export const Dot = styled(Box)({
    width: '5px',
    height: '5px',
    borderRadius: '50%',
    backgroundColor: theme.palette.gery.lightGray
})
export const Line = styled(Box)({
    width: '300px',
    border: `1px solid ${alpha(theme.palette.gery.black, 0.102)}`
})

export const InnerContainer2 = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 20px',
    alignItems: 'center',
})
export const Title3 = styled(Typography)({
    ...theme.typography.body1,
    color: `${alpha(theme.palette.gery.black, 0.7)}`
})
export const SymptomsContainer = styled(Box)({
    width: 'auto',
    height: '112px',
    borderRadius: '12px',
    backgroundColor: theme.palette.primary.light4,
    margin: '0px 5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
})

export const SymptomsText = styled(Typography)({
    ...theme.typography.body1,
    color: `${alpha(theme.palette.gery.black, 0.70)}`,
})

export const CheapContainer = styled(Chip)({
    background: "linear-gradient(0deg, rgba(150, 57, 150, 0.12), rgba(150, 57, 150, 0.12))",
    color: theme.palette.action.purpleDark,
    ...theme.typography.small,
    borderRadius: '100px',
    // padding: '10px 18px 8px 18px',
})

export const MenuContainer = styled(IconButton)(({ theme, isActive }) => ({
    width: '40px',
    height: '40px',
    borderRadius: '100px',
    color: theme.palette.primary.main,
    backgroundColor: isActive ? theme.palette.primary.main : theme.palette.primary.light4,
    color: isActive ? theme.palette.gery.white : theme.palette.primary.main,
    transition: "background-color 0.3s ease-in-out",
}));


export const AvatarIcon = styled(Avatar)({
    width: '64px',
    height: '64px',
    padding: '10px',
    borderRadius: '40px',
    background: 'linear-gradient(0deg, rgba(150, 57, 57, 0.18), rgba(150, 57, 57, 0.18)), #FFFFFF',
    marginBottom: '5px'
})

