import { jwtDecode } from 'jwt-decode';

export const getUserFromToken = () => {
    const token = localStorage.getItem('token');
    if (!token) return null;
    try {
        const decodedToken = jwtDecode(token);
        return decodedToken?.user;
    } catch (error) {
        console.error("Error decoding token:", error);
        return null;
    }
};

export const getStaffFromToken = () => {
    const token = localStorage.getItem('token');
    if (!token) return null;
    try {
        const decodedToken = jwtDecode(token);
        return decodedToken?.staff;
    } catch (error) {
        console.error("Error decoding token:", error);
        return null;
    }
};