import React, { useEffect, useState } from "react";
import CustomTable from "src/components/App/AppGrid/CustomTable/CustomTable";
import { Avatar, Chip, Box, alpha, DialogContent, Typography } from "@mui/material";
import theme from "src/config/theme";
import ButtonComponent from "src/components/shared/Button";
import DialogBox from "src/components/shared/Dialog/DialogBox";
import NotesTabForm from "../Forms/NotesTabForm";
import { ReactComponent as Edit } from '../../../../../assets/images/edit-2.svg';
import { ReactComponent as Delete } from '../../../../../assets/images/trash.svg';
import { ReactComponent as Add } from '../../../../../assets/images/add-square.svg';
import NoDataFound from "../../NoDataFound/NoDataFound";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { getMedicalRecordsRequest, deleteMedicalRecordRequest } from "src/modules/app/store/appActions";
import { HeaderPart, FooterPart, AvatarIcon } from "../Forms/Style";
import { ReactComponent as CloseIcon } from '../../../../../../src/assets/images/close-circle.svg';
import { ReactComponent as AddIcon } from '../../../../../../src/assets/images/add-square.svg';
import { ReactComponent as Danger } from '../../../../../../src/assets/images/danger.svg'
import { palette, shadows } from 'src/config/theme.js';

const columns = [
    { id: "note", label: "Appointments", sx: { width: "1200px", minWidth: "150px", textAlign: 'left' } },
    { id: "addedDate", label: "Added Date", sx: { "& .css-axw7ok": { justifyContent: 'center' } } },
    // { id: "addedBy", label: "Added By", sx: { "& .css-axw7ok": { justifyContent: 'center' } } },

];

const Notes = () => {
    const { appointmentId } = useParams();
    const dispatch = useDispatch();
    const [notes, setNotes] = useState([]);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteNoteId, setDeleteNoteId] = useState(null);

    const handleOpenDeleteDialog = (notesId) => {
        setDeleteNoteId(notesId);
        setOpenDeleteDialog(true);
    };
    const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);
    const appointmentData = useSelector((state) => state.app.tokenAppointmentsById);
    const appointmentDetails = appointmentData?.payload?.data?.tokenappointment;
    const PatientId = appointmentDetails?.patient ?? null;
    const medicalRecords = useSelector((state) => state.app.medicalRecordsByPatientId);


    const updateNotes = (newNote) => {
        setNotes((prevNotes) => [newNote, ...prevNotes]);
    }
    useEffect(() => {
        const fetchData = async () => {
            const response = await new Promise((resolve, reject) => {
                dispatch(getMedicalRecordsRequest(PatientId, resolve, reject));
            });
            if (response || response.data) {
                const filteredData = response.filter(notes => notes.recordType === 'note');
                setNotes(filteredData);
                console.log("my notes filtered data", filteredData)
            } else {
                console.log('Error fetching notes');
            }
        }

        fetchData();
    }, [PatientId, dispatch]);

    const handleDelete = async (notesId) => {
        if (notesId) {
            try {
                await dispatch(deleteMedicalRecordRequest(notesId));
                console.log("🚀 Dispatching delete action for ID:", notesId);
                setNotes((prevNotes) => prevNotes.filter(note => note.id !== notesId));
                setOpenDeleteDialog(false);
                console.log('Note deleted successfully');
            } catch (error) {
                console.error('Failed to delete note:', error);
            }
        }
    };
    const actions = [
        // { label: "Edit", icon: <Edit />, onClick: (row) => alert(`Viewing ${row.patient}`) },
        { label: "Delete", icon: <Delete />, onClick: (row) => handleOpenDeleteDialog(row.notesId) },

    ];
    const data = notes.map((notesData) => ({
        notesId: notesData?.id,
        note: notesData?.notes,
        addedDate: new Date(notesData.date).toLocaleDateString('en-GB', {
            day: '2-digit', month: 'short', year: 'numeric'
        }) + `, ${notesData.time}`,
        // addedBy: 'John Doe',
    }))


    return (
        <>
            <Box sx={{
                height: '756px',
                boxShadow: theme.shadows[1],
                borderRadius: '10px',
                border: `1px solid ${alpha(theme.palette.gery.black, 0.1)}`,
                backgroundColor: theme.palette.gery.white,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                position: 'relative'
            }}>


                <CustomTable columns={columns} data={data} actions={actions}
                    headerButton={
                        <ButtonComponent
                            variant="contained"
                            endIcon={<Add />}
                            onClick={handleOpen}
                            sx={{
                                background: theme.palette.primary.main,
                                border: `1px solid ${alpha(theme.palette.gery.black, 0.14)}`,
                                boxShadow: theme.shadows[1],
                                width: '122px',
                                height: '36px',
                                padding: '6px 6px 6px 10px',
                                borderRadius: '12px',
                                ...theme.typography.h5,
                                // position: 'absolute',
                                // left: '370px'
                                // left: 'auto'
                            }}
                        >Add Note</ButtonComponent>
                    }
                    noDataComponent={
                        <NoDataFound
                            title="No Notes Found!"
                            buttonText="Add Note"
                            buttonIcon={Add}
                            onClick={handleOpen}
                        />
                    }
                />

                <DialogBox open={open} onClose={handleClose} >
                    <NotesTabForm onClose={handleClose} updateNotes={updateNotes} />
                </DialogBox>

                <DialogBox open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                    <HeaderPart>
                        Delete Note
                        <CloseIcon onClick={handleCloseDeleteDialog} style={{ cursor: 'pointer' }} />
                    </HeaderPart>
                    <DialogContent dividers >
                        <Box sx={{ width: '470px', height: '314px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                            <Box sx={{ width: '232px', height: '186px', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', }}>
                                <AvatarIcon sx={{ bgcolor: "transparent" }}>
                                    <Danger width="24px" height="24px" />
                                </AvatarIcon>
                                <Typography variant="h5">Delete Appointment</Typography>
                                <Typography sx={{ ...theme.typography.smallRegular, color: `${alpha(theme.palette.gery.black, 0.7)}`, textAlign: 'center', marginTop: '10px' }}>
                                    Are you sure you want to Delete Notes

                                    {/* <Typography sx={{ ...theme.typography.smallRegular, color: theme.palette.gery.black, marginTop: '5px' }}>
                                        {selectedPatient ? `${selectedPatient.fullName} (${selectedPatient.contactNo})` : "Loading..."}
                                    </Typography> */}
                                </Typography>
                            </Box>

                        </Box>



                    </DialogContent>

                    <FooterPart >
                        <ButtonComponent
                            variant="none"
                            startIcon={<CloseIcon sx={{ color: palette.primary.main }} />}
                            onClick={handleCloseDeleteDialog}
                        >
                            Cancel
                        </ButtonComponent>
                        <ButtonComponent
                            type="submit"
                            variant="outlined"
                            startIcon={<AddIcon sx={{ color: palette.primary.main }} />}
                            sx={{
                                width: '104px',
                                height: '52px',
                                borderRadius: '12px',
                                padding: '10px 18px',
                                bgcolor: palette.primary.main,
                                boxShadow: shadows[1],
                                border: `1px solid ${alpha(palette.gery.black, 0.14)}`,
                                color: palette.gery.white,
                                '&:hover': { color: palette.primary.main },
                            }}
                            onClick={() => handleDelete(deleteNoteId)}
                        >
                            Save
                        </ButtonComponent>
                    </FooterPart>
                </DialogBox>


            </Box>
        </>
    )
};

export default Notes;

