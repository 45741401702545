import { all, fork, put, call, takeLatest, select } from 'redux-saga/effects';
import * as authTypes from './authTypes';
import * as authActions from './authActions';
import { setAppLoading } from 'src/modules/app/store/appActions';
import * as authApi from '../api/authApi';
import storage from 'src/utils/storageUtils';
import { push } from 'redux-first-history';
import { authPages } from 'src/config';
import { useSelector } from 'react-redux';

function* handleLogin({ data, resolve, reject }) {
  try {
    console.log('Attempting login');
    const res = yield call(authApi.loginUser, {
      email: data.email,
      password: data.password,
    });
    storage.set('TOKEN', res.token);
    yield put(authActions.restoreSession());
    resolve(true);
  } catch (error) {
    storage.del('TOKEN');
    reject(error);
  }
}
function* handleLogout() {
  storage.del('TOKEN');
  yield put(push('/auth/login'));
}

function* restoreSession() {

  try {
    yield put(setAppLoading(true));
    const clinic = yield select((state) => state.app.clinics);

    const token = storage.get('TOKEN');
    const pathname = yield select((state) => state.router?.location?.pathname || '');
    console.log('token : ', token);
    console.log('pathname : ', pathname);
    if (token && token !== '') {
      const res = yield call(authApi.getProfile);
      console.log('Profile data:', res);
      if (res.user) {
        storage.set('DOCTORNAME', res.user.personalInfo.fullName)
        yield put(authActions.setAuthUser(res.user));
        yield put(authActions.setAuthUserPermissions(res.permissions));
        if (authPages.includes(pathname)) {

          yield put(push(`/`));
        }
      } else {
        yield put(push('/auth/login'));
      }
    } else {
      yield put(push('/auth/login'));
    }
    yield put(setAppLoading(false));
  } catch (error) {
    console.log('Session restore failed', error);
    yield put(push('/auth/login'));
    yield put(setAppLoading(false));
  }
}

export function* watchSagas() {
  yield takeLatest(authTypes.LOGIN, handleLogin);
  yield takeLatest(authTypes.RESTORE_SESSION, restoreSession);
  yield takeLatest(authTypes.LOGOUT, handleLogout);
}

export default function* runSagas() {
  yield all([fork(watchSagas)]);
}