import React, { useState } from "react";
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    IconButton, Menu, MenuItem, Typography, Box, alpha
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ReactComponent as More } from '../../../../assets/images/more.svg'
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import theme from "src/config/theme";
import Paging from "../../Paging";
import NoDataFound from "src/modules/app/components/NoDataFound/NoDataFound";
const CustomTable = ({ columns, data, actions, headerButton, noDataComponent }) => {
    const [page, setPage] = useState(0);
    const perPage = 10;
    const totalCount = data.length;
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);

    const handleMenuOpen = (event, row) => {
        setAnchorEl(event.currentTarget);
        setSelectedRow(row);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedRow(null);
    };
    const isRowActive = (row) => selectedRow === row;

    return (
        <>
            <TableContainer component={Paper} sx={{
                borderRadius: "12px 12px 0 0",
                overflow: "auto",
                "&::-webkit-scrollbar": { display: "none" },
                "-ms-overflow-style": "none",
                "scrollbar-width": "none",
            }}>
                <Table >
                    {/* Table Head */}
                    <TableHead >
                        <TableRow
                            sx={{
                                backgroundColor: theme.palette.primary.light5,
                                borderBottom: `1px solid ${theme.palette.primary.light1}`,
                                borderRadius: '10px 10px 0 0',
                                height: '64px',
                            }}>
                            {columns.map((column, index) => (
                                <TableCell key={column.id} sx={{ ...theme.typography.h5, textAlign: 'center', padding: '20px', height: '64px', ...(column.sx) }} >
                                    <Box display="flex" alignItems="center" gap={1}>
                                        {column.label}

                                        {index === 0 && headerButton}
                                    </Box>

                                </TableCell>
                            ))}
                            {actions && <TableCell sx={{ ...theme.typography.h5, textAlign: 'center', padding: '20px', height: '64px' }}>Actions</TableCell>}
                        </TableRow>
                    </TableHead>

                    {/* Table Body */}
                    <TableBody sx={{
                        ...(data.length === 0 && {
                            // display: 'flex',
                            // justifyContent: 'center',
                            // alignItems: 'center',
                            // flexDirection: 'column',

                        })
                    }}>
                        {data.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={columns.length + (actions ? 1 : 0)}
                                    sx={{ borderBottom: 'none', position: 'absolute', left: '43%', top: '30%' }}>

                                    {noDataComponent || "No data available"}
                                </TableCell>
                            </TableRow>
                        ) : (


                            data.slice(page * perPage, (page + 1) * perPage).map((row, rowIndex) => (

                                <TableRow key={rowIndex}
                                    sx={{
                                        backgroundColor: theme.palette.grey.white,
                                        borderBottom: `1px solid ${theme.palette.primary.light1}`,
                                        borderRadius: '10px 10px 0 0',
                                        height: '70px',
                                        ...(columns.sx || {})

                                    }}
                                >
                                    {columns.map((column) => (
                                        <TableCell key={column.id} sx={{
                                            ...theme.typography.smallRegular, textAlign: 'center', height: '64px', textAlign: 'center', padding: '15px 20px', ...(column.sx)
                                        }}>{row[column.id]}</TableCell>
                                    ))}

                                    {/* Actions Menu */}
                                    {actions && (
                                        <TableCell sx={{ ...theme.typography.smallRegular, textAlign: 'center', height: '64px', textAlign: 'center', padding: '15px 20px' }}>

                                            <IconButton
                                                onClick={(event) => handleMenuOpen(event, row)}

                                                sx={{
                                                    color: isRowActive(row) ? theme.palette.gery.white : theme.palette.primary.main, background: isRowActive(row) ? theme.palette.primary.main : theme.palette.primary.light4,
                                                }}>
                                                <More />
                                            </IconButton>
                                            <Menu
                                                anchorEl={anchorEl}
                                                open={Boolean(anchorEl)}
                                                onClose={handleMenuClose}
                                                sx={{
                                                    "& .MuiPaper-root": {
                                                        width: "176px",
                                                        // height: "212px",
                                                        borderRadius: "12px",
                                                        // boxShadow: theme.shadows[1],
                                                        padding: "4px 18px",
                                                        backgroundColor: "red",
                                                        border: `1px solid ${alpha(theme.palette.gery.black, 0.1)}`,
                                                        backgroundColor: theme.palette.gery.white,
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: '14px',
                                                        // overflow: 'hidden'
                                                    }
                                                }}
                                            >
                                                {actions.map((action, index) => (
                                                    <MenuItem key={index} 
                                                    onClick={() => 
                                                        {
                                                            action.onClick(selectedRow)
                                                            handleMenuClose();

                                                        }
                                                        
                                                     }
                                                    >
                                                        <Box display="flex" alignItems="center" gap={1}>
                                                            {action.icon} <Typography>{action.label}</Typography>
                                                        </Box>
                                                    </MenuItem>
                                                ))}
                                            </Menu>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))

                        )}

                    </TableBody>
                </Table>
            </TableContainer>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '64px',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    flexShrink: 0,
                    borderRadius: '0px 0px 6px 6px',
                    paddingBottom: '16px',
                    // gap: '16px'
                }}>
                <Box sx={{ height: '8px', alignSelf: 'stretch', backgroundColor: theme.palette.primary.light5, }}>
                </Box>


                <Paging
                    size="small"
                    perPage={perPage}
                    page={page}
                    count={totalCount}
                    onChange={(newPage) => setPage(newPage)}
                />
            </Box>
        </>
    );
};

export default CustomTable;
