import api from 'src/api';

export const loginUser = (data) => {
  return api('/user/login', data, 'post');
};

export const addAppointment = (data) => {
  return api('/tokenappointment', data, 'post');
}

export const getAppointment = () => {
  return api('/tokenappointments', null, 'get');
}

export const getAppointmentById = (appointmentId) => {
  return api(`/tokenappointment/${appointmentId}`, null, 'get');
}
export const getClinics = () => {
  return api('/clinic', null, 'get')
}

export const adddPatient = (data) => {
  return api('/patient', data, 'post')
}

export const getPatient = () => {
  return api('/patients', null, 'get')
}

export const getPatientById = (patientId) => {
  return api(`/patient/${patientId}`, null, 'get')
}

export const getPatientInfoById = (patientId) => {
  return api(`/patientinfo/patient/${patientId}`, null, 'get')
}

export const getTagByPatientId = (patientId) => {
  return api(`/tag/patient/${patientId}`, null, 'get')
}

export const updateStatus = (appointmentId, data) => {
  return api(`/recordstatus/${appointmentId}`, data, 'put')
}

export const postMedicalRecord = (data) => {
  return api(`/medicalrecord`, data, 'post')
}

export const getMedicalRecordByPatientId = (patientId) => {
  return api(`/medicalrecord/patient/${patientId}`, null, 'get')
}

export const deleteMedicalRecordById = (id) => {
  return api(`/medicalrecord/${id}`, null, 'delete')
}


export const fetchAvailability = (clinicId) => {
  return api(`/getavailability/${clinicId}`, null, 'get');
};

export const getTemplateByClinicId = (clinicId) => {
  return api(`/template/clinic/${clinicId}`, null, 'get');
}

export const getFollowUpByPatientId = (patientId) => {
  return api(`/followup/patient/${patientId}`, null, 'get');
}

export const getTreatmentByPatientId = (patientId) => {
  return api(`/treatment/patient/${patientId}`, null, 'get');
}

export const getBillingsByPatientId = (patientId) => {
  return api(`/billing/patient/${patientId}`, null, 'get');
}

export const getMessagesByPatientId = (patientId) => {
  return api(`/message/patient/${patientId}`, null, 'get');
}

export const getReportsByPatientId = (patientId) => {
  return api(`/file/patient/${patientId}`, null, 'get');
}

export const postTemplate = (data) => {
  return api(`/template`, data, 'post')
}

