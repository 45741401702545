// import { all, fork, put, call, select, takeLatest } from '@redux-saga/core/effects';
// import { all, fork, put, call, select, takeLatest } from '@redux-saga/core/effects';
import { all, fork, call, put, select, takeLatest } from '@redux-saga/core/effects'

import { jwtDecode } from 'jwt-decode';
import * as appTypes from './appTypes';
import * as appActions from './appActions';
import {
  addAppointment,
  getClinics,
  adddPatient,
  getPatient,
  getAppointment,
  updateStatus,
  getAppointmentById,
  getPatientById,
  getPatientInfoById,
  getTagByPatientId,
  postMedicalRecord,
  getMedicalRecordByPatientId,
  deleteMedicalRecordById,
  fetchAvailability,
  getTemplateByClinicId,
  postTemplate
} from '../api/appApi';
import { replace } from 'redux-first-history';
import { extractParams, replaceParams } from '../../../../src/utils/url';
import storage from 'src/utils/storageUtils';


function getUserIdFromToken() {
  const token = localStorage.getItem('token');
  if (token) {
    const decodedToken = jwtDecode(token);
    return decodedToken.user;
  }
  throw new Error('Token not found');
}


function* handlePostPatient(action) {

  const { data, resolve, reject } = action.payload;
  console.log("Saga Data:", data);
  try {
    const updatedData = {
      ...data,
    }
    const newPatient = yield call(adddPatient, updatedData)
    if (resolve) resolve(newPatient);
  } catch (error) {
    const { reject } = action.payload;
    if (reject) reject(error);
  }
}

function* handlePostAppointment(action) {
  try {
    const { data, resolve, reject } = action.payload;
    console.log('Saga Data:', data);
    const updatedDatas = {
      ...data,
      // user: getUserIdFromToken(),
    };
    console.log('Updated Data:', updatedDatas);


    const newAppointment = yield call(addAppointment, updatedDatas);
    console.log('New Appointment:', newAppointment);

    yield put(appActions.postAppointmentSuccess(newAppointment.tokenappointment));
    if (resolve) resolve(newAppointment)

  } catch (error) {
    console.error('Saga Error:', error.message || error);
    console.error('Error details:', error.response ? error.response.data : 'No response data');
    yield put(appActions.postAppointmentFailure(error));

    if (action.payload.reject) {
      action.payload.reject(error);
    }
    // if (reject) reject(error);
  }
}

function* handleGetAppointment(action) {
  const { token, resolve, reject } = action.payload;
  try {
    const response = yield call(getAppointment, token);
    console.log("get Appointment response", response);
    if (response && response.data) {
      yield put(appActions.getAppointmentSuccess(response.data));
      if (resolve) resolve(response.data)
    } else {
      const error = new Error("No Data Found");
      yield put(appActions.getAppointmentFailure(error));
    }

  } catch (error) {
    yield put(appActions.getAppointmentFailure(error));
    if (reject) reject(error)
  }

}

function* handleGetAppointmentById(action) {
  try {
    const { appointmentId } = action.payload;
    const response = yield call(getAppointmentById, appointmentId);
    yield put(appActions.getAppointmentByIdSuccess(response));

  } catch (error) {
    yield put(appActions.getAppointmentByIdFailure(error.message || 'Failed to Fetch Appointment Data'))

  }

}

function* handleGetClinic(action) {
  try {
    yield put(appActions.setAppLoading(true));
    const referenceURL = 'http://localhost:3000/:clinicId/:doctorId/xyz';
    const location = window.location;
    const params = extractParams(referenceURL, location.href);
    const clinics = yield call(getClinics);
    const firstClinic = clinics.clinics?.[0];
    if ((!params.clinicId || !params.doctorId) && firstClinic) {
      const newUrl = replaceParams(referenceURL, location.href, firstClinic.id, firstClinic.user);
      yield put(replace(newUrl));
    }
    yield put(appActions.setClinics(clinics));
    yield put(
      appActions.setCurrentClinics({
        clinicId: params.clinicId || firstClinic.id,
        doctorId: params.doctorId || firstClinic.user,
      }),
    );
    yield put(appActions.setAppLoading(false));
  } catch (error) {
    console.error(error);
  }
}

function* handleGetPatient(action) {
  const { token, resolve, reject } = action.payload;
  try {
    const response = yield call(getPatient, token);
    console.log("Get Patient API Response:", response);
    if (response && response.data) {
      yield put(appActions.getPatientSuccess(response.data));
      if (resolve) {
        console.log("Resolving with data:", response.data);
        resolve(response.data);
      }
    } else {
      const error = new Error('No Data Found');
      yield put(appActions.getPatientFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    yield put(appActions.getPatientFailure(error));
    if (reject) reject(error);
  }
}

function* handleGetPatientById(action) {
  try {
    const { patientId } = action.payload;
    const response = yield call(getPatientById, patientId);
    yield put(appActions.getPatientByIdSuccess(response));

  } catch (error) {
    yield put(appActions.getPatientByIdFailure(error.message || 'Failed to Fetch Patient Data'))

  }

}
function* handleGetPatientInfoById(action) {
  const { patientId, resolve, reject } = action.payload;
  try {
    const response = yield call(getPatientInfoById, patientId);
    if (response && response.data) {
      yield put(appActions.getPatientInfoByIdSuccess(response.data));
      if (resolve) resolve(response.data)
    } else {
      const error = new Error("No Data Found");
      yield put(appActions.getPatientInfoByIdFailure(error));
    }

  } catch (error) {
    yield put(appActions.getPatientInfoByIdFailure(error));
    if (reject) reject(error)
  }


}

function* handleGetTagByPatientId(action) {
  const { patientId, resolve, reject } = action.payload;
  try {
    const response = yield call(getTagByPatientId, patientId);

    if (response && response.data) {
      yield put(appActions.getTagByPatientIdSuccess(response.data));
      if (resolve) resolve(response.data)
    } else {
      const error = new Error("No Data Found");
      yield put(appActions.getTagByPatientIdFailure(error));
    }

  } catch (error) {
    yield put(appActions.getTagByPatientIdFailure(error));
    if (reject) reject(error)
  }


}

function* handlePostMedicalRecord(action) {
  const { data, resolve, reject } = action.payload;
  const updatedData = {
    ...data,
  }

  try {
    const response = yield call(postMedicalRecord, updatedData);
    yield put(appActions.postMedicalRecordsSuccess(response.medicalRecord));
    if (resolve) resolve(response.medicalRecord)
  } catch (error) {
    yield put(appActions.postMedicalRecordsFailure(error));
    if (reject) reject(error)
  }
}

function* handleGetMedicalRecord(action) {
  const { patientId, resolve, reject } = action.payload;

  try {
    const response = yield call(getMedicalRecordByPatientId, patientId);
    if (response && response.data) {
      yield put(appActions.getMedicalRecordSuccess(response.data))
    }
    if (resolve) resolve(response.data)

  } catch (error) {
    yield put(appActions.getMedicalRecordFailure(error))
    if (reject) reject(error)
  }


}

function* handleDeleteMedicalRecord(action) {
  const { id, resolve, reject } = action.payload;

  try {
    const response = yield call(deleteMedicalRecordById, id);
    console.log("here is the saga api response", response)

    if (response) {
      yield put(appActions.deleteMedicalRecordSuccess(id));
      if (resolve) resolve(response.data || {});
    } else {
      const error = new Error(response.data?.message || 'Error deleting notes');
      yield put(appActions.deleteMedicalRecordFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    console.log(error);
    yield put(appActions.deleteMedicalRecordFailure(error));
    if (reject) reject(error);
  }
}

function* handleUpdateStatusRecord(action) {
  const { appointmentId, data, resolve, reject } = action.payload;
  try {
    const response = yield call(updateStatus, appointmentId, data);
    console.log("status changed", response);
    if (response && response.tokenAppointment) {
      yield put(appActions.updateRecordStatusSuccess({ data: response.tokenAppointment }));
      if (resolve) resolve(response);
    } else {
      yield put(appActions.updateRecordStatusFailure('No status updated'));
    }

  } catch (error) {
    yield put(appActions.updateRecordStatusFailure(error));
    if (reject) reject(error);
  }
}

function* handleGetTemplateByClinicId(action) {
  const { clinicId, resolve, reject } = action.payload;
  try {
    const response = yield call(getTemplateByClinicId, clinicId);
    if (response) {
      yield put(appActions.getTemplateByClinicIdSuccess(response.data))
    }
    if (resolve) resolve(response.data)
  } catch (error) {
    yield put(appActions.getTemplateByClinicIdFailure(error));
    if (reject) reject(error)
  }
}

function* handlePostTemplate(action) {
  const { data, resolve, reject } = action.payload;

  try {
    const response = yield call(postTemplate, data);

    if (response) {
      yield put(appActions.postTemplateSuccess(response.template));

      if (resolve) resolve(response.template)
    } else {
      yield put(appActions.postTemplateFailure("No template Added"));
      if (reject) reject("No template Added");
    }
  } catch (error) {
    console.error("Error in handlePostTemplate Saga:", error);
    yield put(appActions.postTemplateFailure(error));

    if (reject) reject(error)
  }
}

//get avialbility

function* handlefetchAvailability(action) {
  try {
    const { clinicId } = action.payload;
    const response = yield call(fetchAvailability, clinicId);

    if (response && response.data) {
      yield put(appActions.fetchAvailabilitySuccess(response.data));
    } else {
      const error = new Error('No data found');
      yield put(appActions.fetchAvailabilityFailure(error));
    }
  } catch (error) {
    console.error('Error fetching availability:', error);
    yield put(appActions.fetchAvailabilityFailure(error));
  }
}

export function* watchTokenAppointment() {
  yield takeLatest(appTypes.POST_APPOINTMENT_REQUEST, handlePostAppointment);
  yield takeLatest(appTypes.GET_APPOINTMENT_REQUEST, handleGetAppointment);
  yield takeLatest(appTypes.GET_APPOINTMENT_BY_ID_REQUEST, handleGetAppointmentById)
}

export function* watchClinics() {
  yield takeLatest(appTypes.GET_CLINICS, handleGetClinic)
}

export function* watchPatient() {
  yield takeLatest(appTypes.POST_PATIENT_REQUEST, handlePostPatient);
  yield takeLatest(appTypes.GET_PATIENT_REQUEST, handleGetPatient);
  yield takeLatest(appTypes.GET_PATIENT_BY_ID_REQUEST, handleGetPatientById);
  yield takeLatest(appTypes.GET_PATIENTINFO_BY_ID_REQUEST, handleGetPatientInfoById)
}
export function* watchTag() {
  yield takeLatest(appTypes.GET_TAG_BY_PATIENT_ID_REQUEST, handleGetTagByPatientId)
}
export function* watchStatusRecord() {
  yield takeLatest(appTypes.UPDATE_STATUS_REQUEST, handleUpdateStatusRecord);
}
export function* watchMedicalRecord() {
  yield takeLatest(appTypes.POST_MEDICALRECORD_REQUEST, handlePostMedicalRecord);
  yield takeLatest(appTypes.GET_MEDICALRECORD_BY_PATIENTID_REQUEST, handleGetMedicalRecord);
  yield takeLatest(appTypes.DELETE_MEDICALRECORD_BY_ID_REQUEST, handleDeleteMedicalRecord)
}
export function* watchFetchAvailability() {
  yield takeLatest(appTypes.FETCH_AVAILABILITY_REQUEST, handlefetchAvailability);
}
export function* watchGetTemplateByClinicId() {
  yield takeLatest(appTypes.GET_TEMPLATE_BY_CLINICID_REQUEST, handleGetTemplateByClinicId);
  yield takeLatest(appTypes.POST_TEMPLATE_REQUEST, handlePostTemplate)
}
export default function* runSagas() {
  yield all([
    fork(watchTokenAppointment),
    fork(watchClinics),
    fork(watchPatient),
    fork(watchStatusRecord),
    fork(watchTag),
    fork(watchMedicalRecord),
    fork(watchFetchAvailability),
    fork(watchGetTemplateByClinicId),
  ]);
}
