import React, { useEffect } from 'react';
import { AddContainer, Component4, PatientViewContainer, Texts, Text, Switch, HeaderPart, FooterPart } from './Style';
import InputComponent from 'src/components/shared/Form/Input/index.js';
import { palette, shadows } from 'src/config/theme.js';
import { alpha } from '@mui/material';
import SwitchComponent from 'src/components/shared/Form/Switch';
import { ReactComponent as CloseIcon } from '../../../../../../src/assets/images/close-circle.svg';
import { ReactComponent as AddIcon } from '../../../../../../src/assets/images/add-square.svg';
import ButtonComponent from 'src/components/shared/Button';
import { getAppointmentByIdRequest, postMedicalRecordsRequest, getMedicalRecordsRequest } from 'src/modules/app/store/appActions';
import { useSelector, useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import { Formik, Form } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
    notes: Yup.string().required('notes are required')
})

function NotesTabForm({ onClose, updateNotes }) {
    const { appointmentId } = useParams();
    const dispatch = useDispatch();
    const appointmentData = useSelector((state) => state.app.tokenAppointmentsById);
    const appointmentDetails = appointmentData?.payload?.data?.tokenappointment;
    const PatientId = appointmentDetails?.patient ?? null;
    const user = appointmentDetails?.user ?? null;
    // const tokenappointment = appointmentId;


    const handleSubmit = async (values, { resetForm }) => {
        try {
            const notesData = {
                notes: values.notes,
                date: new Date().toISOString().split('T')[0],
                time: new Date().toLocaleTimeString(),
                patientView: values.patientView ? "on" : "off",
                recordType: 'note',
                tokenappointment: appointmentId,
                patient: PatientId,
                user,
            }
            const response = await new Promise((resolve, reject) => {
                dispatch(postMedicalRecordsRequest(notesData, resolve, reject));
            });

            console.log("Note added successfully", response);

            if (response?.id) {
                updateNotes({ ...notesData, id: response.id });
            } else {
                console.warn("Note added but response did not include an ID");
            }

            resetForm();
            onClose();
        }
        catch (error) {
            console.log(error.message || 'Failed to add notes', { variant: 'error' });
        }

    }

    return (
        <>
            <Formik
                initialValues={{
                    notes: '',
                    patientView: false,
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ values, errors, touched, handleBlur, handleSubmit, handleChange, setFieldValue }) => (
                    <Form >
                        <HeaderPart>
                            Add Note
                            <CloseIcon onClick={onClose} style={{ cursor: 'pointer' }} />
                        </HeaderPart>
                        <AddContainer>
                            <Component4>
                                <Texts>Note</Texts>
                                <InputComponent
                                    name="notes"
                                    value={values.notes}
                                    variant="outlined"
                                    placeholder="Enter note.."
                                    fullWidth
                                    multiline
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.notes && Boolean(errors.notes)}
                                    helperText={touched.notes && errors.notes}
                                    rows={4}
                                    sx={{
                                        backgroundColor: palette.gery.white,
                                        boxShadow: shadows[1],
                                        '& .MuiOutlinedInput-root': {
                                            padding: '16px 18px',
                                            borderRadius: '12px',
                                            height: '120px',
                                            alignItems: 'flex-start',
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: `1px solid ${alpha(palette.gery.black, 0.14)}`,
                                        },
                                    }}
                                />
                            </Component4>
                            <Component4>
                                <Texts>Patient Can View</Texts>
                                <PatientViewContainer>
                                    <Text>No</Text>
                                    <Switch>
                                        <SwitchComponent
                                            name="patientView"
                                            checked={values.patientView}
                                            onChange={() => setFieldValue('patientView', !values.patientView)}

                                            sx={{ marginTop: 0 }} />
                                    </Switch>
                                    <Text>Yes</Text>

                                </PatientViewContainer>

                            </Component4>

                        </AddContainer>
                        <FooterPart >
                            <ButtonComponent
                                variant="none"
                                startIcon={<CloseIcon sx={{ color: palette.primary.main }} />}
                                onClick={onClose}
                            >
                                Cancel
                            </ButtonComponent>
                            <ButtonComponent
                                type="submit"
                                variant="outlined"
                                startIcon={<AddIcon sx={{ color: palette.primary.main }} />}
                                sx={{
                                    width: '104px',
                                    height: '52px',
                                    borderRadius: '12px',
                                    padding: '10px 18px',
                                    bgcolor: palette.primary.main,
                                    boxShadow: shadows[1],
                                    border: `1px solid ${alpha(palette.gery.black, 0.14)}`,
                                    color: palette.gery.white,
                                    '&:hover': { color: palette.primary.main },
                                }}
                            // onClick={handleSubmit ? () => handleSubmit() : null}
                            >
                                Save
                            </ButtonComponent>
                        </FooterPart>
                    </Form>
                )}
            </Formik>
        </>

    )
}

export default NotesTabForm
