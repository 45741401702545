// import React from "react";

// import Grid from "@mui/material/Grid";
// import {
//   AuthBg,
//   Left,
//   Right,
//   Logo,
//   Icon1,
//   Icon2,
//   AuthContent,
//   AuthContainer,
// } from "./styles";

// function AuthLayout({ children, ...props }) {
//   return (
//     <AuthContainer maxWidth="xl">
//       <AuthBg>
//         <Grid container>
//           <Left item xs={6}>
//             <Logo />
//             <Icon1 />
//             <AuthContent>
//               <div>{children}</div>
//             </AuthContent>
//           </Left>
//           <Right item xs={6}>
//             <Icon2 />
//           </Right>
//         </Grid>
//       </AuthBg>
//     </AuthContainer>
//   );
// }

// export default AuthLayout;


import React from "react";

import Grid from "@mui/material/Grid";
import {
  AuthBg,
  Left,
  Right,
  Logo,
  Icon1,
  Icon2,
  AuthContent,
  AuthContainer,
} from "./styles";

function AuthLayout({ children, ...props }) {
  return (
    <AuthContainer maxWidth="xl">

      <Grid container>
        <Left item xs={6}>
          <Logo />
          <AuthContent>
            <div>{children}</div>
          </AuthContent>
        </Left>
        <Right item xs={6}>
          <Icon2 />
        </Right>
      </Grid>
    </AuthContainer>
  );
}

export default AuthLayout;
