import React, {useEffect, useState} from "react";
import { Drawer, Box, Typography, alpha,Avatar,styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as Close } from '../../../assets/images/close-circle.svg'
import { BorderRight } from "@mui/icons-material";
import Search from '../../../assets/images/search-normal.svg'
import InputComponent from "src/components/shared/Form/Input";
import theme, {palette, shadows} from "src/config/theme";
import { ReactComponent as UpArrow } from '../../../assets/images/arrow-up.svg';
import { ReactComponent as DownArrow } from '../../../assets/images/arrow-down.svg';
import { ReactComponent as RightArrow } from '../../../assets/images/arrow-right 2.svg'
import NoDataFound from "src/modules/app/components/NoDataFound/NoDataFound";
import { getAppointmentRequest } from "src/modules/app/store/appActions";
import { useDispatch 

} from "react-redux";
import storage from "src/utils/storageUtils";


export const SearchBox = styled(Box)({
    padding: '10px 18px',
    border: `1px solid ${alpha(palette.gery.black, 0.14)}`,
    borderRadius: '12px',
    width: '332px',
    height: '52px',
    backgroundColor: palette.gery.white,
    boxShadow: shadows[1],
    display: 'flex',
    alignItems: 'center',
    gap: '6px'
  })
  export const SearchIcon = styled(Avatar)({
    position: 'relative',
    width: '24px',
    height: '24px',
  })

  export const ListBox = styled(Box)({
    width:'332px',
    // height:'110px',
    borderRadius:'12px',
    boxShadow: shadows[1],
    border: `1px solid ${alpha(palette.gery.black, 0.10)}`,
    backgroundColor: palette.gery.white,
    display:'flex',
    flexDirection:'column',
    alignItems:'flex-end',
    gap:'10px',
    alignSelf:'stretch',
    position:'relative'

  })
  export const ListInner = styled(Box)({
    width:'312px',
    height:'110px',
    flexShrink:0,

  })
  export const Box1 = styled(Box)({
    display:'inline-flex',
    flexDirection:'column',
    alignItems:'flex-start',
    gap:'4px', 
    margin:'16px 0 0 10px'
  })
  export const Box2 = styled(Box)({
    width:'144px',
    height:'22px',
    margin: '12px 0 0 10px',
    display:'flex',
    gap:'8px',
    alignItems:'center'
  })
  export const Dot = styled(Box)({
    width:'8px',
    height:'8px',
    borderRadius:'4px',
    backgroundColor: theme.palette.action.green,
  })
  export const VerticalBorder = styled(Box)({
    height:'110px',
    border: `1px solid ${theme.palette.primary.light1}`,
    position:'absolute',
    right:'17%'
  })
  export const HorizontalBorder = styled(Box)({
    height:'56px',
    border: `1px solid ${theme.palette.primary.light1}`,
    position:'absolute',
    transform: 'rotate(-90deg)',
    position:'absolute',
    top:'25%',
    right:'8.2%',
  })
  export const TwoIcon = styled(Box)({
    position:'absolute',
    width:'24px',
    height:'24px',
    top:'70px',
    left:'292px',
    display:'flex',
    justifyContent:'center',
    flexDirection:'column',
    // gap:'1px'
    
  })
  
const RightSidebar = ({ open, onClose }) => {
    const [appointments, setAppointments] = useState([]); 
    const theme = useTheme();
    const dispatch = useDispatch();

    useEffect(() => {
      if (open) {
      const fetchData = async () => {
        const token = storage.get('TOKEN');
        const response = await new Promise((resolve, reject) => {
          dispatch(getAppointmentRequest(token, resolve, reject))
        })
        console.log("filtered response", response)
        if (response) {
          const FilteredData = response.filter(appointment => appointment.recordStatus === 'In Queue' || appointment.recordStatus === 'Attending Now'
      );
          console.log("Filtered response:", FilteredData);
          setAppointments(FilteredData);
        } else {
          console.error("Expected an array but got:", response);
        }
      }
     fetchData();
    }
    }, [dispatch, open])


    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    width: '380px',
                    height:'1080px', 
                },
            }}
        >
            <Box sx={{ height: '84px', backgroundColor: theme.palette.primary.light3, border: `1px solid ${theme.palette.primary.light1}`, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ width: '332px', height: '24px', display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h3">OPD Queue</Typography>
                  
                        <Close onClick={onClose} sx={{ color: theme.palette.gery.black }}  style={{ cursor: 'pointer' }}/>
                </Box>
            </Box>
            <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', margin:'32px 24px' }}>
            <SearchBox>
              <SearchIcon src={Search} />
              <InputComponent placeholder="Search" variant="outlined" sx={{
                // paddingLeft: ,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                    width: 'auto'
                  },
                  "& input": {
                    paddingLeft: 0,
                    paddingRight: 0,
                  },
                },
              }} />
              {/* <SearchText placeholder="Search..." /> */}
            </SearchBox>
            </Box>

            {appointments.length === 0 ? (
                <Box   sx={{display:'flex', justifyContent:'center', alignItems:'center',flexDirection:'column',gap:'16px', margin:'0 24px', height:'40%'}}>
                    <NoDataFound 
                    title="Queue Empty!"
                    showButton={false} 
                    description = {
                        <>
                        No Data to Showcase <br />
                        <span style={{ color: theme.palette.gery.black }}>Click “Add to Queue”</span> from Appointments.
                    </>
                    }
                    />
                </Box>
            ):(
<Box sx={{overflowY: 'auto',  
    maxHeight: 'calc(100vh - 100px)', 
    paddingBottom: '16px' ,}}>
             {appointments.map((data) => (
              <Box  key={data.id} sx={{ display:'flex', justifyContent:'center', alignItems:'flex-start',flexDirection:'column',gap:'16px', margin:'10px 24px',  }}>
              <ListBox>
               <ListInner>
                 <Box1>
                   <Typography variant="h5" sx={{color: theme.palette.primary.main}}>{data?.patient?.fullName}</Typography>
                   <Typography variant="body1" sx={{color: `${alpha(theme.palette.gery.black,0.70)}`}}>{new Date(data.date).toLocaleDateString('en-GB', {
      day: '2-digit', month: 'short', year: 'numeric'
    }) + `, ${data.time}`
}</Typography>
                 </Box1>

                 {data.recordStatus === 'Attending Now' ? 
                   <Box2>
                   <Dot />
                   <Typography variant="small" sx={{color:theme.palette.action.green, fontSize:'14px'}}>Attending Now</Typography>
                   
                     </Box2> :  <Box2>
                 <Typography variant="small" sx={{color:theme.palette.primary.main, fontSize:'14px'}}>Attend Now</Typography>
               <RightArrow style={{color: theme.palette.primary.main}}/>

               
                 </Box2>
                 }
               
       
               </ListInner>
               <VerticalBorder/>
               <HorizontalBorder />
               <Close  style={{position:'absolute',top:'16px', left:'292px',cursor:'pointer' }}/>
             <TwoIcon>
               <UpArrow  style={{color:theme.palette.primary.main, cursor:'pointer'}}/>
               <DownArrow style={{color:theme.palette.primary.main, cursor:'pointer'}}/>
               </TwoIcon>
             

              </ListBox>
              {/* <ListBox>
               <ListInner>
                 <Box1>
                   <Typography variant="h5" sx={{color: theme.palette.primary.main}}>Demo Patient</Typography>
                   <Typography variant="body1" sx={{color: `${alpha(theme.palette.gery.black,0.70)}`}}>15th Oct, 10:00am</Typography>
                 </Box1>
                 <Box2>
                 <Typography variant="small" sx={{color:theme.palette.primary.main, fontSize:'14px'}}>Attend Now</Typography>
               <RightArrow style={{color: theme.palette.primary.main}}/>

               
                 </Box2>
       
               </ListInner>
               <VerticalBorder/>
               <HorizontalBorder />
               <Close  style={{position:'absolute',top:'16px', left:'292px',cursor:'pointer' }}/>
             <TwoIcon>
               <UpArrow  style={{color:theme.palette.primary.main, cursor:'pointer'}}/>
               <DownArrow style={{color:theme.palette.primary.main, cursor:'pointer'}}/>
               </TwoIcon>
             

              </ListBox>
              <ListBox>
               <ListInner>
                 <Box1>
                   <Typography variant="h5" sx={{color: theme.palette.primary.main}}>Demo Patient</Typography>
                   <Typography variant="body1" sx={{color: `${alpha(theme.palette.gery.black,0.70)}`}}>15th Oct, 10:00am</Typography>
                 </Box1>
                 <Box2>
               <Typography variant="small" sx={{color:theme.palette.primary.main, fontSize:'14px'}}>Attend Now</Typography>
               <RightArrow style={{color: theme.palette.primary.main}}/>

                 </Box2>
       
               </ListInner>
               <VerticalBorder/>
               <HorizontalBorder />
               <Close  style={{position:'absolute',top:'16px', left:'292px',cursor:'pointer' }}/>
             <TwoIcon>
               <UpArrow  style={{color:theme.palette.primary.main, cursor:'pointer'}}/>
               <DownArrow style={{color:theme.palette.primary.main, cursor:'pointer'}}/>
               </TwoIcon>
             

              </ListBox> */}
           </Box>
            )) }
            </Box>
            )}
        </Drawer>
    );
};

export default RightSidebar;
