import React, { useState } from 'react';
import { Typography, alpha } from '@mui/material';
import { AddContainer, Component4, DropZone, Texts, HeaderPart, FooterPart } from './Style';
import InputSelect from 'src/components/shared/Form/Select';
import theme from 'src/config/theme';
import { ReactComponent as CloseIcon } from '../../../../../../src/assets/images/close-circle.svg';
import { ReactComponent as AddIcon } from '../../../../../../src/assets/images/add-square.svg';
import { palette, shadows } from 'src/config/theme.js';
import ButtonComponent from 'src/components/shared/Button';


const Report = [
    { label: 'Patient Summary Report', value: 'patient summary report' },
    { label: 'Doctor Consultation Report', value: 'doctor consultation report' },
    { label: 'Health checkup Report', value: 'health checkup report' }
]

function ReportTabForm({ onClose }) {
    const [selectValue, setSelectValue] = useState('');
    const [file, setFile] = useState(null);
    const handleChange = (value) => {
        setSelectValue(value)
    }
    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };
    return (
        <>    <HeaderPart>
            Add Report
            <CloseIcon onClick={onClose} style={{ cursor: 'pointer' }} />
        </HeaderPart>
            <AddContainer>
                <Component4>
                    <Texts>Report Type</Texts>
                    <InputSelect
                        value={selectValue}
                        options={Report}
                        onChange={handleChange}
                        variant="outlined"
                        placeholder="Choose"
                        sx={{
                            // width: '200px',
                            boxShadow: theme.shadows[1],
                            '& .MuiOutlinedInput-root': {
                                height: '52px',
                                display: 'flex',
                                alignItems: 'center',
                                // border: `1px solid ${alpha(theme.palette.gery.black, 0.14)}`,
                                borderRadius: '12px',
                            },
                        }}
                    />

                </Component4>
                <Component4>
                    <Texts>Upload Report</Texts>
                    <DropZone component="label">
                        <input
                            type="file"
                            hidden
                            accept=".pdf, .png, .jpg"
                            onChange={handleFileChange}
                        />
                        {!file ? (
                            <>
                                <Typography variant='body1' color={theme.palette.gery.black}>
                                    Drag & Drop File Here or
                                    <span
                                        style={{
                                            ...theme.typography.h6,
                                            color: theme.palette.primary.main,
                                            cursor: "pointer",
                                            borderBottom: `1px solid ${theme.palette.primary.main}`,
                                            marginLeft: 3

                                        }}
                                    >
                                        Browse File
                                    </span>
                                </Typography>
                                <Typography variant="body1" color={alpha(theme.palette.gery.black, 0.7)}>
                                    (max size: 25MB, type: .pdf, .png, .jpg)
                                </Typography>
                            </>
                        ) : (
                            <Typography variant="body1" fontWeight="bold" mt={1} color={alpha(theme.palette.gery.black, 0.7)}>
                                Upload File: <strong style={{ ...theme.typography.h6, color: theme.palette.gery.black }}>{file.name} ({file.size > 1024 * 1024
                                    ? (file.size / (1024 * 1024)).toFixed(2) + " MB"
                                    : (file.size / 1024).toFixed(2) + " KB"})
                                </strong>
                            </Typography>
                        )}

                    </DropZone>

                </Component4>
            </AddContainer>
            <FooterPart >
                <ButtonComponent
                    variant="none"
                    startIcon={<CloseIcon sx={{ color: palette.primary.main }} />}
                    onClick={onClose}
                >
                    Cancel
                </ButtonComponent>
                <ButtonComponent
                    type="submit"
                    variant="outlined"
                    startIcon={<AddIcon sx={{ color: palette.primary.main }} />}
                    sx={{
                        width: '104px',
                        height: '52px',
                        borderRadius: '12px',
                        padding: '10px 18px',
                        bgcolor: palette.primary.main,
                        boxShadow: shadows[1],
                        border: `1px solid ${alpha(palette.gery.black, 0.14)}`,
                        color: palette.gery.white,
                        '&:hover': { color: palette.primary.main },
                    }}
                // onClick={handleSubmit ? () => handleSubmit() : null}
                >
                    Save
                </ButtonComponent>
            </FooterPart>
        </>
    )
}

export default ReportTabForm;
