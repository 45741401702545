// import React from "react";
// import { Box, Typography, Button, IconButton } from "@mui/material";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
// import { CardContainer, CardHeader, InnerContainer, ProfileContainer, Title, Year_Gender, Title2, Dot, Line, InnerContainer2, Title3, SymptomsContainer, SymptomsText, CheapContainer, MenuContainer } from "./Style";
// import { ReactComponent as More } from '../../../../assets/images/more.svg'

// const CustomCard = ({ }) => {
//     return (
//         <>
//             <CardContainer>
//                 <CardHeader>
//                     <ProfileContainer>
//                         D
//                     </ProfileContainer>
//                     <InnerContainer>
//                         <Title>
//                             Demo Patient
//                         </Title>
//                         <Year_Gender>
//                             <Title2>
//                                 27 Years
//                             </Title2>
//                             <Dot></Dot>
//                             <Title2>
//                                 Male
//                             </Title2>

//                         </Year_Gender>

//                     </InnerContainer>
//                 </CardHeader>
//                 <Line />
//                 <InnerContainer2>
//                     <Title3>
//                         Contact No.
//                     </Title3>
//                     <Title>
//                         9724183826
//                     </Title>



//                 </InnerContainer2>
//                 <InnerContainer2>
//                     <Title3>
//                         Time
//                     </Title3>
//                     <Title>
//                         10:00 am
//                     </Title>



//                 </InnerContainer2>
//                 <InnerContainer2>
//                     <Title3>
//                         Date
//                     </Title3>
//                     <Title>
//                         15th Nov, 2024
//                     </Title>


//                 </InnerContainer2>
//                 <SymptomsContainer>
//                     <SymptomsText>
//                         Headache, Vomit, Pain.
//                     </SymptomsText>

//                 </SymptomsContainer>
//                 <Line />
//                 <InnerContainer2>
//                     <CheapContainer label="Scheduled" />
//                     <MenuContainer>

//                         <More />

//                     </MenuContainer>
//                 </InnerContainer2>



//             </CardContainer>

//         </>

//     );
// };

// export default CustomCard;

import React, { useState } from "react";
import { Box, Menu, MenuItem, Typography, Dialog, DialogTitle, DialogContent, DialogActions, alpha, Avatar } from "@mui/material";
import ButtonComponent from "src/components/shared/Button";
import theme from "src/config/theme";
import { CardContainer, CardHeader, InnerContainer, ProfileContainer, Title, Year_Gender, Title2, Dot, Line, InnerContainer2, Title3, SymptomsContainer, SymptomsText, CheapContainer, MenuContainer, AvatarIcon } from "./Style";
import { ReactComponent as More } from '../../../../assets/images/more.svg';
import { ReactComponent as Profile } from '../../../../assets/images/profile-circle.svg';
import { ReactComponent as ArrowRight } from '../../../../assets/images/arrow-right 2.svg';
import { ReactComponent as SliderVertical } from '../../../../assets/images/slider-vertical.svg';
import { ReactComponent as Edit2 } from '../../../../assets/images/edit-2.svg';
import { ReactComponent as Close } from '../../../../assets/images/close-circle.svg';
import { ReactComponent as AddIcon } from '../../../../assets/images/add-square.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/images/close-circle.svg';
import { ReactComponent as Danger } from '../../../../assets/images/danger.svg';
import { useNavigate } from 'react-router-dom';


const getStatusStyles = (status) => {
    switch (status) {
        case "Scheduled":
            return {
                background: "rgba(150, 57, 150, 0.12)",
                color: theme.palette.action.purpleDark,
            };
        case "In Queue":
            return {
                background: "rgba(103, 57, 150, 0.14)",
                color: theme.palette.action.purpleLight,
            };
        case "Attending Now":
            return {
                background: "rgba(57, 150, 57, 0.18)",
                color: theme.palette.action.green,
            };
        case "Complete":
            return {
                background: "rgba(57, 150, 57, 0.18)",
                color: theme.palette.action.green,
            };
        case "Cancelled":
            return {
                background: "rgba(150, 57, 57, 0.18)",
                color: theme.palette.action.red,
            };
        default:
            return {
                background: theme.palette.grey[300],
                color: theme.palette.action.red,
            };
    }
};
// display: "flex", flexWrap: "wrap", gap: "20px" 

const CustomCard = ({ data }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const handleMenuOpen = (event, item) => {
        setAnchorEl(event.currentTarget);
        setSelectedRow(item);
    };


    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedRow(null);
    };
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const actions = [
        { label: "View Profile", icon: <Profile />, onClick: () => navigate("/appointment/Patients") },
        { label: "Attend Now", icon: <ArrowRight />, onClick: () => navigate("/Patients") },
        { label: "Add to Queue", icon: <SliderVertical />, onClick: (row) => alert(`Adding ${row.patient} to Queue`) },
        { label: "Edit", icon: <Edit2 />, onClick: (row) => alert(`Editing ${row.patient}`) },
        { label: "Cancel", icon: <Close />, onClick: () => handleOpen() }

    ];
    return (
        <Box sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
            gap: '20px',
            // justifyContent: 'center'
        }}>
            {data.map((item, index) => {
                const statusLabel = item.status.props.label;
                const statusStyles = getStatusStyles(statusLabel);

                return (
                    <CardContainer key={index}>
                        <CardHeader>
                            <ProfileContainer sx={{ background: theme.palette.primary.light4 }}>
                                D
                            </ProfileContainer>
                            <InnerContainer>
                                <Title>{item.patient.props.children[1]}</Title>
                                <Year_Gender>
                                    <Title2>{item.age}</Title2>
                                    <Dot />
                                    <Title2>{item.sex}</Title2>
                                </Year_Gender>
                            </InnerContainer>
                        </CardHeader>
                        <Line />
                        <InnerContainer2>
                            <Title3>Contact No.</Title3>
                            <Title>{item.contact}</Title>
                        </InnerContainer2>
                        <InnerContainer2>
                            <Title3>Time</Title3>
                            <Title>{item.date.split(", ")[1]}</Title>
                        </InnerContainer2>
                        <InnerContainer2>
                            <Title3>Date</Title3>
                            <Title>{item.date.split(", ")[0]}</Title>
                        </InnerContainer2>
                        <SymptomsContainer>
                            <SymptomsText>{item.symptoms}</SymptomsText>
                        </SymptomsContainer>
                        <Line />
                        <InnerContainer2>
                            <CheapContainer
                                label={statusLabel}
                                sx={{
                                    background: statusStyles.background,
                                    color: statusStyles.color,
                                    borderRadius: "100px",
                                    padding: "6px 12px",
                                    fontSize: "14px",
                                    fontWeight: "bold"
                                }}
                            />
                            <MenuContainer
                                onClick={(event) => handleMenuOpen(event, item)}
                                isActive={selectedRow === item}
                            >
                                <More />
                            </MenuContainer>
                        </InnerContainer2>


                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            sx={{
                                "& .MuiPaper-root": {
                                    width: "176px",
                                    borderRadius: "12px",
                                    boxShadow: theme.shadows[1],
                                    // padding: "8px 12px",
                                    backgroundColor: theme.palette.gery.white,
                                }
                            }}
                        >
                            {actions.map((action, index) => (
                                <MenuItem key={index} onClick={() => {
                                    action.onClick(selectedRow);
                                    handleMenuClose();
                                }}>
                                    <Box display="flex" alignItems="center" gap={1}>
                                        {action.icon} <Typography>{action.label}</Typography>
                                    </Box>
                                </MenuItem>
                            ))}
                        </Menu>
                    </CardContainer>
                );
            })}



            <Dialog open={open} onClose={handleClose}
                sx={{
                    "& .MuiDialog-paper": {
                        borderRadius: "12px",

                    },
                }}
            >
                <DialogTitle sx={{
                    backgroundColor: theme.palette.primary.light3,
                    color: theme.palette.gery.black,
                    ...theme.typography.h3,
                    border: `1px solid ${alpha(theme.palette.primary.light1)}`,
                    padding: '20px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    // width: '470px',
                    height: '64px',
                }}>Cancel Appointment
                    <CloseIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
                </DialogTitle>
                <DialogContent dividers >
                    <Box sx={{ width: '470px', height: '314px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                        <Box sx={{ width: '232px', height: '186px', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', }}>
                            <AvatarIcon sx={{ bgcolor: "transparent" }}>
                                <Danger width="24px" height="24px" />
                            </AvatarIcon>
                            <Typography variant="h5">Cancel Appointment</Typography>
                            <Typography sx={{ ...theme.typography.smallRegular, color: `${alpha(theme.palette.gery.black, 0.7)}`, textAlign: 'center', marginTop: '10px' }}>
                                Are you sure you want to cancel appointment of

                                <Typography sx={{ ...theme.typography.smallRegular, color: theme.palette.gery.black, marginTop: '5px' }}>Demo Patient (+91 9724183826).</Typography>
                            </Typography>
                        </Box>

                    </Box>



                </DialogContent>
                <DialogActions sx={{ height: "100px", padding: '24px 20px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '16px' }}>
                    <ButtonComponent
                        variant="none" startIcon={<CloseIcon sx={{ color: theme.palette.primary.main }} />}

                        onClick={handleClose}
                    >No</ButtonComponent>
                    <ButtonComponent
                        variant="outlined" startIcon={<AddIcon sx={{ color: theme.palette.primary.main }} />}
                        sx={{
                            width: '104px',
                            height: '52px',
                            borderRadius: '12px',
                            padding: '10px 18px',
                            bgcolor: theme.palette.primary.main,
                            boxShadow: theme.shadows[1],
                            border: `1px solid ${alpha(theme.palette.gery.black, 0.14)}`,
                            color: theme.palette.gery.white, '&:hover': {
                                color: theme.palette.primary.main,
                            }
                        }}
                        onClick={handleOpen}
                    >Save</ButtonComponent>
                </DialogActions>
            </Dialog>
        </Box >


    );
};

export default CustomCard;

