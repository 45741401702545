import { styled } from "@mui/material/styles";
import theme from "src/config/theme";
import { palette, shadows, typography } from "src/config/theme";
import { Avatar, Box, Chip, Typography, alpha } from "@mui/material";
export const HistoryContainerOne = styled(Box)({
    width: '762px',
    height: '52px',
    display: 'flex',
    alignItems: 'center',
    gap: '32px',
    marginBottom: '30px'

});
export const Texts = styled(Typography)({
    ...typography.h5,
    color: palette.gery.black,
})
export const InnerContainer = styled(Box)({
    width: '582px',
    height: '52px',
    display: 'flex',
    gap: '12px',
    alignItems: 'center'
})

export const HistoryContainerTwo = styled(Box)({
    width: '661px',
    height: 'auto',
    display: 'flex',
    // gap: '5px',
    flexDirection: 'column',
    // gap: '10px',
});

export const FirstRow = styled(Box)({
    width: 'auto',
    height: '670px',
    display: 'flex',
    flexDirection: 'row'
});
export const LabelText = styled(Box)({
    width: '146px',
    height: '22px'
})

export const Label = styled(Typography)({
    ...typography.h5,
    color: `${alpha(palette.gery.black, 0.7)}`,
    textWrap: 'nowrap',
})

export const Headers = styled(Typography)({
    ...typography.h4,
    color: palette.gery.black,
    fontWeight: '500',
})

export const Paragraph = styled(Typography)({
    ...typography.smallRegular,
    color: `${alpha(palette.gery.black, 0.7)}`,
    lineHeight: '24px',
})

export const Border = styled(Box)({
    border: `1px solid ${alpha(palette.gery.black, 0.14)}`,
    position: 'relative',
    zIndex: 0

})
export const CircleContainer = styled(Box)({
    width: '28px',
    height: '28px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: palette.primary.light3,
    position: 'absolute',
    left: '-13px'

})
export const CircleInner = styled(Box)({
    width: '16px',
    height: '16px',
    backgroundColor: palette.primary.main,
    borderRadius: '50%',

})

export const Details = styled(Box)({
    width: 'auto',
    display: 'flex',
    flexDirection: 'row',
    gap: '40px',

})
export const HeaderTextContainer = styled(Box)({
    width: '486px',
    height: '150px',
    display: 'flex',
    flexDirection: 'row',
    gap: '25px',
    position: 'relative',

})
export const HeaderText = styled(Box)({
    // width: '486px',
    // height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px'
})
