

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AppBar, IconButton, Toolbar, Box, Typography, Switch, Select, alpha, Divider, Avatar, Menu, MenuItem } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import InputSelect from 'src/components/shared/Form/Select';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SwitchComponent from 'src/components/shared/Form/Switch';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ButtonComponent from 'src/components/shared/Button';
import { Save } from "@mui/icons-material";
// import { ReactComponent as sliderVertical } from '../../../../assets/images/arrow-down.svg';
import { ReactComponent as SliderVerticalIcon } from '../../../assets/images/slider-vertical.svg';
import { ReactComponent as LeftArrow } from '../../../assets/images/arrow-left 2.svg';
import avatarImage from '../../../assets/images/avatar.jpg';
import RightSidebar from '../RIghtSidebar/RightSidebar';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentClinics, fetchAvailabilityRequest, getClinics, } from 'src/modules/app/store/appActions';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../modules/auth/store/authActions';
import storage from 'src/utils/storageUtils';





function Header() {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const clinic = useSelector((state) => state.app.clinics);
  const clinics = clinic?.data || [];
  const [anchorElUser, setAnchorElUser] = useState(null);
  const DoctorName = storage.get('DOCTORNAME')


  const handleLogout = () => {
    dispatch(logout());
    navigate('/auth/login');
  };

  const clinicList = clinics.map((clinic) => ({
    ...clinic,
    label: clinic.clinicName,
    value: clinic.id,
  }));



  useEffect(() => {
    if (!clinic?.data || clinic.data.length === 0) {
      dispatch(getClinics());
    }
  }, [dispatch, clinic?.data]);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const settings = [{ label: 'Profile', action: () => console.log('Profile Clicked') },
  { label: 'Account', action: () => console.log('Account Clicked') },
  {
    label: 'Logout', action: () => {
      handleLogout();
    }
  }];

  const setClinicIdOnUrl = (id) => {
    const selectedClinic = clinicList.find((clinic) => clinic.id === id);
    if (selectedClinic) {
      dispatch(setCurrentClinics({ clinicId: selectedClinic.id, doctorId: selectedClinic.user }));
      navigate(`/${selectedClinic.id}/${selectedClinic.user}/appointment`);

      // Fetch availability for the selected clinic
      dispatch(fetchAvailabilityRequest(selectedClinic.id));
    }
  };
  const [clinicStatus, setClinicStatus] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [selectValue, setSelectValue] = useState('');

  const handleChange = (value) => {
    setSelectValue(value);
    console.log("Selected Value:", value);
  };

  return (
    <AppBar position='static' sx={{ bgcolor: theme.palette.gery.white, paddingX: 2, boxShadow: "none", borderBottom: '1px solid #0000001A', width: 'auto', height: '84px', display: 'flex', justifyContent: 'center' }} disableGutters>
      <Toolbar disableGutters>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} >
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '16px'
          }}>
            <IconButton>
              <LeftArrow />
            </IconButton>
            <Typography variant="h5" color="black" sx={{ marginLeft: '-20px' }}>Back</Typography>
            <InputSelect
              showNone={false}
              value={clinic.currentClinic?.id || clinics[0]?.id}
              options={clinicList}
              onChange={(selectedValue) => setClinicIdOnUrl(selectedValue)}
              variant="outlined"
              placeholder="Choose"
              sx={{
                width: '200px',
                boxShadow: theme.shadows[1],
                '& .MuiOutlinedInput-root': {
                  height: '52px',
                  display: 'flex',
                  alignItems: 'center',
                  // border: `1px solid ${alpha(theme.palette.gery.black, 0.14)}`,
                  borderRadius: '12px',
                },
              }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                border: `1px solid ${alpha(theme.palette.gery.black, 0.14)}`,
                borderRadius: '12px',
                padding: '10px 18px',
                width: '238px',
                height: '52px',
                boxShadow: theme.shadows[1],
              }}
            >
              {/* Wrap Typography and Switch inside a flex container */}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', position: 'relative' }}>
                <Typography variant={theme.typography.h5} color="black" sx={{ textWrap: 'nowrap' }}>
                  Clinic: Open
                </Typography>
                <SwitchComponent
                  checked={clinicStatus}
                  onChange={() => setClinicStatus(!clinicStatus)}
                  sx={{
                    margin: 0,
                    padding: 0,
                    // width: 0,
                    // height: 0
                  }}
                />
                <Typography variant={theme.typography.h5} color="black" sx={{ position: 'absolute', right: -30 }}>
                  Close
                </Typography>
              </Box>
            </Box>

          </Box>
          <Box sx={{ width: '409px', height: '52px', display: 'flex', alignItems: 'center', gap: '20px' }}>
            <ButtonComponent
              variant="outlined"
              onClick={() => setSidebarOpen(true)}
              startIcon={<SliderVerticalIcon sx={{ color: theme.palette.primary.main }} />}
              sx={{ width: '160px', height: '52px', borderRadius: '12px', padding: '10px', bgcolor: theme.palette.primary.light3, border: `1px solid ${theme.palette.primary.light1}` }}>OPD Queue
            </ButtonComponent>
            <Divider orientation='vertical' />
            <Box sx={{ width: '209px', height: '52px', display: 'flex', gap: '10px' }} onClick={handleOpenUserMenu}>
              <Avatar alt="Remy Sharp" src={avatarImage} sx={{ width: '52px', height: '52px' }} />
              <Box>
                <Typography variant={theme.typography.h3} color={theme.palette.gery.black} sx={{ textWrap: 'nowrap' }}>
                  {DoctorName}
                </Typography><br />
                <Typography variant={theme.typography.smallRegular} color={`${alpha(theme.palette.gery.black, 0.698)}`}>
                  as an Owner
                </Typography>
              </Box>
            </Box>

            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting.label} onClick={() => {
                  handleCloseUserMenu();
                  setting.action();
                }}>
                  <Typography sx={{ textAlign: 'center' }}>{setting.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Box>
      </Toolbar>
      <RightSidebar open={sidebarOpen} onClose={() => setSidebarOpen(false)} />
    </AppBar>

  )
}
export default Header;




