import React, { useState } from "react";
import CustomTable from "src/components/App/AppGrid/CustomTable/CustomTable";
import { Avatar, Chip, Box, alpha } from "@mui/material";
import theme from "src/config/theme";
import ButtonComponent from "src/components/shared/Button";
import DialogBox from "src/components/shared/Dialog/DialogBox";
import { ReactComponent as Edit } from '../../../../../assets/images/edit-2.svg';
import { ReactComponent as Delete } from '../../../../../assets/images/trash.svg';
import { ReactComponent as Add } from '../../../../../assets/images/add-square.svg';
import { ReactComponent as View } from '../../../../../assets/images/eye.svg';
import { ReactComponent as Download } from '../../../../../assets/images/document-download.svg'
import TreatmentTabForm from "../Forms/TreatmentTabForm";
import TypeSitting from "../Forms/TypeSitting";
import TypeVisiting from "../Forms/TypeVisiting";
import NoDataFound from "../../NoDataFound/NoDataFound";

const columns = [
  { id: "treatmentName", label: "Treatment Name", sx: { width: "486px", minWidth: "150px", textAlign: 'left' } },
  { id: "type", label: "Type", sx: { textAlign: 'left' }, },
  { id: "schduleDays", label: "Schedule Days", sx: { "& .css-axw7ok": { justifyContent: 'center' } } },
  { id: 'dateOrTime', label: 'Date Or Time', sx: { "& .css-axw7ok": { justifyContent: 'center' } } },
  { id: 'status', label: 'Status', sx: { "& .css-axw7ok": { justifyContent: 'center' } } },
];
const data = [
  {
    treatmentName: 'Blood Report',
    type: 'Visiting',
    schduleDays: 'Mon, Wed, Fri ',
    dateOrTime: '10:00am',
    status: '0/12 Complete',
  },
  {
    treatmentName: 'Root Canal',
    type: 'Sitting',
    schduleDays: '-',
    dateOrTime: '',
    status: '0/3 Complete',
  },
];
const actions = [

  { label: "Edit", icon: <Edit />, onClick: (row) => alert(`Viewing ${row.patient}`) },
  { label: "Delete", icon: <Delete />, onClick: (row) => alert(`Attending ${row.patient}`) },
];

const Treatments = () => {
  const [open, setOpen] = useState(false);
  const [selectedTreatment, setSelectedTreatment] = useState(null);
  const handleOpen = (treatment = null) => {
    setSelectedTreatment(treatment);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedTreatment(null);
  };
  const handleExited = () => {
    setSelectedTreatment(null);
  };
  const getDialogTitle = () => {
    if (!open) return null;
    if (selectedTreatment) {
      return `${selectedTreatment.treatmentName} Treatments`;
    }
    return `Add Treatment`;

  }

  const getDialogContent = () => {
    if (!open) return null;
    if (selectedTreatment?.type === "Visiting") {
      return <TypeVisiting onClose={handleClose} />

    } else if (selectedTreatment?.type === "Sitting") {
      return <TypeSitting onClose={handleClose} />

    } else {
      return <TreatmentTabForm onClose={handleClose} />

    }
  }
  return (
    <>
      <Box sx={{
        height: '756px',
        boxShadow: theme.shadows[1],
        borderRadius: '10px',
        border: `1px solid ${alpha(theme.palette.gery.black, 0.1)}`,
        backgroundColor: theme.palette.gery.white,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative'
      }}>
        <CustomTable
          columns={columns}

          data={data.map((row) => ({
            ...row,
            status: (
              <Chip
                label={row.status}
                onClick={() => handleOpen(row)}
                sx={{
                  background: 'rgba(103, 57, 150, 0.14)',
                  color: theme.palette.action.purpleLight,
                  cursor: 'pointer',
                  ...theme.typography.small,
                  borderRadius: '100px',
                  padding: '10px 18px',
                }}
              />
            ),
          }))}
          actions={actions}
          headerButton={
            <ButtonComponent
              variant="contained"
              endIcon={<Add />}
              onClick={() => handleOpen()}
              sx={{
                background: theme.palette.primary.main,
                border: `1px solid ${alpha(theme.palette.gery.black, 0.14)}`,
                boxShadow: theme.shadows[1],
                width: '164px',
                height: '36px',
                padding: '6px 6px 6px 10px',
                borderRadius: '12px',
                ...theme.typography.h5,

              }}
            >Add Treatment</ButtonComponent>
          }
          noDataComponent={
            <NoDataFound
              title="No Treatments Found!"
              buttonText="Add Treatment"
              buttonIcon={Add}
              onClick={handleOpen}
            />
          }
        />
        <DialogBox open={open} onClose={handleClose} onExited={handleExited} title={getDialogTitle()}>
          {getDialogContent()}
        </DialogBox>
      </Box>
    </>
  )
};

export default Treatments;

