// import React from "react";
// import { Box, Button, Typography } from "@mui/material";
// import { DataGrid } from "@mui/x-data-grid";
// import AddIcon from "@mui/icons-material/Add";
// import WarningAmberIcon from "@mui/icons-material/WarningAmber";
// import Welcome from "../components/patient/Welcome";
// import { useTheme } from "@mui/material/styles";


// const columns = [
//   { field: "patientName", headerName: "Patient Details", flex: 1 },
//   { field: "age", headerName: "Age", flex: 0.5 },
//   { field: "sex", headerName: "Sex", flex: 0.5 },
//   { field: "contactNo", headerName: "Contact No", flex: 1 },
//   { field: "symptoms", headerName: "Symptoms", flex: 1 },
//   { field: "status", headerName: "Status", flex: 1 },
//   { field: "dateTime", headerName: "Date & Time", flex: 1 },
//   {
//     field: "actions",
//     headerName: "Actions",
//     flex: 1,
//     sortable: false,
//     renderCell: () => (
//       <Button variant="contained" color="primary">
//         Edit
//       </Button>
//     ),
//   },
// ];

// const rows = []; // Empty rows to show "No Appointments Found"

// function AppointmentContainer() {
//   const theme = useTheme();

//   return (
//     <>
//       <Welcome category="appointments" />
//       <Box sx={{
//         height: '824px', margin: 2
//       }}>
//         < DataGrid
//           rows={rows}
//           columns={columns}
//           pageSizeOptions={[5, 10, 20]}
//           disableRowSelectionOnClick

//           sx={{
//             "& .MuiDataGrid-container--top [role=row]": {
//               backgroundColor: theme.palette.primary.light5,
//               color: theme.palette.gery.black, // Change text color
//               fontWeight: "bold", // Make text bold
//             },
//           }}
//           localeText={{
//             noRowsLabel: (
//               <Box textAlign="center" py={5}>
//                 <WarningAmberIcon sx={{ fontSize: 50, color: "#aaa" }} />
//                 <Typography variant="h6" sx={{ fontWeight: "bold", mt: 1 }}>
//                   No Appointments Found!
//                 </Typography>
//                 <Typography variant="body2" sx={{ color: "#777" }}>
//                   No Data to Showcase
//                 </Typography>
//                 <Typography variant="body2" sx={{ color: "#777", mt: 1 }}>
//                   Click "Add" Button to Add Data!
//                 </Typography>
//                 <Button
//                   variant="outlined"
//                   startIcon={<AddIcon />}
//                   sx={{
//                     mt: 2,
//                     borderColor: "#4D47C3",
//                     color: "#4D47C3",
//                     "&:hover": { borderColor: "#3A35A1" },
//                   }}
//                 >
//                   Add Appointment
//                 </Button>
//               </Box>
//             ),
//           }}
//         />
//       </Box >
//     </>
//   );
// }

// export default AppointmentContainer;




import React, { useEffect, useState } from "react";
import CustomTable from "src/components/App/AppGrid/CustomTable/CustomTable";
import { Avatar, Chip, Box, alpha, Typography, DialogContent, DialogTitle, DialogActions, styled } from "@mui/material";
import Welcome from "../components/patient/Welcome";
import theme from "src/config/theme";
import { ReactComponent as Profile } from '../../../assets/images/profile-circle.svg';
import { ReactComponent as ArrowRight } from '../../../assets/images/arrow-right 2.svg';
import { ReactComponent as SliderVertical } from '../../../assets/images/slider-vertical.svg';
import { ReactComponent as Edit2 } from '../../../assets/images/edit-2.svg';
import { ReactComponent as Close } from '../../../assets/images/close-circle.svg';
import { ReactComponent as AddIcon } from '../../../assets/images/add-square.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/close-circle.svg';
import { ReactComponent as Danger } from '../../../assets/images/danger.svg'
import CustomCard from "src/components/App/AppGrid/CustomCard/CustomCard";
import { useNavigate } from 'react-router-dom';
import NoDataFound from "../components/NoDataFound/NoDataFound";
import { useDispatch, useSelector } from 'react-redux';
import { getAppointmentRequest, updateRecordStatusRequest } from '../../../modules/app/store/appActions';
import storage from "src/utils/storageUtils";
import RightSidebar from "../../../components/App/RIghtSidebar/RightSidebar";
import DialogBox from "src/components/shared/Dialog/DialogBox";
import ButtonComponent from "src/components/shared/Button";
export const AvatarIcon = styled(Avatar)({
  width: '64px',
  height: '64px',
  padding: '10px',
  borderRadius: '40px',
  background: 'linear-gradient(0deg, rgba(150, 57, 57, 0.18), rgba(150, 57, 57, 0.18)), #FFFFFF',
  marginBottom: '5px'
})


const columns = [
  { id: "patient", label: "Patient Details", sx: { "& .css-s1474d": { justifyContent: 'flex-Start !important' } }, },
  { id: "age", label: "Age", sx: { "& .css-axw7ok": { justifyContent: 'center !important' } }, },
  { id: "sex", label: "Sex", sx: { "& .css-axw7ok": { justifyContent: 'center !important' } }, },
  { id: "contact", label: "Contact No", sx: { "& .css-axw7ok": { justifyContent: 'center !important' } }, },
  { id: "symptoms", label: "Symptoms", sx: { "& .css-axw7ok": { justifyContent: 'center !important' } }, },
  { id: "status", label: "Status", sx: { "& .css-axw7ok": { justifyContent: 'center !important' } }, },
  { id: "date", label: "Date & Time", sx: { "& .css-axw7ok": { justifyContent: 'center !important' } }, }
];

const AppointmentContainer = () => {
  const [isCardView, setIsCardView] = useState(false);
  const [open, setOpen] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedAppointmentId, setSelectedAppointmentId] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const handleOpen = (appointmentId, patientData) => {
    setSelectedAppointmentId(appointmentId);
    setSelectedPatient(patientData)
    setOpen(true);
  };
  const handleClose = () => {
    setSelectedAppointmentId(null);
    setSelectedPatient(null)
    setOpen(false);
  };


  useEffect(() => {
    const fetchData = async () => {
      const token = storage.get('TOKEN');
      const response = await new Promise((resolve, reject) =>
        dispatch(getAppointmentRequest(token, resolve, reject)),
      );
      if (response) {
        setAppointments(response);
      }
    };
    fetchData();

  }, [dispatch])


  const updateAppointmentStatus = async (appointmentId, status, onSuccess) => {
    const updatedData = { recordStatus: status };

    try {
      const response = await new Promise((resolve, reject) => {
        dispatch(updateRecordStatusRequest(appointmentId, updatedData, resolve, reject));
      });

      if (response) {
        console.log(`Status updated successfully to ${status}`);

        setAppointments((prevAppointments) =>
          prevAppointments.map((appointment) =>
            appointment.id === appointmentId
              ? { ...appointment, recordStatus: status }
              : appointment
          )
        );

        if (onSuccess) onSuccess();
      }
    } catch (error) {
      console.error(`Failed to update status to ${status}:`, error);
    }
  };


  const handleAddToQueue = (appointmentId) => {
    updateAppointmentStatus(appointmentId, "In Queue", () => setSidebarOpen(true));
  };

  const handleCancelAppointment = (appointmentId, patient) => {
    updateAppointmentStatus(appointmentId, "Cancelled", handleClose);
  };

  const handleAttendNowAppointment = (appointmentId) => {
    updateAppointmentStatus(appointmentId, "Attending Now", () => navigate(`/appointment/Patients/${appointmentId}`))
  }

  // const handleAddToQueue = async (appointmentId) => {
  //   const updatedData = {
  //     recordStatus: 'In Queue'
  //   }
  //   try {
  //     const response = await new Promise((resolve,reject) => {
  //       dispatch(updateRecordStatusRequest(appointmentId, updatedData,resolve,reject))
  //     });
  //     if (response) {
  //       console.log('Status updated successfully');

  //       setAppointments((prevAppointments) =>
  //         prevAppointments.map((appointment) =>
  //           appointment.id === appointmentId
  //             ? { ...appointment, recordStatus: 'In Queue' } 
  //             : appointment
  //         )
  //       );
  //       setSidebarOpen(true);
  //     }
  //   } catch(error) {
  //     console.log("Failed to update status:", error)
  //   }

  // }

  // const handleCancelAppointment = async (appointmentId, patient) => {
  //   const updatedData = {
  //     recordStatus : 'Cancelled'
  //   }
  //   try {
  //     const response = await new Promise((resolve, reject) => {
  //       dispatch(updateRecordStatusRequest(appointmentId, updatedData, resolve, reject))
  //       handleClose()
  //     })
  //     if(response) {
  //       setAppointments((prevAppointments) => 
  //         prevAppointments.map((appointment) => 
  //           appointment.id === appointmentId ? 
  //         { ...appointment, recordStatus: 'Cancelled' } 
  //         : appointment,
  //         )
  //       )
  //     }

  //   } catch(error) {
  //     console.log('Failed to update Cancel Status', error)
  //   }

  // }

  const data = appointments.map((appointment) => ({
    appointmentId: appointment.id,
    patientData: appointment?.patient,
    patient: (
      <Box sx={{ display: "flex", justifyContent: 'center', alignItems: "center", gap: "8px" }}>
        <Avatar sx={{ background: theme.palette.primary.light4, color: theme.palette.primary.main }}>{appointment.patient?.fullName?.charAt(0).toUpperCase()}</Avatar>
        <span>{appointment.patient?.fullName || "N/A"}</span>
      </Box>
    ),
    age: appointment.patient?.age ? `${appointment.patient.age} Years` : "N/A",
    sex: appointment.patient?.gender ? `${appointment.patient.gender.charAt(0).toUpperCase() + appointment.patient.gender.slice(1)} ` : 'N/A',
    contact: appointment.patient?.contactNo ? `${appointment.patient.contactNo}` : 'N/A',
    symptoms: appointment.addnotes,
    status: <Chip label={appointment.recordStatus} sx={{
      background:
        appointment.recordStatus === "Scheduled" ? "linear-gradient(0deg, rgba(150, 57, 150, 0.12), rgba(150, 57, 150, 0.12))" :
          appointment.recordStatus === "In Queue" ? 'linear-gradient(0deg, rgba(103, 57, 150, 0.14), rgba(103, 57, 150, 0.14))' :
            appointment.recordStatus === "Attending Now" ? 'linear-gradient(0deg, rgba(57, 150, 57, 0.18), rgba(57, 150, 57, 0.18))' :
              appointment.recordStatus === "Complete" ? 'linear-gradient(0deg, rgba(57, 150, 57, 0.18), rgba(57, 150, 57, 0.18))' :
                appointment.recordStatus === 'Cancelled' ? 'linear-gradient(0deg, rgba(150, 57, 57, 0.18), rgba(150, 57, 57, 0.18))' :
                  "linear-gradient(0deg, rgba(200, 200, 200, 0.18), rgba(200, 200, 200, 0.18))",

      color: appointment.recordStatus === "Scheduled" ? theme.palette.action.purpleDark :
        appointment.recordStatus === "In Queue" ? theme.palette.action.purpleLight :
          appointment.recordStatus === "Attending Now" ? theme.palette.action.green :
            appointment.recordStatus === "Complete" ? theme.palette.action.green :
              appointment.recordStatus === 'Cancelled' ? theme.palette.action.red : theme.palette.gery.black,
      ...theme.typography.small,
      borderRadius: '100px',
      padding: '10px 18px 8px 18px',
    }} />,
    date: new Date(appointment.date).toLocaleDateString('en-GB', {
      day: '2-digit', month: 'short', year: 'numeric'
    }) + `, ${appointment.time}`

  }))

  const actions = [
    { label: "View Profile", icon: <Profile />, onClick: (row) => navigate(`/appointment/Patients/${row.appointmentId}`) },
    { label: "Attend Now", icon: <ArrowRight />, onClick: (row) => handleAttendNowAppointment(row.appointmentId) },

    { label: "Add to Queue", icon: <SliderVertical />, onClick: (row) => handleAddToQueue(row.appointmentId) },

    { label: "Edit", icon: <Edit2 />, onClick: (row) => alert(`Editing ${row.patient}`) },
    { label: "Cancel", icon: <Close />, onClick: (row) => handleOpen(row.appointmentId, row.patientData) }
  ];
  return (
    <>
      <Welcome category="appointments" setIsCardView={setIsCardView} isCardView={isCardView} />
      <Box sx={{
        // width: '1768px',
        height: '824px',
        margin: 2,
        boxShadow: isCardView ? 'none' : theme.shadows[1],
        borderRadius: '10px',
        border: isCardView ? 'none' : `1px solid ${alpha(theme.palette.gery.black, 0.1)}`,
        backgroundColor: theme.palette.gery.white,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative'

      }}>
        {isCardView ? <CustomCard data={data} /> : <CustomTable columns={columns} data={data} actions={actions} />}
      </Box>

      <RightSidebar open={sidebarOpen} onClose={() => setSidebarOpen(false)} />

      <DialogBox open={open} onClose={handleClose} >
        <DialogTitle sx={{
          backgroundColor: theme.palette.primary.light3,
          color: theme.palette.gery.black,
          ...theme.typography.h3,
          border: `1px solid ${alpha(theme.palette.primary.light1)}`,
          padding: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          // width: '470px',
          height: '64px',
        }}>Cancel Appointment
          <CloseIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
        </DialogTitle>
        <DialogContent dividers >
          <Box sx={{ width: '470px', height: '314px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

            <Box sx={{ width: '232px', height: '186px', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', }}>
              <AvatarIcon sx={{ bgcolor: "transparent" }}>
                <Danger width="24px" height="24px" />
              </AvatarIcon>
              <Typography variant="h5">Cancel Appointment</Typography>
              <Typography sx={{ ...theme.typography.smallRegular, color: `${alpha(theme.palette.gery.black, 0.7)}`, textAlign: 'center', marginTop: '10px' }}>
                Are you sure you want to cancel appointment of

                <Typography sx={{ ...theme.typography.smallRegular, color: theme.palette.gery.black, marginTop: '5px' }}>
                  {selectedPatient ? `${selectedPatient.fullName} (${selectedPatient.contactNo})` : "Loading..."}
                </Typography>
              </Typography>
            </Box>

          </Box>



        </DialogContent>
        <DialogActions sx={{ height: "100px", padding: '24px 20px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '16px' }}>
          <ButtonComponent
            variant="none" startIcon={<CloseIcon sx={{ color: theme.palette.primary.main }} />}

            onClick={handleClose}
          >No</ButtonComponent>
          <ButtonComponent
            variant="outlined" startIcon={<AddIcon sx={{ color: theme.palette.primary.main }} />}
            sx={{
              width: '104px',
              height: '52px',
              borderRadius: '12px',
              padding: '10px 18px',
              bgcolor: theme.palette.primary.main,
              boxShadow: theme.shadows[1],
              border: `1px solid ${alpha(theme.palette.gery.black, 0.14)}`,
              color: theme.palette.gery.white, '&:hover': {
                color: theme.palette.primary.main,
              }
            }}
            onClick={() => handleCancelAppointment(selectedAppointmentId)}
          >Save</ButtonComponent>
        </DialogActions>
      </DialogBox>

    </>
  )
};

export default AppointmentContainer;

