import React, { useEffect, useState } from 'react';
import { AddContainer, Component4, PatientViewContainer, Texts, Text, Switch, InnerComponent, Component5, HeaderPart, FooterPart } from './Style';
import InputComponent from 'src/components/shared/Form/Input/index.js';
import { palette, shadows } from 'src/config/theme.js';
import { alpha } from '@mui/material';
import SwitchComponent from 'src/components/shared/Form/Switch';
import InputSelect from 'src/components/shared/Form/Select';
import theme from 'src/config/theme.js';
import { ReactComponent as CloseIcon } from '../../../../../../src/assets/images/close-circle.svg';
import { ReactComponent as AddIcon } from '../../../../../../src/assets/images/add-square.svg';
import ButtonComponent from 'src/components/shared/Button';
import { useDispatch, useSelector } from 'react-redux';
import { getTemplateByClinicIdRequest, postMedicalRecordsRequest, postTemplateRequest } from 'src/modules/app/store/appActions';
import { useParams } from 'react-router-dom';
import { Formik, Form } from "formik";
import * as Yup from "yup";



const validationSchema = Yup.object().shape({
    notes: Yup.string().required('prescription are required')
})
function PrescriptionTabForm({ onClose, updateNotes }) {

    const [selectValue, setSelectValue] = useState('');
    const [isTemplateSaved, setIsTemplateSaved] = useState(false);

    const dispatch = useDispatch();
    const TemplateData = useSelector((state) => state.app.getTemplateByClinicId);
    const clinicData = useSelector((state) => state.app.clinics);
    const clinicId = clinicData?.clinicId;
    const { appointmentId } = useParams();

    const appointmentData = useSelector((state) => state.app.tokenAppointmentsById);
    const appointmentDetails = appointmentData?.payload?.data?.tokenappointment;
    const PatientId = appointmentDetails?.patient ?? null;
    const user = appointmentDetails?.user ?? null;

    useEffect(() => {
        if (clinicId) {
            dispatch(getTemplateByClinicIdRequest(clinicId));
        }
    }, [clinicId, dispatch]);

    const Templates = TemplateData?.payload?.data?.filter((template) => template.type === "prescription").map((template) => ({
        label: template.title,
        value: template.id,

    })) || [];

    const handleSubmit = async (values, { resetForm }) => {
        try {
            const prescriptionData = {
                notes: values.notes,
                date: new Date().toISOString().split('T')[0],
                time: new Date().toLocaleTimeString(),
                patientView: "off",
                recordType: 'prescription',
                tokenappointment: appointmentId,
                patient: PatientId,
                user,
            }
            console.log("isTemplateSaved:", isTemplateSaved);


            // dispatch(getTemplateByClinicIdRequest(clinicId));
            const response = await new Promise((resolve, reject) => {
                dispatch(postMedicalRecordsRequest(prescriptionData, resolve, reject));
            });

            console.log("prescription added successfully", response);

            if (response?.id) {
                updateNotes({ ...prescriptionData, id: response.id });
            }
            if (isTemplateSaved) {
                const templateData = {
                    title: values.title,
                    text: values.notes,
                    type: 'prescription',
                    clinic: clinicId,
                }

                const response2 = await new Promise((resolve, reject) => {
                    dispatch(postTemplateRequest(templateData, resolve, reject));
                });

                console.log("✅ postTemplateRequest response:", response2);
            }

            resetForm();
            onClose();
        }
        catch (error) {
            console.log(error.message || 'Failed to add notes', { variant: 'error' });
        }

    }

    return (
        <>
            <Formik
                initialValues={{
                    notes: '',
                    title: '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ values, errors, touched, handleBlur, handleSubmit, handleChange, setFieldValue }) => (
                    <Form>
                        <HeaderPart>
                            Add Prescription
                            <CloseIcon onClick={onClose} style={{ cursor: 'pointer' }} />
                        </HeaderPart>
                        <AddContainer>
                            <Component4>
                                <Texts>Prescription Template</Texts>
                                <InputSelect
                                    value={selectValue}
                                    options={Templates}
                                    onChange={(value) => {
                                        setSelectValue(value);
                                        const selectedTemplate = TemplateData?.payload?.data?.find(template => template.id === value);
                                        setFieldValue("notes", selectedTemplate ? selectedTemplate.text : "");
                                    }
                                    }
                                    showNone={false}
                                    variant="outlined"
                                    placeholder="Choose"
                                    sx={{
                                        // width: '200px',
                                        boxShadow: theme.shadows[1],
                                        '& .MuiOutlinedInput-root': {
                                            height: '52px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            // border: `1px solid ${alpha(theme.palette.gery.black, 0.14)}`,
                                            borderRadius: '12px',
                                        },
                                    }}
                                />

                            </Component4>
                            <Component4>
                                <Texts>Prescription</Texts>
                                <InputComponent
                                    name="notes"
                                    value={values.notes}
                                    variant="outlined"
                                    placeholder="Enter"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.notes && Boolean(errors.notes)}
                                    helperText={touched.notes && errors.notes}
                                    fullWidth
                                    multiline
                                    rows={4}
                                    sx={{
                                        backgroundColor: palette.gery.white,
                                        boxShadow: shadows[1],
                                        '& .MuiOutlinedInput-root': {
                                            padding: '16px 18px',
                                            borderRadius: '12px',
                                            height: '120px',
                                        },
                                    }}
                                />
                            </Component4>
                            <Component5>
                                <InnerComponent sx={{ width: '141px' }}>
                                    <Texts>Save as ?</Texts>
                                    <PatientViewContainer>
                                        <Text>No</Text>
                                        <Switch>
                                            <SwitchComponent
                                                sx={{ marginTop: 0 }}
                                                checked={isTemplateSaved}
                                                onChange={(e) => setIsTemplateSaved(e?.target?.checked ?? !isTemplateSaved)}
                                            />
                                        </Switch>
                                        <Text>Yes</Text>

                                    </PatientViewContainer>
                                </InnerComponent>
                                {isTemplateSaved &&
                                    <InnerComponent sx={{ width: '267px' }}>
                                        <Texts>New Template Name</Texts>
                                        <InputComponent
                                            name="title"
                                            value={values.title}
                                            variant="outlined"
                                            placeholder="Enter "
                                            fullWidth
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={touched.title && Boolean(errors.title)}
                                            helperText={touched.title && errors.title}
                                            sx={{
                                                backgroundColor: palette.gery.white,
                                                boxShadow: shadows[1],
                                                '& .MuiOutlinedInput-root': {
                                                    padding: '10px 18px',
                                                    borderRadius: '12px',
                                                    height: '52px',
                                                    // border: `1px solid ${alpha(palette.gery.black, 0.14)}`,
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: `1px solid ${alpha(palette.gery.black, 0.14)}`,
                                                },
                                            }}
                                        />

                                    </InnerComponent>
                                }
                            </Component5>

                        </AddContainer>
                        <FooterPart >
                            <ButtonComponent
                                variant="none"
                                startIcon={<CloseIcon sx={{ color: palette.primary.main }} />}
                                onClick={onClose}
                            >
                                Cancel
                            </ButtonComponent>
                            <ButtonComponent
                                type="submit"
                                variant="outlined"
                                startIcon={<AddIcon sx={{ color: palette.primary.main }} />}
                                sx={{
                                    width: '104px',
                                    height: '52px',
                                    borderRadius: '12px',
                                    padding: '10px 18px',
                                    bgcolor: palette.primary.main,
                                    boxShadow: shadows[1],
                                    border: `1px solid ${alpha(palette.gery.black, 0.14)}`,
                                    color: palette.gery.white,
                                    '&:hover': { color: palette.primary.main },
                                }}
                            // onClick={handleSubmit ? () => handleSubmit() : null}
                            >
                                Save
                            </ButtonComponent>
                        </FooterPart>
                    </Form>
                )}
            </Formik>
        </>
    )
}

export default PrescriptionTabForm
