import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from 'react-router-dom';
import { ListItem } from './styles';
import { useSelector } from 'react-redux';
function Item({ item, pathname, isPermanentOpen }) {
  const navigate = useNavigate();
  const clinic = useSelector((state) => state.app.clinics);
  // const className = pathname === item.link ? 'active AppMenuItem' : 'AppMenuItem';
  const isActive = pathname === `/${clinic.clinicId}/${clinic.doctorId}${item.link}`;
  const className = isActive ? 'active AppMenuItem' : 'AppMenuItem';



  const handleClick = () => {
    navigate(`/${clinic.clinicId}/${clinic.doctorId}${item.link}`);
  };
  return (
    <ListItem className={className} onClick={handleClick} isPermanentOpen={isPermanentOpen} sx={{ width: '279px' }}>
      <ListItemIcon>{item.icon}</ListItemIcon>
      {isPermanentOpen && <ListItemText primary={item.title} />}
    </ListItem>
  );
}
export default Item;