import { createTheme } from '@mui/material/styles';

export const colors = {
  // Primary Colors
  primaryMain: '#393996',
  primaryLight1: '#DBDBEC',
  primaryLight2: '#DFDFEE',
  primaryLight3: '#F0F0F7',
  primaryLight4: '#F7F7FB',
  primaryLight5: '#F8F9FD',

  // Action Colors
  actionPurpleDark: '#780078',
  actionPurpleLight: '#4C0099',
  actionGreen: '#006900',
  actionRed: '#963939',

  // Action Light Colors
  actionLightPurple1: '#F2E7F2',
  actionLightPurple2: '#E9E3F0',
  actionLightGreen: '#DBECDB',
  actionLightRed: '#ECDBDB',

  // Gery Colors
  black: '#000000',
  darkGray: '#4D4D4D',
  mediumGray: '#DBDBDB',
  lightGray: '#E6E6E6',
  extraLightGray: '#FAFAFA',
  white: '#FFFFFF',

}
export const typography = {
  fontFamily: 'Google Sans, Arial, sans-serif',

  h1: {
    fontSize: '26px',
    lineHeight: '33px',
    fontWeight: 500, //  26 Med
    letterSpacing: '0%',
  },
  h2: {
    fontSize: '24px',
    lineHeight: '31px',
    fontWeight: 500, // Medium
    letterSpacing: '0%',
  },
  h3: {
    fontSize: '18px',
    lineHeight: '23px',
    fontWeight: 700, // Bold
    letterSpacing: '-1%',
  },
  h4: {
    fontSize: '18px',
    lineHeight: '23px',
    fontWeight: 400, //  18 Reg
    letterSpacing: '0%',
  },
  h5: {
    fontSize: '17px',
    lineHeight: '22px',
    fontWeight: 500, //17 Med
    letterSpacing: '0%',
  },
  h6: {
    fontSize: '17px',  //17 Med 
    lineHeight: '27px',
    fontWeight: 500, // Medium
    letterSpacing: '0%',
  },
  body1: {                 //Normal With Regular
    fontSize: '17px',
    lineHeight: '22px',
    fontWeight: 400, // 17 Reg
    letterSpacing: '0%',
  },
  body2: {                  //Normal with Bold
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 700, // Bold
    letterSpacing: '0%',
  },
  small: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 500, // 16 Med
    letterSpacing: '0%',
  },
  smallRegular: {        //16 Reg
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 400, // Regular
    letterSpacing: '0%',
  },
};

export const shadows = [
  'none', // Index 0 is always 'none'
  '0px 0px 26px 0px #00000008', // Normal Shadow  
  '4px 0px 20px 0px #00000005', // Normal X Shadow
  '0px 4px 20px 0px #00000005', // Normal Y Shadow           
  '0px 0px 40px 0px #00000029', // Big Shadow

];
export const palette = {
  type: 'light',
  primary: {
    main: colors.primaryMain,
    light1: colors.primaryLight1,
    light2: colors.primaryLight2,
    light3: colors.primaryLight3,
    light4: colors.primaryLight4,
    light5: colors.primaryLight5,
  },
  action: {
    purpleDark: colors.actionPurpleDark,
    purpleLight: colors.actionPurpleLight,
    green: colors.actionGreen,
    red: colors.actionRed,
  },

  actionLight: {
    purple1: colors.actionLightPurple1,
    purple2: colors.actionLightPurple2,
    green: colors.actionLightGreen,
    red: colors.actionLightRed,
  },
  gery: {
    black: colors.black,
    darkGray: colors.darkGray,
    mediumGray: colors.mediumGray,
    lightGray: colors.lightGray,
    extraLightGray: colors.extraLightGray,
    white: colors.white,
  },
  text: {
    primary: colors.black,
    secondary: colors.darkGray,
  },
  background: {
    default: colors.extraLightGray,
  },
};
export const components = {
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
    },
  },
};

export const overrides = {};

const theme = createTheme({
  palette,
  typography,
  components,
  overrides,
  shadows,
});

export default theme;

export const drawerWidth = 280;
