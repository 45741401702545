import React, { useState } from 'react'
import { AddContainer, Component4, NextFollowUpBox, NextFollowUpText, Texts, HeaderPart, FooterPart } from './Style'
import NumberInput from 'src/components/shared/Form/Number';
import { ReactComponent as CloseIcon } from '../../../../../../src/assets/images/close-circle.svg';
import { ReactComponent as AddIcon } from '../../../../../../src/assets/images/add-square.svg';
import ButtonComponent from 'src/components/shared/Button';
import { palette, shadows } from 'src/config/theme.js';
import { alpha } from '@mui/material';
function FollowUpTabForm({ onClose }) {
    const [followUpCount, setFollowUpCount] = useState(1);
    const [gapBetweenFollowUps, setGapBetweenFollowUps] = useState(0);
    return (
        <>
            <HeaderPart>
                Add Follow-ups
                <CloseIcon onClick={onClose} style={{ cursor: 'pointer' }} />
            </HeaderPart>
            <AddContainer>
                <Component4>
                    <Texts>How many Follow-ups?</Texts>
                    <NumberInput
                        fullWidth
                        width="430px" height="50px"
                        value={followUpCount}
                        onChange={setFollowUpCount}
                    />
                </Component4>
                <Component4>
                    <Texts>Gap between Follow-ups</Texts>
                    <NumberInput
                        fullWidth
                        width="430px" height="50px"
                        value={gapBetweenFollowUps}
                        onChange={setGapBetweenFollowUps}
                    />

                </Component4>
                <Component4>
                    <Texts>Next Follow-up Dates</Texts>
                    <NextFollowUpBox>
                        <NextFollowUpText>22/01/2025, 02/01/2025, 12/01/2025.</NextFollowUpText>
                    </NextFollowUpBox>
                </Component4>
            </AddContainer>
            <FooterPart >
                <ButtonComponent
                    variant="none"
                    startIcon={<CloseIcon sx={{ color: palette.primary.main }} />}
                    onClick={onClose}
                >
                    Cancel
                </ButtonComponent>
                <ButtonComponent
                    type="submit"
                    variant="outlined"
                    startIcon={<AddIcon sx={{ color: palette.primary.main }} />}
                    sx={{
                        width: '104px',
                        height: '52px',
                        borderRadius: '12px',
                        padding: '10px 18px',
                        bgcolor: palette.primary.main,
                        boxShadow: shadows[1],
                        border: `1px solid ${alpha(palette.gery.black, 0.14)}`,
                        color: palette.gery.white,
                        '&:hover': { color: palette.primary.main },
                    }}
                // onClick={handleSubmit ? () => handleSubmit() : null}
                >
                    Save
                </ButtonComponent>
            </FooterPart>
        </>
    )
}

export default FollowUpTabForm
