// import { ReactComponent as Dashboard } from 'src/assets/images/sidebar/dashboard.svg';
// import { ReactComponent as Appointment } from 'src/assets/images/sidebar/appointment.svg';
// import { ReactComponent as Patients } from 'src/assets/images/sidebar/patients.svg';
// import { ReactComponent as Billing } from 'src/assets/images/sidebar/billing.svg';
// import { ReactComponent as Clinics } from 'src/assets/images/sidebar/clinics.svg';
// import { ReactComponent as Settings } from 'src/assets/images/sidebar/settings.svg';
// import { ReactComponent as Staff } from 'src/assets/images/sidebar/staff.svg';
// import { ReactComponent as Tasks } from 'src/assets/images/sidebar/tasks.svg';

import { ReactComponent as Appointment } from 'src/assets/images/category.svg';
import { ReactComponent as Dashboard } from 'src/assets/images/chart.svg';
import { ReactComponent as Patients } from 'src/assets/images/people.svg';
import { ReactComponent as Staff } from 'src/assets/images/tag-user.svg';
import { ReactComponent as Tasks } from 'src/assets/images/task-square.svg';
import { ReactComponent as Clinics } from 'src/assets/images/hospital.svg';
import { ReactComponent as Billing } from 'src/assets/images/document-text.svg';


const iconConfig = {
  appointment: Appointment,
  dashboard: Dashboard,
  patients: Patients,
  staff: Staff,
  tasks: Tasks,
  clinics: Clinics,
  billing: Billing,
  // settings: Settings,
};

export default iconConfig;
