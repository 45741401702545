import React from "react";
import {
    PatientInfoBox, PatientName, AddressName,
    InfoTypography,
    DataTypography,
} from "./styles";
import { Container, Grid, } from "@mui/material";
import Img from 'src/assets/images/avatar.jpg'
import Stack from '@mui/material/Stack';
import { Avatar, Typography } from "@mui/material";
import Divider from '@mui/material/Divider'; 
import Edit from 'src/assets/images/welcome/EditIcon.svg'
import Spacer from "src/components/shared/Spacer";
function Infomation() {
    return (
        <>
            <Container maxWidth='lg'>
                <PatientInfoBox >
                    <Stack> <Avatar src={Img} sx={{
                        position: 'relative', left: 30, top: 20,
                    }} />
                        <PatientName variant="h4">Ansh Ashok Rathod</PatientName>
                        <AddressName variant="h6">1338,Emily Flat,Reginaldtown</AddressName>
                    </Stack>
                    <Grid container spacing={7} sx={{
                        marginLeft: '40%'
                    }}>
                        <InfoTypography variant="body1" >Age</InfoTypography>

                        <Spacer x={3}  />

                        <Divider orientation="vertical" flexItem style={{ backgroundColor: '#B7B7B7', height: 40 }} />
                        <Spacer x={3} />

                        <InfoTypography variant="body1">Sex</InfoTypography>
                        <Spacer x={3}/>

                        <Divider orientation="vertical" flexItem style={{ backgroundColor: '#B7B7B7', height: 40 }} />
                        <Spacer x={2} />

                        <InfoTypography variant="body1" >Weight</InfoTypography>
                        <Spacer x={2}  />
                        <Divider orientation="vertical" flexItem style={{ backgroundColor: '#B7B7B7', height: 40 }} />
                        <Spacer x={2} />
                        <InfoTypography variant="body1" >Height</InfoTypography>
                        <Spacer x={1} />

                        <Spacer x={2} />
                        <Divider orientation="vertical" flexItem style={{ backgroundColor: '#B7B7B7', height: 40 }} />
                        <Spacer x={2}  />
                        <InfoTypography variant="body1" >Basic</InfoTypography>
                        <Spacer x={1}  />
                    </Grid>

                    <Grid container spacing={7} sx={{
                        marginLeft: '40%'
                    }}>
                        <DataTypography variant="h5">8 Year</DataTypography>
                        <Spacer x={4.5}  />
                        <DataTypography variant="h5">Male</DataTypography>
                        <Spacer x={6}  />
                        <DataTypography variant="h5">60 kg</DataTypography>
                        <Spacer x={6}  />
                        <DataTypography variant="h5">179 cm</DataTypography>
                        <Spacer x={5}  />
                        <DataTypography variant="h5">Fever Etc.</DataTypography>
                    </Grid>

                    <Avatar src={Edit} sx={{
                       position:'relative', left:'92%',bottom:40
                    }}/>
                </PatientInfoBox>
            </Container>

        </>
    )
}

export default Infomation;