import api from 'src/api';

export const loginUser = (data) => {
  return api('/login', data, 'post');
};

export const getProfile = () => {
  return api('/profile', null, 'get');
};

