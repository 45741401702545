import { styled } from "@mui/material/styles";
import theme from "src/config/theme";
import { palette, shadows, typography } from "src/config/theme";
import { Avatar, Box, Chip, Typography, alpha } from "@mui/material";
import ButtonComponent from "src/components/shared/Button";
import AppMenu from "src/components/shared/Menu";


export const PatientHeaderContainer = styled(Box)({
    backgroundColor: palette.gery.white,
    height: '108px',
    padding: '0px 32px 0 32px',
    // borderBottom: `1px solid ${alpha(palette.gery.black, 0.102)}`,
    // boxShadow: shadows[3],
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
})

export const Part1 = styled(Box)({
    width: '729px',
    height: '70px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '10px',


})
export const Part2 = styled(Box)({
    width: '426px',
    height: '52px',
    display: 'flex',
    alignItems: 'center',
    gap: '24px',
})
export const AddItemButton = styled(ButtonComponent)({
    width: '172px',
    height: '52px',
    borderRadius: '12px',
    padding: '10px 18px',
    background: palette.primary.main,
    border: `1px solid ${alpha(palette.gery.black, 0.14)}`,
    boxShadow: shadows[1],
    color: palette.gery.white,
    justifyContent: 'space-between',
    '&:hover': {
        color: theme.palette.primary.main,
    }
})

export const MenuOptions = styled(AppMenu)({

    "&.MuiPaper-root": {
        borderRadius: "12px",
        boxShadow: shadows[2],
        // padding: "8px 0",
        border: '1px solid black'
    },

})

export const TwoIconContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    width: '206px',
    height: '52px'
})

export const PrevNextIconContainer = styled(ButtonComponent)({
    width: '102px',
    height: '52px',
    borderRadius: '12px',
    padding: '10px 18px',
    gap: '6px',
    boxShadow: shadows[1],
    color: palette.gery.black,
    ...typography.h5,
})
export const PatientDp = styled(Avatar)({
    width: '70px',
    height: '70px',
    borderRadius: '12px',
    padding: '10px',
    backgroundColor: palette.primary.light3,
    ...typography.h1,
    color: palette.primary.main
})
export const PatientDetails = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    width: '643px',
    height: '69px',

})
export const Name_TagContainer = styled(Box)({
    display: 'flex',
    width: '400px',
    height: '33px',
    gap: '12px',

})
export const AllDetails = styled(Box)({
    display: 'flex',
    gap: '20px',
    width: '643px',
    height: '24px',
    alignItems: 'center'
})

export const Name = styled(Typography)({
    ...typography.h1,
    width: '164px',
    height: '33px',
    textWrap: 'nowrap'
})

export const TagsContainer = styled(Box)({
    display: 'flex',
    gap: '8px'
})

export const Tags = styled(Chip)({
    width: 'auto',
    height: '33px',
    borderRadius: '100px',
    padding: '4px 12px',
    backgroundColor: palette.primary.light3,
    ...typography.body1
})

export const Icon_Text = styled(Box)({
    // width: '67px',
    height: '24px',
    display: 'flex',
    gap: '8px',
    alignItems: 'center'
})

export const Texts = styled(Typography)({
    color: `${alpha(palette.gery.black, 0.7)}`,
    ...typography.body1,
    textWrap: 'nowrap'
})

export const EditIcon = styled(Avatar)({
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    // padding: '8px 24px',
    backgroundColor: palette.primary.light4,
    cursor: 'pointer'
})

export const TabsContainer = styled(Box)({
    // backgroundColor: 'red',
    height: '68px',
    padding: '0px 32px 0 32px',
    borderBottom: `1px solid ${alpha(palette.gery.black, 0.1)}`,
    boxShadow: shadows[3],
    backgroundColor: palette.gery.white,
    display: 'flex',
    alignItems: 'flex-end'
})

export const TabsData = styled(Box)({
    backgroundColor: palette.gery.extraLightGray,
    padding: '32px',

})
