import React, { useState } from 'react'
import { AddContainer, EditContainer, EditLine, ProfileContainer, ProfileAvatar, ProfileIcon, Texts, TagsContainer, InnerComponent, Component2, HightContainer, HightInner, NextFollowUpText, Component4, HeaderPart, FooterPart } from './Style'
import { ReactComponent as Add } from '../../../../../assets/images/add-square.svg'
import InputSelect from 'src/components/shared/Form/Select';
import theme from 'src/config/theme';
import InputComponent from 'src/components/shared/Form/Input';
import { palette, shadows } from 'src/config/theme.js';
import { alpha } from '@mui/material';
import DateTimePicker from 'src/components/App/DatePicker';
import NumberInput from 'src/components/shared/Form/Number';
import DialogBox from 'src/components/shared/Dialog/DialogBox';
import { Chip, MenuItem, Select } from "@mui/material";
import { ReactComponent as Close } from '../../../../../assets/images/close-circle.svg'
import { ReactComponent as CloseIcon } from '../../../../../../src/assets/images/close-circle.svg';
import { ReactComponent as AddIcon } from '../../../../../../src/assets/images/add-square.svg';
import ButtonComponent from 'src/components/shared/Button';

function EditProfile({ onClose }) {
    const [open, setOpen] = useState(false);
    const [dialogType, setDialogType] = useState("");
    const handleOpen = (type) => {
        setDialogType(type);
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    const Gender = [
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
        { label: 'Other', value: 'other' },
    ];
    const Tag = [
        {
            label: 'Add New Tag', value: 'add new tag', customStyle: {
                color: `${theme.palette.primary.main} !important`,
                '&:hover': {
                    backgroundColor: 'transparent !important',
                },
            }
        },
        { label: 'Friend', value: 'friend' },
        { label: 'Relative', value: 'relative' },
    ];
    const Location = [
        {
            label: 'Add New Location', value: 'add new location', customStyle: {
                color: `${theme.palette.primary.main} !important`,

                '&:hover': {
                    backgroundColor: 'transparent !important',
                },
            }
        },
        { label: 'Odhav', value: 'odhav' },
        { label: 'Iskon', value: 'iskon' },
        { label: 'Narol', value: 'narol' },
    ]
    const [dob, setDob] = useState(null);
    const [select, setSelect] = useState({
        gender: '',
        tag: [],
        location: [],
    })
    // const handleChange = (name, value) => {
    //     if (value === 'add new tag' || value === 'add new location') {
    //         handleOpen(value);
    //     } else {
    //         setSelect((prev) => ({
    //             ...prev,
    //             [name]: Array.isArray(value) ? [...value] : value,
    //         }));
    //     }
    // }
    const handleChange = (name, value) => {
        if (Array.isArray(value) && (value.includes("add new tag") || value.includes("add new location"))) {
            handleOpen(name === "tag" ? "add new tag" : "add new location");
        }
        else if (value === "add new tag" || value === "add new location") {
            handleOpen(value);
        }
        else {
            setSelect((prev) => ({
                ...prev,
                [name]: Array.isArray(value) ? [...value] : value,
            }));
        }
    };

    return (
        <>
            <HeaderPart>
                Edit Profile Details
                <CloseIcon onClick={onClose} style={{ cursor: 'pointer' }} />
            </HeaderPart>
            <EditContainer>
                <AddContainer sx={{ width: '470px', alignItems: 'center' }}>
                    <ProfileContainer>
                        <ProfileAvatar>D</ProfileAvatar>
                        <ProfileIcon>
                            <Add width="24px" height="24px" />
                        </ProfileIcon>

                    </ProfileContainer>
                    <EditLine sx={{ width: '1', height: '0' }} />
                    <TagsContainer>
                        <Texts>Tags</Texts>
                        <InputSelect
                            multiple={true}
                            showNone={false}
                            value={select.tag}
                            options={Tag}
                            onChange={(value) => handleChange('tag', value)}
                            variant="outlined"
                            placeholder="Select Multiple"
                            fullWidth
                            renderValue={(selected) => (
                                <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
                                    {selected.map((tag) => (
                                        <Chip
                                            key={tag}
                                            label={tag}
                                            onDelete={() => handleChange("tag", selected.filter((t) => t !== tag))}
                                            deleteIcon={
                                                <Close
                                                    sx={{
                                                        color: theme.palette.action.red,
                                                    }}
                                                />
                                            }
                                            sx={{
                                                backgroundColor: theme.palette.primary.light3,
                                                color: "#000",
                                                borderRadius: "16px",
                                                padding: "4px 4px 4px 8px",
                                                ...theme.typography.body1,

                                            }}
                                        />
                                    ))}
                                </div>
                            )}

                            sx={{
                                // width: '200px',
                                boxShadow: theme.shadows[1],
                                '& .MuiOutlinedInput-root': {
                                    height: '52px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    // border: `1px solid ${alpha(theme.palette.gery.black, 0.14)}`,
                                    borderRadius: '12px',
                                },
                            }}
                        />

                    </TagsContainer>
                </AddContainer>
                <EditLine />
                <AddContainer sx={{ width: '460px' }}>
                    <Component2>
                        <InnerComponent>
                            <Texts>New Patient Name</Texts>
                            <InputComponent
                                variant="outlined"
                                placeholder="Enter Name"
                                fullWidth

                                sx={{
                                    backgroundColor: palette.gery.white,
                                    boxShadow: shadows[1],

                                    '& .MuiOutlinedInput-root': {
                                        padding: '10px 0px',
                                        borderRadius: '12px',
                                        // border: `1px solid ${alpha(palette.gery.black, 0.14)}`,
                                        // alignItems: 'flex-start',
                                        height: '52px',
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: `1px solid ${alpha(palette.gery.black, 0.14)}`,

                                    },
                                }}
                            />

                        </InnerComponent>
                        <InnerComponent>
                            <Texts>Phone Number</Texts>
                            <InputComponent
                                variant="outlined"
                                placeholder="Enter Number"
                                fullWidth

                                sx={{
                                    backgroundColor: palette.gery.white,
                                    boxShadow: shadows[1],

                                    '& .MuiOutlinedInput-root': {
                                        padding: '10px 0px',
                                        borderRadius: '12px',
                                        // border: `1px solid ${alpha(palette.gery.black, 0.14)}`,
                                        // alignItems: 'flex-start',
                                        height: '52px',
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: `1px solid ${alpha(palette.gery.black, 0.14)}`,
                                    },
                                }}
                            />


                        </InnerComponent>
                    </Component2>
                    <Component2>
                        <InnerComponent>
                            <Texts>Gender</Texts>
                            <InputSelect
                                showNone={false}
                                value={select.gender}
                                options={Gender}
                                onChange={(value) => handleChange('gender', value)}
                                variant="outlined"
                                placeholder="Select"
                                sx={{
                                    // width: '200px',
                                    boxShadow: theme.shadows[1],
                                    '& .MuiOutlinedInput-root': {
                                        height: '52px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        // border: `1px solid ${alpha(theme.palette.gery.black, 0.14)}`,
                                        borderRadius: '12px',
                                    },
                                }}
                            />

                        </InnerComponent>
                        <InnerComponent>
                            <Texts>Date of Birth</Texts>
                            <DateTimePicker
                                // label="Date of Birth"
                                value={dob}
                                onChange={setDob}
                                type="date"
                                sx={{
                                    height: "52px",
                                    borderRadius: '12px',
                                    // padding: '10px 16px',
                                }}
                            />
                        </InnerComponent>
                    </Component2>
                    <Component2>
                        <InnerComponent>
                            <Texts>Weight (kg)</Texts>
                            <NumberInput
                                fullWidth
                                width="198px"
                                height="50px"
                            // value={followUpCount}
                            // onChange={setFollowUpCount}
                            />

                        </InnerComponent>
                        <InnerComponent>
                            <Texts>Height</Texts>
                            <HightContainer>
                                <HightInner>
                                    <InputComponent
                                        variant="outlined"
                                        placeholder="0"
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                height: '22px',
                                                // width: '11px',
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none',
                                            },
                                        }} />
                                    <NextFollowUpText>ft</NextFollowUpText>
                                </HightInner>
                                <EditLine sx={{ height: "32px" }} />
                                <HightInner>
                                    <InputComponent
                                        variant="outlined"
                                        placeholder="0"
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                height: '22px',
                                                // width: '11px'
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                border: 'none',
                                            },
                                        }} />
                                    <NextFollowUpText>in</NextFollowUpText>
                                </HightInner>

                            </HightContainer>
                        </InnerComponent>
                    </Component2>
                    <TagsContainer>
                        <Texts>Location</Texts>
                        <InputSelect
                            multiple={true}
                            showNone={false}
                            value={select.location}
                            options={Location}
                            onChange={(value) => handleChange('location', value)}
                            variant="outlined"
                            placeholder="Select Multiple"
                            fullWidth
                            renderValue={(selected) => (
                                <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
                                    {selected.map((location) => (
                                        <Chip
                                            key={location}
                                            label={location}
                                            onDelete={() => handleChange("location", selected.filter((t) => t !== location))}
                                            deleteIcon={
                                                <Close
                                                    sx={{
                                                        color: theme.palette.action.red,
                                                    }}
                                                />
                                            }
                                            sx={{
                                                backgroundColor: theme.palette.primary.light3,
                                                color: "#000",
                                                borderRadius: "16px",
                                                padding: "4px 4px 4px 8px",
                                                ...theme.typography.body1,

                                            }}
                                        />
                                    ))}
                                </div>
                            )}

                            sx={{
                                // width: '200px',
                                boxShadow: theme.shadows[1],
                                '& .MuiOutlinedInput-root': {
                                    height: '52px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    // border: `1px solid ${alpha(theme.palette.gery.black, 0.14)}`,
                                    borderRadius: '12px',
                                },
                            }}
                        />

                    </TagsContainer>

                </AddContainer>
                <DialogBox open={open} onClose={handleClose} title={dialogType === 'add new tag' ? 'Add New Tag' : 'Add New Location'}>
                    {dialogType === 'add new tag' ?
                        <AddContainer>
                            <Component4>
                                <Texts>New Tag Name</Texts>
                                <InputComponent
                                    variant="outlined"
                                    placeholder="Enter Tag"
                                    fullWidth
                                    sx={{
                                        backgroundColor: palette.gery.white,
                                        boxShadow: shadows[1],
                                        '& .MuiOutlinedInput-root': {
                                            // padding: '10px 18px',
                                            borderRadius: '12px',
                                            height: '52px',
                                            // border: `1px solid ${alpha(palette.gery.black, 0.14)}`,
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: `1px solid ${alpha(palette.gery.black, 0.14)}`,
                                        },
                                    }}
                                />

                            </Component4>
                        </AddContainer>
                        :
                        <AddContainer>
                            <Component4>
                                <Texts>New Location Name</Texts>
                                <InputComponent
                                    variant="outlined"
                                    placeholder="Enter Location"
                                    fullWidth
                                    sx={{
                                        backgroundColor: palette.gery.white,
                                        boxShadow: shadows[1],
                                        '& .MuiOutlinedInput-root': {
                                            // padding: '10px 18px',
                                            borderRadius: '12px',
                                            height: '52px',
                                            // border: `1px solid ${alpha(palette.gery.black, 0.14)}`,
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: `1px solid ${alpha(palette.gery.black, 0.14)}`,
                                        },
                                    }}
                                />

                            </Component4>
                        </AddContainer>}
                </DialogBox>
            </EditContainer>
            <FooterPart >
                <ButtonComponent
                    variant="none"
                    startIcon={<CloseIcon sx={{ color: theme.palette.primary.main }} />}
                    onClick={onClose}
                >
                    Cancel
                </ButtonComponent>
                <ButtonComponent
                    type="submit"
                    variant="outlined"
                    startIcon={<AddIcon sx={{ color: theme.palette.primary.main }} />}
                    sx={{
                        width: '104px',
                        height: '52px',
                        borderRadius: '12px',
                        padding: '10px 18px',
                        bgcolor: theme.palette.primary.main,
                        boxShadow: theme.shadows[1],
                        border: `1px solid ${alpha(theme.palette.gery.black, 0.14)}`,
                        color: theme.palette.gery.white,
                        '&:hover': { color: theme.palette.primary.main },
                    }}
                // onClick={handleSubmit ? () => handleSubmit() : null}
                >
                    Save
                </ButtonComponent>
            </FooterPart>
        </>
    )
}

export default EditProfile
