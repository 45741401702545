import { reject } from 'lodash';
import * as appTypes from './appTypes';

export const setAppLoading = (loading) => ({
  type: appTypes.SET_APP_LOADING,
  loading,
});

//POST APPOINTMENT
export const postAppointmentRequest = (payload) => ({
  type: appTypes.POST_APPOINTMENT_REQUEST,
  payload
})
export const postAppointmentSuccess = (tokenappointment) => ({
  type: appTypes.POST_APPOINTMENT_SUCCESS,
  payload: { data: tokenappointment }
})
export const postAppointmentFailure = (error) => ({
  type: appTypes.POST_APPOINTMENT_FAILURE,
  payload: { error },
})

// GET APPOINTMENT 
export const getAppointmentRequest = (token, resolve, reject) => ({
  type: appTypes.GET_APPOINTMENT_REQUEST,
  payload: { token, resolve, reject }
})
export const getAppointmentSuccess = (data) => ({
  type: appTypes.GET_APPOINTMENT_SUCCESS,
  payload: { data }
})
export const getAppointmentFailure = (error) => ({
  type: appTypes.GET_APPOINTMENT_FAILURE,
  payload: { error }
})
// GET APPOINTMENT BY ID
export const getAppointmentByIdRequest = (appointmentId) => ({
  type: appTypes.GET_APPOINTMENT_BY_ID_REQUEST,
  payload: { appointmentId }
})
export const getAppointmentByIdSuccess = (data) => ({
  type: appTypes.GET_APPOINTMENT_BY_ID_SUCCESS,
  payload: { data }
})
export const getAppointmentByIdFailure = (error) => ({
  type: appTypes.GET_APPOINTMENT_BY_ID_FAILURE,
  payload: { error }
})

//POST PATIENT
export const postPatientRequest = (payload) => ({
  type: appTypes.POST_PATIENT_REQUEST,
  payload
})
export const postPatientSuccess = (patient) => ({
  type: appTypes.POST_PATIENT_SUCCESS,
  payload: { data: patient }
})
export const postPatientFailure = (error) => ({
  type: appTypes.POST_PATIENT_FAILURE,
  payload: { error }
})
//  GET PATIENT
export const getPatientRequest = (token, resolve, reject) => ({
  type: appTypes.GET_PATIENT_REQUEST,
  payload: { token, resolve, reject },
})
export const getPatientSuccess = (data) => ({
  type: appTypes.GET_PATIENT_SUCCESS,
  payload: { data }
})
export const getPatientFailure = (error) => ({
  type: appTypes.GET_PATIENT_FAILURE,
  payload: { error }
})

//GET PATIENT BY ID
export const getPatientByIdRequest = (patientId) => ({
  type: appTypes.GET_PATIENT_BY_ID_REQUEST,
  payload: { patientId }
})
export const getPatientByIdSuccess = (data) => ({
  type: appTypes.GET_PATIENT_BY_ID_SUCCESS,
  payload: { data }
})
export const getPatientByIdFailure = (error) => ({
  type: appTypes.GET_PATIENT_BY_ID_FAILURE,
  payload: { error }
})


//GET PATIENTINFO BY PATIENT ID
export const getPatientInfoByIdRequest = (patientId, resolve, reject) => ({
  type: appTypes.GET_PATIENTINFO_BY_ID_REQUEST,
  payload: { patientId, resolve, reject }
})
export const getPatientInfoByIdSuccess = (data) => ({
  type: appTypes.GET_PATIENTINFO_BY_ID_SUCCESS,
  payload: { data }
})
export const getPatientInfoByIdFailure = (error) => ({
  type: appTypes.GET_PATIENTINFO_BY_ID_FAILURE,
  payload: { error }
})

//GET TAG BY PATIENT ID
export const getTagByPatientIdRequest = (patientId, resolve, reject) => ({
  type: appTypes.GET_TAG_BY_PATIENT_ID_REQUEST,
  payload: { patientId, resolve, reject }
})
export const getTagByPatientIdSuccess = (data) => ({
  type: appTypes.GET_TAG_BY_PATIENT_ID_SUCCESS,
  payload: { data }
})

export const getTagByPatientIdFailure = (error) => ({
  type: appTypes.GET_TAG_BY_PATIENT_ID_FAILURE,
  payload: { error }
})

//POST MEDICAL RECORDS
export const postMedicalRecordsRequest = (data, resolve, reject) => ({
  type: appTypes.POST_MEDICALRECORD_REQUEST,
  payload: { data, resolve, reject }
})
export const postMedicalRecordsSuccess = (data) => ({
  type: appTypes.POST_MEDICALRECORD_SUCCESS,
  payload: { data }
})
export const postMedicalRecordsFailure = (error) => ({
  type: appTypes.POST_MEDICALRECORD_FAILURE,
  payload: { error }
})

//GET MEDICAL RECORDS BY ID 

export const getMedicalRecordsRequest = (patientId, resolve, reject) => ({
  type: appTypes.GET_MEDICALRECORD_BY_PATIENTID_REQUEST,
  payload: { patientId, resolve, reject }

})
export const getMedicalRecordSuccess = (data) => ({
  type: appTypes.GET_MEDICALRECORD_BY_PATIENTID_SUCCESS,
  payload: { data }

})
export const getMedicalRecordFailure = (error) => ({
  type: appTypes.GET_MEDICALRECORD_BY_PATIENTID_FAILURE,
  payload: { error }
})

//POST TEMPLATE
export const postTemplateRequest = (data, resolve, reject) => ({
  type: appTypes.POST_TEMPLATE_REQUEST,
  payload: { data, resolve, reject }
})
export const postTemplateSuccess = (data) => ({
  type: appTypes.POST_TEMPLATE_SUCCESS,
  payload: { data }
})
export const postTemplateFailure = (error) => ({
  type: appTypes.POST_TEMPLATE_FAILURE,
  payload: { error }
})

//GET TEMPLATE BY CLINIC ID
export const getTemplateByClinicIdRequest = (clinicId, resolve, reject) => ({
  type: appTypes.GET_TEMPLATE_BY_CLINICID_REQUEST,
  payload: { clinicId, resolve, reject }
})
export const getTemplateByClinicIdSuccess = (data) => ({
  type: appTypes.GET_TEMPLATE_BY_CLINICID_SUCCESS,
  payload: { data }
})
export const getTemplateByClinicIdFailure = (error) => ({
  type: appTypes.GET_TEMPLATE_BY_CLINICID_FAILURE,
  payload: { error }
})




//DELETE MEDICAL RECORD BY ID
export const deleteMedicalRecordRequest = (id, resolve, reject) => ({
  type: appTypes.DELETE_MEDICALRECORD_BY_ID_REQUEST,
  payload: { id, resolve, reject }

})
export const deleteMedicalRecordSuccess = (id) => ({

  type: appTypes.DELETE_MEDICALRECORD_BY_ID_SUCCESS,
  payload: { id }
})
export const deleteMedicalRecordFailure = (error) => ({
  type: appTypes.DELETE_MEDICALRECORD_BY_ID_FAILURE,
  payload: { error }
})


//UPDATE RECORD STATUS;
export const updateRecordStatusRequest = (appointmentId, data, resolve, reject) => ({
  type: appTypes.UPDATE_STATUS_REQUEST,
  payload: { appointmentId, data, resolve, reject },
})
export const updateRecordStatusSuccess = (data) => ({
  type: appTypes.UPDATE_STATUS_SUCCESS,
  payload: { data }
})
export const updateRecordStatusFailure = (error) => ({
  type: appTypes.UPDATE_STATUS_FAILURE,
  payload: { error }
})



//get Availibity
export const fetchAvailabilityRequest = (clinicId) => ({
  type: appTypes.FETCH_AVAILABILITY_REQUEST,
  payload: { clinicId },
});

export const fetchAvailabilitySuccess = (data) => ({
  type: appTypes.FETCH_AVAILABILITY_SUCCESS,
  data,
});

export const fetchAvailabilityFailure = (error) => ({
  type: appTypes.FETCH_AVAILABILITY_FAILURE,
  payload: { error },
});


export const getClinics = () => ({
  type: appTypes.GET_CLINICS,
});

export const setClinics = (data) => ({
  type: appTypes.SET_CLINICS,
  data,
});

export const setCurrentClinics = (data) => ({
  type: appTypes.SET_CURRENT_CLINIC,
  data,
});





