import React, { useState } from "react";
import CustomTable from "src/components/App/AppGrid/CustomTable/CustomTable";
import { Avatar, Chip, Box, alpha } from "@mui/material";
import theme from "src/config/theme";
import ButtonComponent from "src/components/shared/Button";
import DialogBox from "src/components/shared/Dialog/DialogBox";
import { ReactComponent as Edit } from '../../../../../assets/images/edit-2.svg';
import { ReactComponent as Delete } from '../../../../../assets/images/trash.svg';
import { ReactComponent as Add } from '../../../../../assets/images/add-square.svg';
import NoDataFound from "../../NoDataFound/NoDataFound";

import FollowUpTabForm from "../Forms/FollowUpTabForm";

const columns = [
  { id: "followups", label: "Follow-ups", sx: { width: "821px", minWidth: "150px", textAlign: 'left' } },
  { id: "appointmentDate", label: "Appointment Date", sx: { "& .css-axw7ok": { justifyContent: 'center' } } },
  { id: "status", label: 'Status', sx: { "& .css-axw7ok": { justifyContent: 'center' } } },
  { id: "addedBy", label: 'Added By', sx: { "& .css-axw7ok": { justifyContent: 'center' } } },
];
const data = [
  {
    followups: '15th Nov, 2024',
    appointmentDate: '-',
    status: <Chip label="Task Assigned" sx={{
      background: 'linear-gradient(0deg, rgba(103, 57, 150, 0.14), rgba(103, 57, 150, 0.14))',
      color: theme.palette.action.purpleLight,
      ...theme.typography.small,
      borderRadius: '100px',
      padding: '10px 18px 8px 18px',
    }} />,
    addedBy: 'John Doe'
  },
  {
    followups: '15th Nov, 2024',
    appointmentDate: '-',
    status: <Chip label="Task Assigned" sx={{
      background: 'linear-gradient(0deg, rgba(103, 57, 150, 0.14), rgba(103, 57, 150, 0.14))',
      color: theme.palette.action.purpleLight,
      ...theme.typography.small,
      borderRadius: '100px',
      padding: '10px 18px 8px 18px',
    }} />,
    addedBy: 'John Doe'
  },
  {
    followups: '15th Nov, 2024',
    appointmentDate: '-',
    status: <Chip label="Task Assigned" sx={{
      background: 'linear-gradient(0deg, rgba(103, 57, 150, 0.14), rgba(103, 57, 150, 0.14))',
      color: theme.palette.action.purpleLight,
      ...theme.typography.small,
      borderRadius: '100px',
      padding: '10px 18px 8px 18px',
    }} />,
    addedBy: 'John Doe'
  },
  {
    followups: '15th Nov, 2024',
    appointmentDate: '15th Nov, 2024',
    status: <Chip label="Appointment Scheduled" sx={{
      background: "linear-gradient(0deg, rgba(150, 57, 150, 0.12), rgba(150, 57, 150, 0.12))",
      color: theme.palette.action.purpleDark,
      ...theme.typography.small,
      borderRadius: '100px',
      padding: '10px 18px 8px 18px',
    }} />,
    addedBy: 'John Doe'
  },

];
const actions = [

  { label: "Edit", icon: <Edit />, onClick: (row) => alert(`Viewing ${row.patient}`) },
  { label: "Delete", icon: <Delete />, onClick: (row) => alert(`Attending ${row.patient}`) },
];

const FollowUps = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Box sx={{
        height: '756px',
        boxShadow: theme.shadows[1],
        borderRadius: '10px',
        border: `1px solid ${alpha(theme.palette.gery.black, 0.1)}`,
        backgroundColor: theme.palette.gery.white,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative'
      }}>
        <CustomTable
          columns={columns}
          data={data}
          actions={actions}
          headerButton={
            <ButtonComponent
              variant="contained"
              endIcon={<Add />}
              onClick={handleOpen}
              sx={{
                background: theme.palette.primary.main,
                border: `1px solid ${alpha(theme.palette.gery.black, 0.14)}`,
                boxShadow: theme.shadows[1],
                width: '170px',
                height: '36px',
                padding: '6px 6px 6px 10px',
                borderRadius: '12px',
                ...theme.typography.h5,
              }}
            >Add Follow-ups</ButtonComponent>
          }
          noDataComponent={
            <NoDataFound
              title="No Follow-ups Found!"
              buttonText="Add Follow-up"
              buttonIcon={Add}
              onClick={handleOpen}
            />
          }
        />
        <DialogBox open={open} >
          <FollowUpTabForm onClose={handleClose} />
        </DialogBox>
      </Box>
    </>
  )
};

export default FollowUps;

