import React, { useState } from 'react'
import { AddContainer, Component4, Texts, HeaderPart, FooterPart } from './Style';
import InputSelect from 'src/components/shared/Form/Select';
import InputComponent from 'src/components/shared/Form/Input';
import { palette, shadows } from 'src/config/theme.js';
import { alpha } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../../../../../src/assets/images/close-circle.svg';
import { ReactComponent as AddIcon } from '../../../../../../src/assets/images/add-square.svg';
import ButtonComponent from 'src/components/shared/Button';

import theme from 'src/config/theme.js';

const Language = [
    { label: 'English', value: 'english' },
    { label: 'Hindi', value: 'hindi' },
    { label: 'Gujarati', value: 'gujarati' }
];

const Template = [
    { label: 'Greetings!', value: 'greetings' },
    { label: 'Holiday', value: 'holiday' }
]


function MessageTabForm({ onClose }) {
    const [selectValue, setSelectValue] = useState({
        language: '',
        template: '',
    })
    const handleChange = (name, value) => {
        setSelectValue((prev) => ({
            ...prev,
            [name]: value,
        }))

    }
    return (
        <>
            <HeaderPart>
                Add Message
                <CloseIcon onClick={onClose} style={{ cursor: 'pointer' }} />
            </HeaderPart>
            <AddContainer>
                <Component4>
                    <Texts>Language</Texts>
                    <InputSelect
                        value={selectValue.language}
                        options={Language}
                        onChange={(value) => handleChange('language', value)}
                        variant="outlined"
                        placeholder="Select"
                        sx={{
                            // width: '200px',
                            boxShadow: theme.shadows[1],
                            '& .MuiOutlinedInput-root': {
                                height: '52px',
                                display: 'flex',
                                alignItems: 'center',
                                // border: `1px solid ${alpha(theme.palette.gery.black, 0.14)}`,
                                borderRadius: '12px',
                            },
                        }}
                    />

                </Component4>
                <Component4>
                    <Texts>Message Template</Texts>
                    <InputSelect
                        value={selectValue.template}
                        options={Template}
                        onChange={(value) => handleChange('template', value)}
                        variant="outlined"
                        placeholder="Select"
                        sx={{
                            // width: '200px',
                            boxShadow: theme.shadows[1],
                            '& .MuiOutlinedInput-root': {
                                height: '52px',
                                display: 'flex',
                                alignItems: 'center',
                                // border: `1px solid ${alpha(theme.palette.gery.black, 0.14)}`,
                                borderRadius: '12px',
                            },
                        }}
                    />

                </Component4>
                <Component4>
                    <Texts>Message</Texts>
                    <InputComponent
                        variant="outlined"
                        placeholder="Enter "
                        fullWidth
                        multiline
                        rows={4}
                        sx={{
                            backgroundColor: palette.gery.white,
                            boxShadow: shadows[1],
                            '& .MuiOutlinedInput-root': {
                                padding: '16px 18px',
                                borderRadius: '12px',
                                height: '120px',
                            },
                        }}
                    />


                </Component4>
            </AddContainer>
            <FooterPart >
                <ButtonComponent
                    variant="none"
                    startIcon={<CloseIcon sx={{ color: palette.primary.main }} />}
                    onClick={onClose}
                >
                    Cancel
                </ButtonComponent>
                <ButtonComponent
                    type="submit"
                    variant="outlined"
                    startIcon={<AddIcon sx={{ color: palette.primary.main }} />}
                    sx={{
                        width: '104px',
                        height: '52px',
                        borderRadius: '12px',
                        padding: '10px 18px',
                        bgcolor: palette.primary.main,
                        boxShadow: shadows[1],
                        border: `1px solid ${alpha(palette.gery.black, 0.14)}`,
                        color: palette.gery.white,
                        '&:hover': { color: palette.primary.main },
                    }}
                // onClick={handleSubmit ? () => handleSubmit() : null}
                >
                    Save
                </ButtonComponent>
            </FooterPart>
        </>
    )
}

export default MessageTabForm
