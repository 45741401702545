import { styled } from "@mui/material/styles";
import { palette, shadows, typography } from "src/config/theme";
import { Avatar, Typography, alpha, Box } from "@mui/material";
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';

import theme from 'src/config/theme';

export const Texts = styled(Typography)({
    color: palette.gery.black,
    ...theme.typography.smallRegular,
});


export const AddAppointmentContainer = styled(Box)(({ isExpanded, theme }) => ({
    backgroundColor: theme.palette.gery.white,
    // height: isExpanded ? '596px' : '496px',
    padding: '24px 20px 28px 20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    transition: 'height 0.3s ease-in-out'
}));

export const Component1 = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    // width: '430px',
    height: '76px',
});

export const Component2 = styled(Box)({
    height: '76px',
    display: 'flex',
    gap: '22px',
    justifyContent: 'space-between'
});
export const InnerComponent = styled(Box)({
    display: 'flex',
    gap: '4px',
    flexDirection: 'column',
    height: '76px',
    width: '204px',
})
export const Component3 = styled(Box)({
    height: '76px',
    display: 'flex',
    gap: '22px',
    justifyContent: 'space-between'
});
export const Component4 = styled(Box)({
    height: '144px',
    display: 'flex',
    gap: '4px',
    flexDirection: 'column'
});

export const HiddenComponent = styled(Box)({
    height: '76px',
    display: 'flex',
    gap: '22px',
    justifyContent: 'space-between'
});

export const FooterPart = styled(Box)({
    height: "100px",
    padding: '24px 20px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '16px',
    borderTop: `1px solid ${theme.palette.primary.light1} !important`
})


export const HeaderPart = styled(Box)({

    backgroundColor: theme.palette.primary.light3,
    color: theme.palette.gery.black,
    ...theme.typography.h3,
    borderBottom: `1px solid ${theme.palette.primary.light1} !important`,
    padding: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '64px',

})
