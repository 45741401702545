import { Container } from '@mui/material';
import React, { Component, useEffect, useState } from 'react'
import {
    PatientHeaderContainer, Part1, Part2, AddItemButton, MenuOptions, TwoIconContainer, PrevNextIconContainer, PatientDp,
    PatientDetails, Name_TagContainer, AllDetails, Name, TagsContainer, Tags, Icon_Text, Texts, EditIcon, TabsContainer,
    TabsData
} from './Style';
import { alpha } from '@mui/material';
import CustomTabs from 'src/components/shared/CustomTabs/CustomTabs';
import { ReactComponent as AddIcon } from "../../../../../src/assets/images/add-square.svg";
import { ReactComponent as DropDown } from '../../../../../src/assets/images/arrow-down.svg';
import { ReactComponent as PrevIcon } from '../../../../../src/assets/images/arrow-left 2.svg';
import { ReactComponent as NextIcon } from '../../../../../src/assets/images/arrow-right 2.svg';
import { ReactComponent as Man } from '../../../../../src/assets/images/woman.svg';
import { ReactComponent as Calender } from '../../../../../src/assets/images/calendar.svg';
import { ReactComponent as Call } from '../../../../../src/assets/images/call.svg';
import { ReactComponent as Weight } from '../../../../../src/assets/images/weight.svg';
import { ReactComponent as Height } from '../../../../../src/assets/images/ruler.svg';
import { ReactComponent as Location } from '../../../../../src/assets/images/location.svg';
import { ReactComponent as Edit } from '../../../../../src/assets/images/edit-2.svg';
import theme, { components, palette, typography } from 'src/config/theme';
import History from './Histroy/History';
import Appointments from './Appointments/Appointments';
import Notes from './Notes/Notes';
import Prescription from './Prescription/Prescription';
import FollowUps from './FollowUp/FollowUps';
import Payment from './Payments/Payment';
import Treatments from './Treatments/Treatments';
import Message from './Message/Message';
import Reports from './Reports/Reports';
import DialogBox from 'src/components/shared/Dialog/DialogBox';
import EditProfile from './Forms/EditProfile';
import { useParams } from 'react-router-dom';
import { getAppointmentByIdRequest, getPatientByIdRequest, getPatientInfoByIdRequest, getTagByPatientIdRequest } from '../../store/appActions';
import { useDispatch, useSelector } from 'react-redux';


function Patients() {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { appointmentId } = useParams();

    const tabsData = [
        { label: "History", component: <History /> },
        { label: "Appointments", component: <Appointments /> },
        { label: "Notes", component: <Notes /> },
        { label: "Prescriptions", component: <Prescription /> },
        { label: "Follow-ups", component: <FollowUps /> },
        { label: "Payments", component: <Payment /> },
        { label: "Treatment", component: <Treatments /> },
        { label: "Message", component: <Message /> },
        { label: "Reports", component: <Reports /> },
    ];
    const menuOptions = [
        { label: "Add Appointment" },
        { label: "Add Now" },
        { label: 'Add Prescription' },
        { label: 'Add Follow-up' },
        { label: 'Add Payment' },
        { label: 'Add Treatment' },
        { label: 'Add Message' },
        { label: 'Add Report' },
    ];
    const [menuOpen, setMenuOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const appointmentData = useSelector((state) => state.app.tokenAppointmentsById);
    const patientData = useSelector((state) => state.app.patientById);
    const patientInfoData = useSelector((state) => state.app.patientInfoById);
    const TagData = useSelector((state) => state.app.TagByPatientId);
    const TagArray = TagData?.payload?.data?.[0] || {};
    const patientInfoDetails = patientInfoData?.payload?.data?.[0] || {};
    const patientDetails = patientData?.payload?.data?.patient;
    const appointmentDetails = appointmentData?.payload?.data?.tokenappointment;
    const PatientId = appointmentDetails?.patient ?? null;



    useEffect(() => {
        if (PatientId) {
            dispatch(getPatientByIdRequest(PatientId));
            dispatch(getPatientInfoByIdRequest(PatientId));
            dispatch(getTagByPatientIdRequest(PatientId));
        }

        if (appointmentId) {
            dispatch(getAppointmentByIdRequest(appointmentId));
        }
    }, [PatientId, appointmentId, dispatch]);

    return (
        <Container maxWidth="xxl" disableGutters sx={{ backgroundColor: theme.palette.gery.extraLightGray }}>
            <PatientHeaderContainer>
                <Part1>
                    <PatientDp>D</PatientDp>
                    <PatientDetails>
                        <Name_TagContainer>
                            <Name>{patientDetails?.fullName || 'N/A'}</Name>
                            <TagsContainer>
                                {Array.isArray(TagArray?.tag) && TagArray.tag.length > 0 ? (
                                    TagArray.tag.map((tagLabel, index) => (
                                        <Tags key={index} label={tagLabel} />
                                    ))
                                ) : (
                                    <Tags label="No tags available" />
                                )}
                            </TagsContainer>

                        </Name_TagContainer>
                        <AllDetails>
                            <Icon_Text>
                                <Man />
                                <Texts>{patientDetails?.gender ? patientDetails.gender.charAt(0).toUpperCase() + patientDetails.gender.slice(1).toLowerCase() : 'N/A'}</Texts>

                            </Icon_Text>
                            <Icon_Text>
                                <Calender />
                                <Texts>{patientDetails?.age || 'N/A'} Years</Texts>


                            </Icon_Text>
                            <Icon_Text>
                                <Call />
                                <Texts>{patientDetails?.contactNo || 'N/A'}</Texts>


                            </Icon_Text>
                            <Icon_Text>
                                <Weight />
                                <Texts>{patientInfoDetails?.weight || 'N/A'}</Texts>


                            </Icon_Text>
                            <Icon_Text>
                                <Height />
                                <Texts>{patientInfoDetails?.height || 'N/A'}</Texts>
                            </Icon_Text>
                            <Icon_Text>
                                <Location />
                                <Texts>{patientDetails?.area || 'N/A'}</Texts>
                            </Icon_Text>
                            <EditIcon onClick={handleOpen}>
                                <Edit width="24px" height="24px" style={{ color: theme.palette.primary.main }} />
                            </EditIcon>
                        </AllDetails>
                    </PatientDetails>
                </Part1>
                <Part2>
                    <MenuOptions
                        options={menuOptions}
                        trigger="click"
                        iconButton={false}
                        onOpenChange={setMenuOpen}


                        title={
                            <AddItemButton variant="outlined" disableRipple onClick={() => setMenuOpen(menuOpen)}>
                                <AddIcon width="24px" height="24px" />
                                Add Item
                                <DropDown
                                    width="24px"
                                    height="24px"
                                    style={{
                                        transition: "transform 0.3s ease-in-out",
                                        transform: menuOpen ? "rotate(180deg)" : "rotate(0deg)"
                                    }}
                                />
                            </AddItemButton>
                        }
                    />
                    <TwoIconContainer>
                        <PrevNextIconContainer >
                            <PrevIcon width="24px" height="24px" variant="none" />
                            Prev
                        </PrevNextIconContainer>
                        <PrevNextIconContainer>
                            Next     <NextIcon width="24px" height="24px" />
                        </PrevNextIconContainer>
                    </TwoIconContainer>
                </Part2>
            </PatientHeaderContainer>
            <TabsContainer>
                <CustomTabs
                    tabs={tabsData}
                    onChange={(index) => setSelectedTab(index)}
                    sx={{
                        // backgroundColor: "#f5f5f5",

                        tabStyles: {
                            color: `${alpha(palette.gery.black, 0.7)}`,
                            selectedColor: palette.primary.main,
                            height: "56px",
                            fontSize: '17px',
                            fontWeight: '400px',
                            lineHeight: '21.62px'
                        },
                        indicatorStyles: {
                            color: palette.primary.main,
                            height: "2px",
                        },
                    }}
                />
            </TabsContainer>
            <TabsData >
                {tabsData[selectedTab].component}
            </TabsData>
            <DialogBox open={open}
                PaperProps={{
                    sx: {
                        maxWidth: 'none',
                    }
                }}>
                <EditProfile onClose={handleClose} />
            </DialogBox>
        </Container>
    )
}

export default Patients;
