import React, { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import { styled } from "@mui/system";
import theme from "src/config/theme";

const StyledTabs = styled(Tabs)(({ indicatorStyles }) => ({
    "& .MuiTabs-indicator": {
        backgroundColor: indicatorStyles?.color || "#283593",
        height: indicatorStyles?.height || "3px",
    },
    "& .MuiTabs-flexContainer": {
        gap: '2px'
    },
}));

const StyledTab = styled(Tab)(({ tabStyles }) => ({

    ...theme.typography.body1,
    padding: '10px 26px',
    textTransform: "none",
    // fontWeight: tabStyles?.fontWeight || 500,
    color: tabStyles?.color || "#757575",
    minHeight: tabStyles?.height || "48px", // Default height

    "&.Mui-selected": {
        color: tabStyles?.selectedColor || "#283593",
    },
}));

const CustomTabs = ({
    tabs,
    onChange,
    sx = {},
}) => {
    const [selectedTab, setSelectedTab] = useState(0);

    const handleChange = (_, newValue) => {
        setSelectedTab(newValue);
        if (onChange) onChange(newValue);
    };

    return (
        <Box sx={{ width: "100%", backgroundColor: sx.backgroundColor || "#fff" }}>
            <StyledTabs
                value={selectedTab}
                onChange={handleChange}
                indicatorStyles={sx.indicatorStyles || {}}
            >
                {tabs.map((tab, index) => (
                    <StyledTab key={index} label={tab.label} tabStyles={sx.tabStyles || {}} />
                ))}
            </StyledTabs>
        </Box>
    );
};

export default CustomTabs;
