import React, { useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Header from 'src/components/App/Header';
import Sidebar from 'src/components/App/Sidebar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import { SidebarOnOffContainer } from './styles';
import { drawerWidth } from 'src/config/theme';
import { useLocation } from 'react-router-dom';
import MenuClose from 'src/assets/images/sidebar-close.svg';
import MenuOpen from 'src/assets/images/sidebar-open.svg';
function AppLayout({ children, ...props }) {
  const location = useLocation();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const [isPermanentOpen, setIsPermanentOpen] = React.useState(true);
  const handleDrawerClose = () => {
    setIsPermanentOpen((prev) => !prev);
  };
  const handleDrawerToggle = () => {
    if (!isClosing) {
      setIsPermanentOpen(!isPermanentOpen);
      setMobileOpen(!mobileOpen);
    }
  };
  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };
  console.log('props');
  console.log(props);
  // const { window } = props;

  useEffect(() => {
    if(location.pathname === '/appointment/Patients') {
      setIsPermanentOpen(false);
    }
  })
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Sidebar
        window={window}
        mobileOpen={mobileOpen}
        isPermanentOpen={isPermanentOpen}
        handleDrawerClose={handleDrawerClose}
        handleDrawerTransitionEnd={handleDrawerTransitionEnd}
      />
      <SidebarOnOffContainer isPermanentOpen={isPermanentOpen} onClick={handleDrawerClose}>
        <Box
          component="img"
          src={isPermanentOpen ? MenuClose : MenuOpen}
          alt={isPermanentOpen ? 'Menu Close' : 'Menu Open'}
          sx={{
            display: 'flex',
            width: '24px',
            height: '24px',
            justifyContent: 'center',
          }}
        />
      </SidebarOnOffContainer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: {
            md: isPermanentOpen ? `calc(100% - ${drawerWidth}px)` : 'calc(100% - 70px)',
            lg: isPermanentOpen ? `calc(100% - ${drawerWidth}px)` : 'calc(100% - 70px)',
          },
          marginLeft: isPermanentOpen ? 0 : '0px',
          transition: 'all 0.3s ease-in-out',
          marginBottom: 4,
          // marginTop: 1,
          maxWidth: '100vw',
        }}
      >
        <Box

        >
          <Toolbar
            disableGutters
          >
            <Container maxWidth="xxl" disableGutters >
              <Header />
            </Container>
          </Toolbar>
        </Box>
        <Container maxWidth="xxl" disableGutters >{children}</Container>
      </Box>
    </Box>
  );
}
export default AppLayout;





