
import AppointmentContainer from '../container/AppointmentContainer';

import BillContainer from '../container/BillContainer';
import Patients from '../components/Patients/Patients';

export default [
  // {
  //   title: 'dashboard',
  //   component: DashboardContainer,
  //   url: '/',
  //   exact: true,
  //   auth: false,
  // },
  {
    title: 'appointment',
    component: AppointmentContainer,
    url: '/:clinicId/:doctorId/appointment',
    exact: true,
    auth: false,
    permission: 'appointment',
  },

  {
    title: 'Bill',
    component: BillContainer,
    url: '/bill',
    exact: true,
    auth: false,
  },



  {
    title: 'Patients',
    component: Patients,
    url: `/appointment/Patients/:appointmentId`,
    exact: true,
    auth: false,
  },

];

