import React, { useState } from 'react'
import { AddContainer, SittingOneBox, SittingText, Status, StatusContainer, Component4, StatusIcon, LinkText, Component3, Texts, HeaderPart, FooterPart } from './Style'
import { ReactComponent as TickCircle } from '../../../../../assets/images/tick-circle.svg';
import DialogBox from 'src/components/shared/Dialog/DialogBox';
import DateTimePicker from 'src/components/App/DatePicker/index.js';
import { palette, shadows } from 'src/config/theme';
import { ReactComponent as CloseIcon } from '../../../../../../src/assets/images/close-circle.svg';
import { ReactComponent as AddIcon } from '../../../../../../src/assets/images/add-square.svg';
import ButtonComponent from 'src/components/shared/Button';
import { alpha } from '@mui/material';

function TypeSitting({ onClose }) {
    const [isCompleted, setIsCompleted] = useState(false);
    const handleStatusClick = () => {
        setIsCompleted(true);
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [treatmentSchedule, setTreatmentSchedule] = useState({
        date: null,
        time: null,
    });
    const handleScheduleChange = (key, value) => {
        setTreatmentSchedule((prev) => ({
            ...prev,
            [key]: value,
        }));
    };
    return (
        <>
            <HeaderPart>
                Root Canal Treatments
                <CloseIcon onClick={onClose} style={{ cursor: 'pointer' }} />
            </HeaderPart>
            <AddContainer>
                <Component4>
                    <SittingOneBox>
                        <SittingText>
                            1. 15th Oct, 10:00am
                        </SittingText>
                        <StatusContainer>
                            <Status
                                label={isCompleted ? 'Completed' : 'Pending'}
                                sx={{
                                    background: isCompleted ? 'linear-gradient(0deg, rgba(57, 150, 57, 0.18), rgba(57, 150, 57, 0.18))' : 'linear-gradient(0deg, rgba(150, 57, 150, 0.12) 0%, rgba(150, 57, 150, 0.12) 100%), #FFF',
                                    color: isCompleted ? palette.action.green : palette.action.purpleDark,
                                }}
                            />
                            {!isCompleted && (
                                <StatusIcon onClick={handleStatusClick} style={{ cursor: 'pointer' }}>
                                    <TickCircle />
                                </StatusIcon>
                            )}

                        </StatusContainer>

                    </SittingOneBox>
                </Component4>
                <Component4>
                    <SittingOneBox>
                        <LinkText onClick={handleOpen}>
                            Schedule Second Sitting
                        </LinkText>
                    </SittingOneBox>
                </Component4>

                <DialogBox open={open} onClose={handleClose} >
                    <HeaderPart>
                        Schedule Second Sitting
                        <CloseIcon onClick={onClose} style={{ cursor: 'pointer' }} />
                    </HeaderPart>
                    <AddContainer>
                        <Component3>
                            <Texts>Second Treatment Date</Texts>
                            <DateTimePicker
                                // label="Date of Birth"
                                value={treatmentSchedule.date}
                                onChange={(value) => handleScheduleChange('date', value)}
                                fullWidth
                                type="date"
                                sx={{
                                    height: "52px",
                                    borderRadius: '12px',
                                    // padding: '10px 16px',
                                }}
                            />
                        </Component3>
                        <Component3>
                            <Texts>Second Treatment Time slot</Texts>
                            <DateTimePicker
                                value={treatmentSchedule.time}
                                onChange={(value) => handleScheduleChange('time', value)}
                                type="time"
                            />

                        </Component3>
                    </AddContainer>
                    <FooterPart >
                        <ButtonComponent
                            variant="none"
                            startIcon={<CloseIcon sx={{ color: palette.primary.main }} />}
                            onClick={onClose}
                        >
                            Cancel
                        </ButtonComponent>
                        <ButtonComponent
                            type="submit"
                            variant="outlined"
                            startIcon={<AddIcon sx={{ color: palette.primary.main }} />}
                            sx={{
                                width: '104px',
                                height: '52px',
                                borderRadius: '12px',
                                padding: '10px 18px',
                                bgcolor: palette.primary.main,
                                boxShadow: shadows[1],
                                border: `1px solid ${alpha(palette.gery.black, 0.14)}`,
                                color: palette.gery.white,
                                '&:hover': { color: palette.primary.main },
                            }}
                        // onClick={handleSubmit ? () => handleSubmit() : null}
                        >
                            Save
                        </ButtonComponent>
                    </FooterPart>
                </DialogBox>

            </AddContainer>
            <FooterPart >
                <ButtonComponent
                    variant="none"
                    startIcon={<CloseIcon sx={{ color: palette.primary.main }} />}
                    onClick={onClose}
                >
                    Cancel
                </ButtonComponent>
                <ButtonComponent
                    type="submit"
                    variant="outlined"
                    startIcon={<AddIcon sx={{ color: palette.primary.main }} />}
                    sx={{
                        width: '104px',
                        height: '52px',
                        borderRadius: '12px',
                        padding: '10px 18px',
                        bgcolor: palette.primary.main,
                        boxShadow: shadows[1],
                        border: `1px solid ${alpha(palette.gery.black, 0.14)}`,
                        color: palette.gery.white,
                        '&:hover': { color: palette.primary.main },
                    }}
                // onClick={handleSubmit ? () => handleSubmit() : null}
                >
                    Save
                </ButtonComponent>
            </FooterPart>
        </>
    )
}
export default TypeSitting
