

import React from 'react';
import Welcome from '../components/patient/Welcome';
import Infomation from '../components/patient/Infomation';
// import Bill from '../components/RightSidebar/Bill';
import { Container } from '@mui/material';
function TreatmentsContainer() {
  return (
    <>
      <Welcome />
      <Infomation />
      <Container maxWidth="lg">
        {/* <Bill /> */}
      </Container>
    </>
  );
}
export default TreatmentsContainer;