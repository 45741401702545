import * as appTypes from './appTypes';
import { recomposeColor } from '@mui/material';
import { template } from 'lodash';


const initialState = {
  file: null,
  isLoading: false,
  tokenAppointment: [],
  patient: [],
  patients: [],
  patientById: [],
  patientInfoById: [],
  TagByPatientId: [],
  medicalRecord: [],
  medicalRecordsByPatientId: [],
  medicalRecords: [],
  tokenAppointments: [],
  tokenAppointmentsById: [],
  updateStatusRecord: [],
  getTemplateByClinicId: [],
  template: [],
  getavailability: [],
  clinics: {
    data: [],
    currentClinic: {},
    clinicId: 0,
    doctorId: 0,
  },

};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case appTypes.SET_APP_LOADING:
      return { ...state, appLoading: payload.loading };

    //post  & GET appointment
    case appTypes.POST_APPOINTMENT_REQUEST:
      return {
        ...state, isLoading: true, error: null,
      };
    case appTypes.POST_APPOINTMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tokenAppointment: [...state.tokenAppointment, payload.data],
      };
    case appTypes.POST_APPOINTMENT_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    case appTypes.GET_APPOINTMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tokenAppointments: payload.data
      };
    case appTypes.GET_APPOINTMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error
      };

    case appTypes.GET_APPOINTMENT_BY_ID_SUCCESS:

      return {
        ...state,
        isLoading: false,
        tokenAppointmentsById: payload
      }
    case appTypes.GET_APPOINTMENT_BY_ID_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error
      }

    //post patient

    case appTypes.POST_PATIENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case appTypes.POST_PATIENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        patient: [...state.patient, payload.data],
      }
    case appTypes.POST_PATIENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      }

    //get patient 
    case appTypes.GET_PATIENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        patients: payload.data
      };
    case appTypes.GET_PATIENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error
      };

    //Get Patient By Id
    case appTypes.GET_PATIENT_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        patientById: payload
      }
    case appTypes.GET_PATIENT_BY_ID_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error
      }

    //GET PATIENTINFO BY PATIENT ID
    case appTypes.GET_PATIENTINFO_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        patientInfoById: payload
      }
    case appTypes.GET_PATIENTINFO_BY_ID_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error
      }

    //GET TAG BY PATIENT ID
    case appTypes.GET_TAG_BY_PATIENT_ID_SUCCESS:
      console.log("here is reducer response:", payload)
      return {
        ...state,
        isLoading: false,
        TagByPatientId: payload,
      }
    case appTypes.GET_TAG_BY_PATIENT_ID_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error
      }

    //POST Medical Records
    case appTypes.POST_MEDICALRECORD_REQUEST:
      return {
        ...state, isLoading: true, error: null,
      };
    case appTypes.POST_MEDICALRECORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        medicalRecord: [...state.medicalRecord, payload.data],
      };
    case appTypes.POST_MEDICALRECORD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error
      };
    //GET MEDICAL RECORDS BY PATIENT ID
    case appTypes.GET_MEDICALRECORD_BY_PATIENTID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        medicalRecordsByPatientId: payload,
      }
    case appTypes.GET_MEDICALRECORD_BY_PATIENTID_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      }
    //GET TEMPLATE BY CLINIC ID
    case appTypes.GET_TEMPLATE_BY_CLINICID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        getTemplateByClinicId: payload,
      }
    case appTypes.GET_TEMPLATE_BY_CLINICID_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      }

    case appTypes.POST_TEMPLATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,

      }
    case appTypes.POST_TEMPLATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        template: [...state.template, payload]
      }
    case appTypes.POST_TEMPLATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      }


    //DELETE MEDICAL RECORDS
    case appTypes.DELETE_MEDICALRECORD_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        medicalRecordsByPatientId: Array.isArray(state.medicalRecordsByPatientId)
          ? state.medicalRecordsByPatientId.filter((medicalRecord) => medicalRecord.id !== payload.id)
          : [],
      }
    case appTypes.DELETE_MEDICALRECORD_BY_ID_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error
      }




    //Update statusrecord
    case appTypes.UPDATE_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case appTypes.UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        updateStatusRecord: payload.data,
      }
    case appTypes.UPDATE_STATUS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error
      };


    //get Availibity

    case appTypes.FETCH_AVAILABILITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        getavailability: [...state.getavailability, payload.data],
      };
    case appTypes.FETCH_AVAILABILITY_FAILURE:
      return { ...state, isLoading: false, error: payload.error, getavailability: [] };

    case appTypes.SET_CLINICS:
      return { ...state, clinics: { ...state.clinics, data: payload.data?.clinics } };

    case appTypes.SET_CURRENT_CLINIC:
      const { clinicId, doctorId } = payload?.data || {};
      const currentClinic = state.clinics.data?.find((clinic) => clinic.id === clinicId) ?? {};
      return { ...state, clinics: { ...state.clinics, currentClinic, clinicId, doctorId } };
    default:
      return state;
  }
};

