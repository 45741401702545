



import React, { useState } from 'react';
import { Form, Button } from 'src/components/shared';
import * as Yup from "yup";
import InputComponent from 'src/components/shared/Form/Input';
import ButtonComponent from 'src/components/shared/Button';
import { Typography, Box, alpha } from '@mui/material';
import theme, { palette, shadows, typography } from 'src/config/theme';
import { Formik } from "formik";
import { useDispatch } from 'react-redux';
import { login } from '../store/authActions';

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email address").required("Email is required"),
  password: Yup.string().required("Password is required"),
})

function LoginForm() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (data) => {
    return new Promise((resolve, reject) => {
      dispatch(login(data, resolve, reject));
    });
  };

  
  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        console.log("onSubmit triggered", values);

        setLoading(true);
        try {
          await handleSubmit(values);
          console.log("Form submitted successfully");
        } catch (error) {
          console.error("Login failed:", error);
        }
        setLoading(false);
        setSubmitting(false);
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
            width: '340px',
          }}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '4px',
              height: '76px',

            }}>
              <Typography sx={{ ...typography.smallRegular, color: theme.palette.gery.black }}>
                Email
              </Typography>
              <InputComponent
                variant="outlined"
                name="email"
                fullWidth
                placeholder="Enter Email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                sx={{
                  backgroundColor: palette.gery.white,
                  boxShadow: shadows[1],

                  '& .MuiOutlinedInput-root': {
                    padding: '10px 0px',
                    borderRadius: '12px',

                    height: '52px',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: `1px solid ${alpha(palette.gery.black, 0.14)}`,

                  },
                }}
              />
            </Box>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '4px',
              height: '76px',
            }}>
              <Typography sx={{ ...typography.smallRegular, color: theme.palette.gery.black }}>
                Password
              </Typography>
              <InputComponent
                variant="outlined"
                name="password"
                placeholder="Enter Password"
                fullWidth
                type="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                sx={{
                  backgroundColor: palette.gery.white,
                  boxShadow: shadows[1],

                  '& .MuiOutlinedInput-root': {
                    padding: '10px 0px',
                    borderRadius: '12px',

                    height: '52px',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: `1px solid ${alpha(palette.gery.black, 0.14)}`,

                  },
                }}
              />
            </Box>

            <ButtonComponent
              variant="contained"
              fullWidth
              type="submit"
              onClick={() => handleSubmit(values)}
              // loading={loading}
              sx={{
                padding: '10px 0px',
                borderRadius: '12px',
                height: '52px',
              }}>
              Log In
            </ButtonComponent>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export default LoginForm;

