import React, { useState, useEffect } from "react";
import CustomTable from "src/components/App/AppGrid/CustomTable/CustomTable";
import { Avatar, Chip, Box, alpha, DialogContent, Typography } from "@mui/material";
import theme from "src/config/theme";
import ButtonComponent from "src/components/shared/Button";
import DialogBox from "src/components/shared/Dialog/DialogBox";
import { ReactComponent as Edit } from '../../../../../assets/images/edit-2.svg';
import { ReactComponent as Delete } from '../../../../../assets/images/trash.svg';
import { ReactComponent as Add } from '../../../../../assets/images/add-square.svg';
import { ReactComponent as View } from '../../../../../assets/images/eye.svg';
import { ReactComponent as Share } from '../../../../../assets/images/Share.svg';
import { ReactComponent as Print } from '../../../../../assets/images/printer.svg';
import { ReactComponent as Download } from '../../../../../assets/images/document-download.svg'
import PrescriptionTabForm from "../Forms/PrescriptionTabForm";
import NoDataFound from "../../NoDataFound/NoDataFound";
import { getMedicalRecordsRequest, deleteMedicalRecordRequest } from "src/modules/app/store/appActions";
import { useDispatch, useSelector } from "react-redux";
import { HeaderPart, FooterPart, AvatarIcon, SaveButton } from "../Forms/Style";
import { ReactComponent as CloseIcon } from '../../../../../../src/assets/images/close-circle.svg';
import { ReactComponent as AddIcon } from '../../../../../../src/assets/images/add-square.svg';
import { ReactComponent as Danger } from '../../../../../../src/assets/images/danger.svg'
import { palette, shadows } from 'src/config/theme.js';


const columns = [
  { id: "note", label: "Appointments", sx: { width: "1200px", minWidth: "150px", textAlign: 'left' } },
  { id: "addedDate", label: "Added Date", sx: { "& .css-axw7ok": { justifyContent: 'center' } } },
  // { id: "addedBy", label: "Added By", sx: { "& .css-axw7ok": { justifyContent: 'center' } } },

];





const Prescription = () => {
  const [open, setOpen] = useState(false);
  const [prescription, setPrescription] = useState([])
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const appointmentData = useSelector((state) => state.app.tokenAppointmentsById);
  const appointmentDetails = appointmentData?.payload?.data?.tokenappointment;
  const PatientId = appointmentDetails?.patient ?? null;
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deletePreId, setDeletePreId] = useState(null);

  const handleOpenDeleteDialog = (notesId) => {
    setDeletePreId(notesId);
    setOpenDeleteDialog(true);
  };
  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

  const updateNotes = (newprescription) => {
    setPrescription((prevPrescription) => [newprescription, ...prevPrescription]);
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await new Promise((resolve, reject) => {
        dispatch(getMedicalRecordsRequest(PatientId, resolve, reject));
      });
      if (response || response.data) {
        const filteredData = response.filter(notes => notes.recordType === 'prescription');
        setPrescription(filteredData);
        console.log("my notes filtered data", filteredData)
      } else {
        console.log('Error fetching notes');
      }
    }

    fetchData();
  }, [PatientId, dispatch]);

  const data = prescription.map((prescriptionData) => ({
    prescriptionId: prescriptionData?.id,
    note: prescriptionData?.notes,
    addedDate: new Date(prescriptionData.date).toLocaleDateString('en-GB', {
      day: '2-digit', month: 'short', year: 'numeric'
    }) + `, ${prescriptionData.time}`,

  }))

  const handleDelete = async (prescriptionId) => {
    if (prescriptionId) {
      try {
        await dispatch(deleteMedicalRecordRequest(prescriptionId));
        console.log("🚀 Dispatching delete action for ID:", prescriptionId);
        setPrescription((prevNotes) => prevNotes.filter(note => note.id !== prescriptionId));
        setOpenDeleteDialog(false);
        console.log('Note deleted successfully');
      } catch (error) {
        console.error('Failed to delete note:', error);
      }
    }
  };
  const actions = [
    { label: "View", icon: <View />, onClick: (row) => alert(`Viewing ${row.patient}`) },
    { label: "Share", icon: <Share />, onClick: (row) => alert(`Attending ${row.patient}`) },
    { label: "Print", icon: <Print />, onClick: (row) => alert(`Viewing ${row.patient}`) },
    { label: "Download", icon: <Download />, onClick: (row) => alert(`Attending ${row.patient}`) },
    { label: "Delete", icon: <Delete />, onClick: (row) => handleOpenDeleteDialog(row.prescriptionId) },
  ];
  return (
    <>
      <Box sx={{
        height: '756px',
        boxShadow: theme.shadows[1],
        borderRadius: '10px',
        border: `1px solid ${alpha(theme.palette.gery.black, 0.1)}`,
        backgroundColor: theme.palette.gery.white,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative'
      }}>
        <CustomTable
          columns={columns}
          data={data}
          actions={actions}
          headerButton={
            <ButtonComponent
              variant="contained"
              endIcon={<Add />}
              onClick={handleOpen}
              sx={{
                background: theme.palette.primary.main,
                border: `1px solid ${alpha(theme.palette.gery.black, 0.14)}`,
                boxShadow: theme.shadows[1],
                width: '188px',
                height: '36px',
                padding: '6px 6px 6px 10px',
                borderRadius: '12px',
                ...theme.typography.h5,
                // position: 'absolute',
                // left: '370px'
                // left: 'auto'
              }}
            >Add Prescriptions</ButtonComponent>
          }
          noDataComponent={
            <NoDataFound
              title="No Prescriptions Found!"
              buttonText="Add Prescription"
              buttonIcon={Add}
              onClick={handleOpen}
            />
          }
        />
        <DialogBox open={open} onClose={handleClose} >
          <PrescriptionTabForm onClose={handleClose} updateNotes={updateNotes} />
        </DialogBox>
        <DialogBox open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
          <HeaderPart>
            Delete Note
            <CloseIcon onClick={handleCloseDeleteDialog} style={{ cursor: 'pointer' }} />
          </HeaderPart>
          <DialogContent dividers >
            <Box sx={{ width: '470px', height: '314px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

              <Box sx={{ width: '232px', height: '186px', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', }}>
                <AvatarIcon sx={{ bgcolor: "transparent" }}>
                  <Danger width="24px" height="24px" />
                </AvatarIcon>
                <Typography variant="h5">Delete Appointment</Typography>
                <Typography sx={{ ...theme.typography.smallRegular, color: `${alpha(theme.palette.gery.black, 0.7)}`, textAlign: 'center', marginTop: '10px' }}>
                  Are you sure you want to Delete Prescription


                </Typography>
              </Box>

            </Box>
          </DialogContent>
          <FooterPart >
            <ButtonComponent
              variant="none"
              startIcon={<CloseIcon sx={{ color: palette.primary.main }} />}
              onClick={handleCloseDeleteDialog}
            >
              Cancel
            </ButtonComponent>
            <SaveButton
              type="submit"
              variant="outlined"
              startIcon={<AddIcon sx={{ color: palette.primary.main }} />}

              onClick={() => handleDelete(deletePreId)}
            >
              Save
            </SaveButton>
          </FooterPart>
        </DialogBox>

      </Box>
    </>
  )
};

export default Prescription;

