import { Avatar, Box, Typography, alpha } from '@mui/material';
import React from 'react';
import { ReactComponent as Danger } from '../../../../assets/images/danger.svg';
import theme from 'src/config/theme';
import ButtonComponent from 'src/components/shared/Button';
import { ReactComponent as Add } from '../../../../assets/images/add-square.svg'

const NoDataFound = ({
    title = "No Appointments Found!",
    buttonText = "Add Appointment",
    showButton = true,
    onClick = () => { },
    buttonSx = {},
    description = (
        <>
            No Data to Showcase <br />
            <span style={{ color: theme.palette.gery.black }}>Click “Add”</span> Button to Add Data!
        </>
    ),

}) => {
    return (
        <Box
            sx={{
                width: '279px',
                height: '264px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '17px',
                flexShrink: 0,
                flexDirection: 'column'
            }}
        >
            {/* Static Avatar Icon */}
            <Avatar sx={{
                width: 56, height: 56, bgcolor: theme.palette.primary.light3,
                border: `1px solid ${theme.palette.primary.light1}`,
                boxShadow: theme.shadows[1], color: theme.palette.primary.main
            }}>
                <Danger width="28px" height="28px" />
            </Avatar>

            {/* Dynamic Title */}
            <Typography variant='h2' sx={{ color: theme.palette.gery.black, textAlign: 'center' }}>
                {title}
            </Typography>

            {/* Static Description */}
            {/* <Typography variant='h6' sx={{ color: alpha(theme.palette.gery.black, 0.70), textAlign: 'center' }}>
                No Data to Showcase <br />
                <span style={{ color: theme.palette.gery.black }}>Click “Add”</span> Button to Add Data!
            </Typography> */}
            <Typography variant='h6' sx={{ color: alpha(theme.palette.gery.black, 0.70), textAlign: 'center' }}>
                {description}
            </Typography>

            {/* Dynamic Button */}
            {showButton && (
                <ButtonComponent
                    variant="outlined"
                    startIcon={<Add sx={{ color: theme.palette.primary.main }} />}
                    sx={{
                        borderRadius: '12px',
                        boxShadow: theme.shadows[1],
                        bgcolor: theme.palette.gery.white,
                        padding: '10px 18px',
                        ...theme.typography.h5,
                        ...buttonSx
                    }}
                    onClick={onClick}
                >
                    {buttonText}
                </ButtonComponent>
            )}


        </Box>
    );
};

export default NoDataFound;
