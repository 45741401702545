
import React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Content from './Content';
import { palette, drawerWidth } from 'src/config/theme';
function Sidebar({ mobileOpen, isPermanentOpen, handleDrawerTransitionEnd, handleDrawerClose }) {
  return (
    <Box
      component="nav"
      sx={{
        width: isPermanentOpen ? drawerWidth : '88px',
        flexShrink: 0,
      }}
      aria-label="sidebar"
    >
      {/* Temporary Drawer for Mobile */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerClose}
        onTransitionEnd={handleDrawerTransitionEnd}
        ModalProps={{ keepMounted: true }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            backgroundColor: palette.primary.main,
          },
        }}
      >
        <Content isPermanentOpen={isPermanentOpen} />
      </Drawer>
      {/* Permanent Drawer for Desktop */}
      <Drawer
        variant="permanent"
        sx={{
          width: isPermanentOpen ? drawerWidth : '88px',
          flexShrink: 0,
          whiteSpace: 'nowrap',
          transition: 'left 0.3s ease-in-out',
          '& .MuiDrawer-paper': {
            width: isPermanentOpen ? drawerWidth : '88px',
            overflowX: 'hidden',
            transition: 'left 0.3s ease-in-out',
          },
        }}
        open={isPermanentOpen}
      >
        <Content isPermanentOpen={isPermanentOpen} />
      </Drawer>
    </Box>
  );
}
export default Sidebar;