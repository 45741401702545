import React, { useState } from 'react';
import { Container } from '@mui/system';
import SwitchComponent from 'src/components/shared/Form/Switch';
import DialogBox from 'src/components/shared/Dialog/DialogBox';

import {
  StyledBox, GreetingTypography, ClinicTypography, SwitchContainer, CloseTypography, CalendarIcon
  , OpenTypography,
  GreetingBox,
  GreetingBoxTypography1,
  GreetingBoxTypography2,
  ThreeContent,
  Box1,
  Box2,
  SearchBox,
  SearchIcon,
  TableViewIcon,
  CardViewIcon,
  ToggleBox,


} from './styles';


import Calendar from '../../../../assets/images/calendar.svg';
import Search from '../../../../assets/images/search-normal.svg';
import theme, { palette } from 'src/config/theme';
import {
  Divider, Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  alpha
} from '@mui/material';
import { useTheme } from "@mui/material/styles";
import InputComponent from 'src/components/shared/Form/Input';
import TableIcon from '../../../../assets/images/grid-7.svg';
import CardIcon from '../../../../assets/images/grid-8.svg';
import ButtonComponent from 'src/components/shared/Button';
import AddAppointment from '../AddAppointment/AddAppointment';
import { ReactComponent as AddIcon } from '../../../../assets/images/add-square.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/images/close-circle.svg';
import { Box } from '@mui/material';
import storage from 'src/utils/storageUtils';

const Welcome = ({ category = '', setIsCardView, isCardView }) => {
  const DoctorName = storage.get('DOCTORNAME');

  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const handleToggle = (View) => {
    setIsCardView(View)
  }
  const message = {
    appointments: 'Here are list of all your appointments!',
    patients: 'Here are list of all your patients!',
    staff: 'Here are list of all your staff!',
    tasks: 'Here are list of all your tasks!',
    clinics: 'Here are list of all your clinics!',
    billing: 'Here are list of all your billing!',
    Dashboard: 'Welcome To Dashboard!'
  }
  const btnTxt = {
    appointments: 'Add Appointment',
    patients: 'Add Patient!',
    staff: 'Add Staff',
    tasks: 'Add Task!',
    clinics: 'Add clinic',
    billing: 'Add Billing!',
  }
  const greetingSubMessage = message[category] || 'Welcome!';
  const AddBtn = btnTxt[category] || 'Add Data';
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const formComponents = {
    appointments: <AddAppointment onClose={handleClose} />
  }


  const handleSubmitFallback = (event) => {
    event.preventDefault();
    console.log("Fallback handleSubmit called!");
  };

  return (
    <Container maxWidth="xxl" disableGutters>
      <StyledBox >
        <GreetingBox>
          <GreetingBoxTypography1 >Hey! {DoctorName} 👋</GreetingBoxTypography1>
          <GreetingBoxTypography2>{greetingSubMessage}</GreetingBoxTypography2>
        </GreetingBox>
        <ThreeContent>
          <Box1>
            <CalendarIcon src={Calendar} />
            <SearchBox>
              <SearchIcon src={Search} />
              <InputComponent placeholder="Search" variant="outlined" sx={{
                // paddingLeft: ,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                    width: 'auto'
                  },
                  "& input": {
                    paddingLeft: 0,
                    paddingRight: 0,
                  },
                },
              }} />
              {/* <SearchText placeholder="Search..." /> */}
            </SearchBox>
          </Box1>
          <Divider orientation='vertical' />
          <Box2>
            <ToggleBox>
              <Box sx={{
                width: '52px',
                height: '52px',
                backgroundColor: isCardView ? theme.palette.gery.white : theme.palette.primary.light3,
                border: `1px solid ${theme.palette.primary.light1}`,
                borderRight: "0px",
                borderTopLeftRadius: '12px',
                borderBottomLeftRadius: '12px',
                padding: '12px 14px 12px 14px',
                cursor: 'pointer'
              }}
                onClick={() => handleToggle(false)}

              >
                <TableViewIcon src={TableIcon} />
              </Box>
              <Box sx={{
                width: '52px',
                height: '52px',
                backgroundColor: isCardView ? theme.palette.primary.light3 : theme.palette.gery.white,
                border: `1px solid ${theme.palette.primary.light1}`,
                borderLeft: "0px",
                borderTopRightRadius: '12px',
                borderBottomRightRadius: '12px',
                padding: '12px 14px 12px 14px',
                cursor: 'pointer'
              }}
                onClick={() => handleToggle(true)}

              >
                <CardViewIcon src={CardIcon} />
              </Box>
            </ToggleBox>

            <ButtonComponent
              variant="outlined" startIcon={<AddIcon sx={{ color: theme.palette.primary.main }} />}
              sx={{
                width: '207px', height: '52px', borderRadius: '12px', padding: '10px 18px', bgcolor: theme.palette.primary.main, border: `1px solid ${theme.palette.primary.light1}`, color: theme.palette.gery.white, '&:hover': {
                  color: theme.palette.primary.main,
                  boxShadow: theme.shadows[1]
                }
              }}
              onClick={handleOpen}
            >{AddBtn}</ButtonComponent>
          </Box2>
        </ThreeContent>
      </StyledBox>

      <DialogBox
        open={open}
      // onClose={handleClose} 
      // title={AddBtn} 
      >
        {formComponents[category] || (
          <Typography variant="body1">
            No form available for this category.
          </Typography>
        )}

      </DialogBox>
    </Container >
  );
};

export default Welcome;