import React from 'react';
import { Form } from 'src/components/shared';
import LoginForm from '../components/LoginForm';
import { Typography } from '@mui/material';
import Spacer from 'src/components/shared/Spacer';
import theme from 'src/config/theme';

function LoginContainer() {
  return (
    <>
      {/* <Typography variant="h3" color={theme.palette.primary.main}>
        Log in to My Cliniq In HELLO WORLD
      </Typography> */}
      <Spacer x={2} y={2} />
      <LoginForm />
      <Spacer x={2} y={2} />
      {/* <Typography variant="body1" color="#ffffff84" component="div" textAlign="center">
        Not a member?{' '}
        <Typography variant="body1" color="white" component="span">
          Register Now
        </Typography>
      </Typography> */}
    </>
  );
}

export default LoginContainer;
