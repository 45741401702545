export const SET_APP_LOADING = 'SET_APP_LOADING';


export const SET_CLINICS = 'SET_CLINICS';
export const SET_CURRENT_CLINIC = 'SET_CURRENT_CLINIC';
export const GET_CLINICS = 'GET_CLINICS';

//POST APPOINTMENT
export const POST_APPOINTMENT_REQUEST = 'POST_APPOINTMENT_REQUEST';
export const POST_APPOINTMENT_SUCCESS = 'POST_APPOINTMENT_SUCCESS';
export const POST_APPOINTMENT_FAILURE = 'POST_APPOINTMENT_FAILURE';
//GET APPOINTMENT
export const GET_APPOINTMENT_REQUEST = 'GET_APPOINTMENT_REQUEST';
export const GET_APPOINTMENT_SUCCESS = 'GET_APPOINTMENT_SUCCESS';
export const GET_APPOINTMENT_FAILURE = 'GET_APPOINTMENT_FAILURE';

//GET APPOINTMENT_BY_ID
export const GET_APPOINTMENT_BY_ID_REQUEST = 'GET_APPOINTMENT_BY_ID_REQUEST';
export const GET_APPOINTMENT_BY_ID_SUCCESS = 'GET_APPOINTMENT_BY_ID_SUCCESS';
export const GET_APPOINTMENT_BY_ID_FAILURE = 'GET_APPOINTMENT_BY_ID_FAILURE';


//POST PATIENT
export const POST_PATIENT_REQUEST = 'POST_PATIENT_REQUEST';
export const POST_PATIENT_SUCCESS = 'POST_PATIENT_SUCCESS';
export const POST_PATIENT_FAILURE = 'POST_PATIENT_FAILURE';

//GET PATIENT
export const GET_PATIENT_REQUEST = 'GET_PATIENT_REQUEST';
export const GET_PATIENT_SUCCESS = 'GET_PATIENT_SUCCESS';
export const GET_PATIENT_FAILURE = 'GET_PATIENT_FAILURE';

//GET PATIENT_BY_ID
export const GET_PATIENT_BY_ID_REQUEST = 'GET_PATIENT_BY_ID_REQUEST';
export const GET_PATIENT_BY_ID_SUCCESS = 'GET_PATIENT_BY_ID_SUCCESS';
export const GET_PATIENT_BY_ID_FAILURE = 'GET_PATIENT_BY_ID_FAILURE';

//GET PATIENT_INFO_BY_ID
export const GET_PATIENTINFO_BY_ID_REQUEST = 'GET_PATIENTINFO_BY_ID_REQUEST';
export const GET_PATIENTINFO_BY_ID_SUCCESS = 'GET_PATIENTINFO_BY_ID_SUCCESS';
export const GET_PATIENTINFO_BY_ID_FAILURE = 'GET_PATIENTINFO_BY_ID_FAILURE';

//GET TAG BY PATIENT ID
export const GET_TAG_BY_PATIENT_ID_REQUEST = 'GET_TAG_BY_PATIENT_ID_REQUEST';
export const GET_TAG_BY_PATIENT_ID_SUCCESS = 'GET_TAG_BY_PATIENT_ID_SUCCESS';
export const GET_TAG_BY_PATIENT_ID_FAILURE = 'GET_TAG_BY_PATIENT_ID_FAILURE';

//UPDATE STATUS
export const UPDATE_STATUS_REQUEST = 'UPDATE_PATIENT_REQUEST';
export const UPDATE_STATUS_SUCCESS = 'UPDATE_STATUS_SUCCESS';
export const UPDATE_STATUS_FAILURE = 'UPDATE_STATUS_FAILURE';

//POST MEDICAL RECORDS 
export const POST_MEDICALRECORD_REQUEST = 'POST_MEDICALRECORD_REQUEST';
export const POST_MEDICALRECORD_SUCCESS = 'POST_MEDICALRECORD_SUCCESS';
export const POST_MEDICALRECORD_FAILURE = 'POST_MEDICALRECORD_FAILURE';

//GET MEDICAL RECORDS
export const GET_MEDICALRECORD_BY_PATIENTID_REQUEST = 'GET_MEDICALRECORD_BY_PATIENTID_REQUEST';
export const GET_MEDICALRECORD_BY_PATIENTID_SUCCESS = 'GET_MEDICALRECORD_BY_PATIENTID_SUCCESS';
export const GET_MEDICALRECORD_BY_PATIENTID_FAILURE = 'GET_MEDICALRECORD_BY_PATIENTID_FAILURE';

//DELETE MEDICAL RECORDS BY ID

export const DELETE_MEDICALRECORD_BY_ID_REQUEST = 'DELETE_MEDICALRECORD_BY_ID_REQUEST';
export const DELETE_MEDICALRECORD_BY_ID_SUCCESS = 'DELETE_MEDICALRECORD_BY_ID_SUCCESS';
export const DELETE_MEDICALRECORD_BY_ID_FAILURE = 'DELETE_MEDICALRECORD_BY_ID_FAILURE';

//GET AVAILABILITY

export const FETCH_AVAILABILITY_REQUEST = 'FETCH_AVAILABILITY_REQUEST';
export const FETCH_AVAILABILITY_SUCCESS = 'FETCH_AVAILABILITY_SUCCESS';
export const FETCH_AVAILABILITY_FAILURE = 'FETCH_AVAILABILITY_FAILURE';

//GET TEMPLATE BY CLINIC ID
export const GET_TEMPLATE_BY_CLINICID_REQUEST = 'GET_TEMPLATE_BY_CLINICID_REQUEST';
export const GET_TEMPLATE_BY_CLINICID_SUCCESS = 'GET_TEMPLATE_BY_CLINICID_SUCCESS';
export const GET_TEMPLATE_BY_CLINICID_FAILURE = 'GET_TEMPLATE_BY_CLINICID_FAILURE';

//POST TEMPLATE 
export const POST_TEMPLATE_REQUEST = 'POST_TEMPLATE_REQUEST';
export const POST_TEMPLATE_SUCCESS = 'POST_TEMPLATE_SUCCESS';
export const POST_TEMPLATE_FAILURE = 'POST_TEMPLATE_FAILURE';


//GET FOLLOW-UPS BY PATIENT ID 
export const GET_FOLLOWUPS_BY_PATIENTID_REQUEST = 'GET_FOLLOWUPS_BY_PATIENTID_REQUEST';
export const GET_FOLLOWUPS_BY_PATIENTID_SUCCESS = 'GET_FOLLOWUPS_BY_PATIENTID_SUCCESS';
export const GET_FOLLOWUPS_BY_PATIENTID_FAILURE = 'GET_FOLLOWUPS_BY_PATIENTID_FAILURE';

//GET TREATMENT BY PATIENT ID
export const GET_TREATMENT_BY_PATIENTID_REQUEST = 'GET_TREATMENT_BY_PATIENTID_REQUEST';
export const GET_TREATMENT_BY_PATIENTID_SUCCESS = 'GET_TREATMENT_BY_PATIENTID_SUCCESS';
export const GET_TREATMENT_BY_PATIENTID_FAILURE = 'GET_TREATMENT_BY_PATIENTID_FAILURE';

//GET BILLINGS BY PATIENT ID
export const GET_BILLINGS_BY_PATIENTID_REQUEST = 'GET_BILLINGS_BY_PATIENTID_REQUEST';
export const GET_BILLINGS_BY_PATIENTID_SUCCESS = 'GET_BILLINGS_BY_PATIENTID_SUCCESS';
export const GET_BILLINGS_BY_PATIENTID_FAILURE = 'GET_BILLINGS_BY_PATIENTID_FAILURE';

//GET MESSAGE BY PATIENT ID
export const GET_MESSAGE_BY_PATIENTID_REQUEST = 'GET_MESSAGE_BY_PATIENTID_REQUEST';
export const GET_MESSAGE_BY_PATIENTID_SUCCESS = 'GET_MESSAGE_BY_PATIENTID_SUCCESS';
export const GET_MESSAGE_BY_PATIENTID_FAILURE = 'GET_MESSAGE_BY_PATIENTID_FAILURE';

//GET REPORT BY PATIENT ID
export const REPORT_BY_PATIENTID_REQUEST = 'REPORT_BY_PATIENTID_REQUEST';
export const REPORT_BY_PATIENTID_SUCCESS = 'REPORT_BY_PATIENTID_SUCCESS';
export const REPORT_BY_PATIENTID_FAILURE = 'REPORT_BY_PATIENTID_FAILURE';






