// import React from 'react';
// import { Logo, Icon1, LogoContainer, SidebarOnOffContainer } from './styles';
// import { menuItems } from 'src/config/menu';
// import Item from './Item';
// import ListItemText from '@mui/material/ListItemText';

// import List from '@mui/material/List';
// import Stack from '@mui/material/Stack';
// import ListItem from '@mui/material/ListItem';
// import Typography from '@mui/material/Typography';
// import { useLocation } from 'react-router-dom';
// import theme from 'src/config/theme';

// function Content() {
//   const location = useLocation();
//   const pathname = location.pathname;

//   return (
//     <>

//       <LogoContainer>
//         <Logo />

//       </LogoContainer>
//       <Stack direction="column" height="100%" >
//         <List component="nav"
//           sx={{
//             // width: '2px',
//             height: '464px',
//             position: 'relative',
//             top: '70px',
//             display: 'flex',
//             flexDirection: 'column',
//             gap: '12px',
//           }}>
//           {menuItems.map((item, index) => (
//             <Item item={item} key={`menu-item-${index}`} pathname={pathname}
//             />
//           ))}
//         </List>
//         {/* <List>
//           <ListItem>
//             <ListItemText
//               secondary={
//                 <Typography variant="body2" color={theme.palette.primary.main} >
//                   copyright 2024
//                 </Typography>
//               }
//             />
//           </ListItem>
//         </List> */}
//       </Stack >



//       {/* <Icon1 /> */}
//     </>
//   );
// }

// export default Content;


import React from 'react';
import { menuItems } from 'src/config/menu';
import Item from './Item';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useLocation } from 'react-router-dom';
import { Logo, Logo2, LogoContainer, SidebarOnOffContainer } from './styles';
import { useSelector } from 'react-redux';
function Content({ isPermanentOpen }) {
  const location = useLocation();
  const pathname = location.pathname;
  const permissions = useSelector((state) => state.auth?.permissions);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: isPermanentOpen ? 'flex-start' : 'center',
        width: isPermanentOpen ? '279px' : '88px',
        transition: 'width 0.3s ease-in-out',
      }}
    >
      <LogoContainer>{isPermanentOpen === true ? <Logo /> : <Logo2 />}</LogoContainer>
      <Stack
        direction="column"
        justifyContent="space-between"
        position="absolute"
        top="136px"
        overflow="hidden"
      >
        <List component="nav">
          {menuItems.map(
            (item, index) =>
              (!item.permissions || permissions?.[item.permissions] === 'yes') && (
                <Item
                  key={`menu-item-${index}`}
                  item={item}
                  pathname={pathname}
                  isPermanentOpen={isPermanentOpen}
                />
              ),
          )}
        </List>
      </Stack>
    </Box>
  );
}
export default Content;





