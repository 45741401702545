import React, { useState } from 'react'
import { AddContainer, Component4, Texts, HeaderPart, FooterPart } from './Style';
import InputSelect from 'src/components/shared/Form/Select';
import theme from 'src/config/theme';
import InputComponent from 'src/components/shared/Form/Input';
import { palette, shadows } from 'src/config/theme.js';
import { alpha } from '@mui/material';
import DateTimePicker from 'src/components/App/DatePicker';
import { ReactComponent as CloseIcon } from '../../../../../../src/assets/images/close-circle.svg';
import { ReactComponent as AddIcon } from '../../../../../../src/assets/images/add-square.svg';
import ButtonComponent from 'src/components/shared/Button';
const PayFor = [
    { label: 'Consultation', value: 'consultation' },
    { label: 'X-ray', value: 'x-ray' },
]
const Pay_Type = [
    { label: 'Credit', value: 'credit' },
    { label: 'Debit', value: 'debit' },
]
const Pay_Method = [
    { label: 'Cash', value: 'cash' },
    { label: 'Card', value: 'card' },
    { label: 'UPI', value: 'upi' },
]

function PaymentTabForm({ onClose }) {
    const [Date, setDate] = useState('');
    const [selectValue, setSelectValue] = useState({
        payfor: '',
        paytype: '',
        paymethod: '',
    });

    const handleChange = (name, value) => {
        setSelectValue((prev) => ({
            ...prev,
            [name]: value

        }));
    };
    return (
        <>
            <HeaderPart>
                Add Payment
                <CloseIcon onClick={onClose} style={{ cursor: 'pointer' }} />
            </HeaderPart>
            <AddContainer>
                <Component4>
                    <Texts>Payment For</Texts>
                    <InputSelect
                        value={selectValue.payfor}
                        options={PayFor}
                        onChange={(value) => handleChange('payfor', value)}
                        variant="outlined"
                        placeholder="Select"
                        sx={{
                            // width: '200px',
                            boxShadow: theme.shadows[1],
                            '& .MuiOutlinedInput-root': {
                                height: '52px',
                                display: 'flex',
                                alignItems: 'center',
                                // border: `1px solid ${alpha(theme.palette.gery.black, 0.14)}`,
                                borderRadius: '12px',
                            },
                        }}
                    />

                </Component4>
                <Component4>
                    <Texts>Amount</Texts>
                    <InputComponent
                        variant="outlined"
                        placeholder="Enter Amount"
                        fullWidth
                        sx={{
                            backgroundColor: palette.gery.white,
                            boxShadow: shadows[1],
                            '& .MuiOutlinedInput-root': {
                                // padding: '10px 18px',
                                borderRadius: '12px',
                                height: '52px',
                                // border: `1px solid ${alpha(palette.gery.black, 0.14)}`,
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: `1px solid ${alpha(palette.gery.black, 0.14)}`,
                            },
                        }}
                    />

                </Component4>
                <Component4>
                    <Texts>Payment Date</Texts>
                    <DateTimePicker
                        // label="Date of Birth"
                        value={Date}
                        onChange={setDate}
                        fullWidth
                        type="date"
                        sx={{
                            height: "52px",
                            borderRadius: '12px',
                            // padding: '10px 16px',
                        }}
                    />
                </Component4>
                <Component4>
                    <Texts>Payment Type</Texts>
                    <InputSelect
                        value={selectValue.paytype}
                        options={Pay_Type}
                        onChange={(value) => handleChange('paytype', value)}
                        variant="outlined"
                        placeholder="Select"
                        sx={{
                            // width: '200px',
                            boxShadow: theme.shadows[1],
                            '& .MuiOutlinedInput-root': {
                                height: '52px',
                                display: 'flex',
                                alignItems: 'center',
                                // border: `1px solid ${alpha(theme.palette.gery.black, 0.14)}`,
                                borderRadius: '12px',
                            },
                        }}
                    />

                </Component4>
                <Component4>
                    <Texts>Method</Texts>
                    <InputSelect
                        value={selectValue.paymethod}
                        options={Pay_Method}
                        onChange={(value) => handleChange('paymethod', value)}
                        variant="outlined"
                        placeholder="Select"
                        sx={{
                            // width: '200px',
                            boxShadow: theme.shadows[1],
                            '& .MuiOutlinedInput-root': {
                                height: '52px',
                                display: 'flex',
                                alignItems: 'center',
                                // border: `1px solid ${alpha(theme.palette.gery.black, 0.14)}`,
                                borderRadius: '12px',
                            },
                        }}
                    />

                </Component4>





            </AddContainer>
            <FooterPart >
                <ButtonComponent
                    variant="none"
                    startIcon={<CloseIcon sx={{ color: palette.primary.main }} />}
                    onClick={onClose}
                >
                    Cancel
                </ButtonComponent>
                <ButtonComponent
                    type="submit"
                    variant="outlined"
                    startIcon={<AddIcon sx={{ color: palette.primary.main }} />}
                    sx={{
                        width: '104px',
                        height: '52px',
                        borderRadius: '12px',
                        padding: '10px 18px',
                        bgcolor: palette.primary.main,
                        boxShadow: shadows[1],
                        border: `1px solid ${alpha(palette.gery.black, 0.14)}`,
                        color: palette.gery.white,
                        '&:hover': { color: palette.primary.main },
                    }}
                // onClick={handleSubmit ? () => handleSubmit() : null}
                >
                    Save
                </ButtonComponent>
            </FooterPart>
        </>
    )
}

export default PaymentTabForm
