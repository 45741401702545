import React, { useState } from "react";
import CustomTable from "src/components/App/AppGrid/CustomTable/CustomTable";
import { Avatar, Chip, Box, alpha } from "@mui/material";
import theme from "src/config/theme";
import { ReactComponent as Profile } from '../../../../../assets/images/profile-circle.svg';
import { ReactComponent as ArrowRight } from '../../../../../assets/images/arrow-right 2.svg';
import { ReactComponent as SliderVertical } from '../../../../../assets/images/slider-vertical.svg';
import { ReactComponent as Edit2 } from '../../../../../assets/images/edit-2.svg';
import { ReactComponent as Close } from '../../../../../assets/images/close-circle.svg';
import { ReactComponent as Add } from '../../../../../assets/images/add-square.svg'
import ButtonComponent from "src/components/shared/Button";
import DialogBox from "src/components/shared/Dialog/DialogBox";
import AppointmentTabForm from "../Forms/AppointmentTabForm";
import NoDataFound from "../../NoDataFound/NoDataFound";

const columns = [
  { id: "appointments", label: "Appointments", sx: { width: "400px", minWidth: "150px", textAlign: 'left' } },
  { id: "symptoms", label: "Symptoms", sx: { width: "1200px", textAlign: 'left !important' } },
  {
    id: "status", label: "Status", sx: {
      textAlign: 'center !important',
      "& .css-axw7ok": { justifyContent: 'center' }
    }
  },
];

const data = [
  {
    appointments: '15th Oct, 10:00am',
    symptoms: "Headache, Vomit, Pain.",
    status: <Chip label="Scheduled" sx={{
      background: "linear-gradient(0deg, rgba(150, 57, 150, 0.12), rgba(150, 57, 150, 0.12))",
      color: theme.palette.action.purpleDark,
      ...theme.typography.small,
      borderRadius: '100px',
      padding: '10px 18px 8px 18px',
    }} />,
  },
  {
    appointments: '15th Oct, 10:00am',
    symptoms: "Headache, Vomit, Pain.",
    status: <Chip label="In Queue" sx={{
      background: 'linear-gradient(0deg, rgba(103, 57, 150, 0.14), rgba(103, 57, 150, 0.14))',
      color: theme.palette.action.purpleLight,
      ...theme.typography.small,
      borderRadius: '100px',
      padding: '10px 18px 8px 18px',
    }} />,

  },
  {

    appointments: '15th Oct, 10:00am',
    symptoms: "Headache, Vomit, Pain.",
    status: <Chip label="Attending Now" sx={{
      background: 'linear-gradient(0deg, rgba(57, 150, 57, 0.18), rgba(57, 150, 57, 0.18))',
      color: theme.palette.action.green,
      borderRadius: '100px',
      padding: '10px 18px 8px 18px',
      ...theme.typography.small
    }} />,
  },

  {
    appointments: '15th Oct, 10:00am',
    symptoms: "Headache, Vomit, Pain.",
    status: <Chip label="Complete" sx={{
      background: 'linear-gradient(0deg, rgba(57, 150, 57, 0.18), rgba(57, 150, 57, 0.18))',
      color: theme.palette.action.green,
      borderRadius: '100px',
      padding: '10px 18px 8px 18px',
      ...theme.typography.small
    }} />,
    date: "15th Oct, 10:00am"
  },
  {
    appointments: '15th Oct, 10:00am',
    symptoms: "Headache, Vomit, Pain.",
    status: <Chip label="Cancelled" sx={{
      background: ' background: `linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, rgba(150, 57, 57, 0.18), rgba(150, 57, 57, 0.18))`',
      color: theme.palette.action.red,
      borderRadius: '100px',
      padding: '10px 18px 8px 18px',
      ...theme.typography.small
    }} />,
  },

];

const actions = [
  { label: "View Profile", icon: <Profile />, onClick: (row) => alert(`Viewing ${row.patient}`) },
  { label: "Attend Now", icon: <ArrowRight />, onClick: (row) => alert(`Attending ${row.patient}`) },
  { label: "Add to Queue", icon: <SliderVertical />, onClick: (row) => alert(`Adding ${row.patient} to Queue`) },
  { label: "Edit", icon: <Edit2 />, onClick: (row) => alert(`Editing ${row.patient}`) },
  { label: "Cancel", icon: <Close />, onClick: (row) => alert(`Cancelling ${row.patient}`) }
];


const Appointments = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Box sx={{
        height: '756px',
        boxShadow: theme.shadows[1],
        borderRadius: '10px',
        border: `1px solid ${alpha(theme.palette.gery.black, 0.1)}`,
        backgroundColor: theme.palette.gery.white,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative'

      }}>
        <CustomTable
          columns={columns}
          data={data}
          actions={actions}
          headerButton={
            <ButtonComponent
              variant="contained"
              endIcon={<Add />}
              onClick={handleOpen}
              sx={{
                background: theme.palette.primary.main,
                border: `1px solid ${alpha(theme.palette.gery.black, 0.14)}`,
                boxShadow: theme.shadows[1],
                width: '187px',
                height: '36px',
                padding: '6px 6px 6px 10px',
                borderRadius: '12px',
                ...theme.typography.h5,
                // position: 'absolute',
                // left: '370px'
                // left: 'auto'
              }}
            >Add Appointment</ButtonComponent>
          }
          noDataComponent={
            <NoDataFound
              title="No Appointments Found!"
              buttonText="Add Appointment"
              buttonIcon={Add}
              onClick={handleOpen}
            />
          }
        />
        <DialogBox open={open}  >
          <AppointmentTabForm onClose={handleClose} />

        </DialogBox>

      </Box>
    </>
  )
};

export default Appointments;

