import React, { useState, useEffect } from 'react';
import { AddAppointmentContainer, Texts, Component1, Component2, InnerComponent, Component4, Component3, HiddenComponent, FooterPart, HeaderPart } from './style.js';
import InputSelect from 'src/components/shared/Form/Select/index.js';
import { useTheme } from '@emotion/react';
import InputComponent from 'src/components/shared/Form/Input/index.js';
import theme, { palette, shadows } from 'src/config/theme.js';
import { alpha, Box } from '@mui/material';
import DateTimePicker from 'src/components/App/DatePicker/index.js';
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { postAppointmentRequest, postPatientRequest, getPatientRequest, setCurrentClinics } from '../../store/appActions.js';
import { Form } from '../../../../../src/components/shared';
import storage from 'src/utils/storageUtils.js';
import { jwtDecode } from 'jwt-decode';
import { reject } from 'lodash';
import ButtonComponent from 'src/components/shared/Button/index.js';
import { ReactComponent as CloseIcon } from '../../../../../src/assets/images/close-circle.svg';
import { ReactComponent as AddIcon } from '../../../../../src/assets/images/add-square.svg';
import { getUserFromToken } from 'src/DecodeToken.js';
import dayjs from 'dayjs';


const genderData = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
    { label: 'Other', value: 'other' },
]
// const Patients = [
//     { label: 'Demo Patient (1234567890)', value: 'demo patient (1234567890)' },
//     { label: 'Demo Patient (234567890', value: 'demo Patient (234567890' },
// ]



const validationSchema = Yup.object().shape({
    // fullName: Yup.string().when('selectValue', {
    //     is: 'new patient',
    //     then: (schema) => schema.required('Enter Patient Name'),
    // }),
    // contactNo: Yup.string().when('selectValue', {
    //     is: 'New Patient',
    //     then: (schema) => schema.required('Enter Phone Number').matches(/^\d{10}$/, 'Contact number must be exactly 10 digits'),
    // }),

    // dateOfBirth: Yup.date().nullable().when('selectValue', {
    //     is: 'New Patient',
    //     then: (schema) => schema.required('Select Your Date of Birth'),
    // }),

    // gender: Yup.string().when('selectValue', {
    //     is: 'New Patient',
    //     then: (schema) => schema.required('Select Your gender'),

    // }),
    date: Yup.date().required('Select Appointment Date'),
    time: Yup.string().required('Select Appointment Time'),
    addnotes: Yup.string().required('Enter Symptoms'),
})
function AddAppointment({ onClose }) {
    const theme = useTheme();
    const [dob, setDob] = useState(null);
    const [patients, setPatients] = useState([]);
    const clinicData = useSelector((state) => state.app.clinics);
    console.log('my fav clinic', clinicData)
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            const token = storage.get('TOKEN');
            const response = await new Promise((resolve, reject) =>
                dispatch(getPatientRequest(token, resolve, reject)),
            );
            console.log('Fetched Patients:', response);
            if (response && response.length) {
                setPatients(response.map((patient) => ({
                    label: patient.fullName,
                    value: patient.id,
                })));
            }
        };
        fetchData();
    }, [dispatch]);



    const modifiedPatients = [
        {
            label: 'New Patient', value: 'New Patient', customStyle: {
                color: `${theme.palette.primary.main} !important`,
                '&:hover': {
                    backgroundColor: 'transparent !important',
                },
            }

        },
        ...patients,
    ];


    const [selectValue, setSelectValue] = useState('');
    const [selectValue2, setSelectValue2] = useState('');

    const [initialValues, setInitialValue] = useState({
        fullName: '',
        dateOfBirth: null,
        gender: '',
        contactNo: '',
        // patient:''
        date: dayjs(),

        time: dayjs().format('HH:mm'),
        addnotes: '',
    })


    const handleSubmit = async (values, { resetForm }) => {
        console.log('Form Submitted with values:', values);
        const userId = getUserFromToken();
        console.log("my user id here", userId)
        if (!userId) {
            console('Failed to identify user. Please login again.', { variant: 'error' });
            return;
        }

        try {
            let patientId = selectValue;
            if (selectValue === 'New Patient') {
                const patientData = {
                    fullName: values.fullName,
                    contactNo: values.contactNo,
                    gender: values.gender,
                    dateOfBirth: values.dateOfBirth,
                    users: [userId],
                };

                const createdPatient = await new Promise((resolve, reject) => {
                    dispatch(postPatientRequest({ data: patientData, resolve, reject }));
                });

                patientId = createdPatient?.patient?.id;

                if (!patientId) {
                    console.log('Failed to create patient. Please try again.', { variant: 'error' });
                    return;
                }

                console.log('Patient added successfully', { variant: 'success' });
            }

            // Create Appointment
            const appointmentData = {
                date: values.date,
                time: values.time,
                addnotes: values.addnotes,
                scheduleType: 'appointment',
                recordStatus: 'Scheduled',
                user: userId,
                patient: patientId,
                clinic: clinicData?.clinicId,
            };

            console.log('my appointment data', appointmentData)

            await new Promise((resolve, reject) => {
                dispatch(postAppointmentRequest({ data: appointmentData, resolve, reject }));
            });

            console.log('Appointment added successfully', { variant: 'success' });

            resetForm();
            onClose();
        } catch (error) {
            console.log(error.message || 'Failed to add appointment', { variant: 'error' });
        }
    };
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}

        >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                <Form >
                    <>
                        <HeaderPart>
                            Add Appointment
                            <CloseIcon onClick={onClose} style={{ cursor: 'pointer' }} />
                        </HeaderPart>
                        <AddAppointmentContainer isExpanded={selectValue === 'New Patient'}>
                            <Component1>
                                <Texts>Patient</Texts>
                                <InputSelect
                                    name="patient"
                                    showNone={false}
                                    value={selectValue}
                                    onChange={(value) => {
                                        // setFieldValue('patient', value); 
                                        setSelectValue(value);
                                    }}
                                    options={modifiedPatients}
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Select Patient"
                                    sx={{
                                        // width: '200px',
                                        backgroundColor: palette.gery.white,
                                        boxShadow: theme.shadows[1],
                                        '& .MuiOutlinedInput-root': {
                                            height: '52px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            // border: `1px solid ${alpha(theme.palette.gery.black, 0.14)}`,
                                            borderRadius: '12px',
                                        },
                                    }}
                                />
                            </Component1>
                            {selectValue === 'New Patient' && (
                                <>
                                    <HiddenComponent>
                                        <InnerComponent>
                                            <Texts>New Patient Name</Texts>
                                            <InputComponent
                                                name="fullName"
                                                variant="outlined"
                                                placeholder="Enter Name"
                                                fullWidth
                                                value={values.fullName}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={touched.fullName && Boolean(errors.fullName)}
                                                helperText={touched.fullName && errors.fullName}
                                                sx={{
                                                    backgroundColor: palette.gery.white,
                                                    boxShadow: shadows[1],
                                                    '& .MuiOutlinedInput-root': {
                                                        padding: '10px 0px',
                                                        borderRadius: '12px',
                                                        // border: `1px solid ${alpha(palette.gery.black, 0.14)}`,
                                                        // alignItems: 'flex-start',
                                                        height: '52px',
                                                    },
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: `1px solid ${alpha(palette.gery.black, 0.14)}`,
                                                    },
                                                }}
                                            />
                                        </InnerComponent>
                                        <InnerComponent>
                                            <Texts>Phone Number</Texts>
                                            <InputComponent
                                                name="contactNo"
                                                variant="outlined"
                                                placeholder="Enter Number"
                                                fullWidth
                                                value={values.contactNo}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={touched.contactNo && Boolean(errors.contactNo)}
                                                helperText={touched.contactNo && errors.contactNo}
                                                sx={{
                                                    backgroundColor: palette.gery.white,
                                                    boxShadow: shadows[1],
                                                    '& .MuiOutlinedInput-root': {
                                                        padding: '10px 0px',
                                                        borderRadius: '12px',
                                                        // border: `1px solid ${alpha(palette.gery.black, 0.14)}`,
                                                        // alignItems: 'flex-start',
                                                        height: '52px',
                                                    },
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: `1px solid ${alpha(palette.gery.black, 0.14)}`,
                                                    },
                                                }}
                                            />


                                        </InnerComponent>
                                    </HiddenComponent>
                                    <Component2>
                                        <InnerComponent>
                                            <Texts>Gender</Texts>
                                            <InputSelect
                                                name="gender"
                                                showNone={false}
                                                options={genderData}
                                                value={values.gender}
                                                onChange={(value) => setFieldValue('gender', value)}
                                                onBlur={handleBlur}
                                                error={touched.gender && Boolean(errors.gender)}
                                                helperText={touched.gender && errors.gender}
                                                variant="outlined"
                                                placeholder="Select"
                                                sx={{
                                                    // width: '200px',
                                                    boxShadow: theme.shadows[1],
                                                    '& .MuiOutlinedInput-root': {
                                                        height: '52px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        // border: `1px solid ${alpha(theme.palette.gery.black, 0.14)}`,
                                                        borderRadius: '12px',
                                                    },
                                                }}
                                            />

                                        </InnerComponent>
                                        <InnerComponent>
                                            <Texts>Date of Birth</Texts>
                                            <DateTimePicker
                                                name="dateOfBirth"
                                                // label="Date of Birth"
                                                value={values.dateOfBirth ? dayjs(values.dateOfBirth) : null}
                                                onChange={(date) => setFieldValue('dateOfBirth', dayjs(date).format('DD-MM-YYYY'))}
                                                onBlur={handleBlur}
                                                error={touched.dateOfBirth && Boolean(errors.dateOfBirth)}
                                                helperText={touched.dateOfBirth && errors.dateOfBirth}
                                                type="date"
                                                sx={{
                                                    height: "52px",
                                                    borderRadius: '12px',
                                                    // padding: '10px 16px',
                                                }}
                                            />
                                        </InnerComponent>
                                    </Component2>
                                </>

                            )}


                            <Component3>
                                <InnerComponent>
                                    <Texts>Appointment Date</Texts>
                                    <DateTimePicker
                                        name="date"
                                        // label="Date of Birth"
                                        value={values.date}
                                        onChange={(value) => setFieldValue('date', value)}
                                        onBlur={handleBlur}
                                        error={touched.date && Boolean(errors.date)}
                                        helperText={touched.date && errors.date}
                                        minDate={dayjs().startOf('day')}
                                        type="date"
                                        sx={{
                                            height: "52px",
                                            borderRadius: '12px',
                                            // padding: '10px 16px',
                                        }}
                                    />
                                </InnerComponent>
                                <InnerComponent>
                                    <Texts>Appointment Timeslot</Texts>
                                    <DateTimePicker
                                        name="time"
                                        value={values.time ? dayjs(values.time, 'HH:mm') : dayjs()}
                                        onChange={(time) => setFieldValue('time', dayjs(time).format('h:mm A'))}
                                        onBlur={handleBlur}
                                        error={touched.time && Boolean(errors.time)}
                                        helperText={touched.time && errors.time}
                                        type="time"
                                    />
                                </InnerComponent>
                            </Component3>
                            <Component4>
                                <Texts>Symptoms</Texts>
                                <InputComponent
                                    name="addnotes"
                                    variant="outlined"
                                    placeholder="Enter"
                                    fullWidth
                                    multiline
                                    value={values.addnotes}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.addnotes && Boolean(errors.addnotes)}
                                    helperText={touched.addnotes && errors.addnotes}
                                    rows={4}
                                    sx={{
                                        backgroundColor: palette.gery.white,
                                        boxShadow: shadows[1],
                                        '& .MuiOutlinedInput-root': {
                                            padding: '16px 18px',
                                            borderRadius: '12px',
                                            height: '120px',
                                            alignItems: 'flex-start',
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: `1px solid ${alpha(palette.gery.black, 0.14)}`,
                                        },
                                    }}
                                />
                            </Component4>
                        </AddAppointmentContainer>
                        <FooterPart >
                            <ButtonComponent
                                variant="none"
                                startIcon={<CloseIcon sx={{ color: theme.palette.primary.main }} />}
                                onClick={onClose}
                            >
                                Cancel
                            </ButtonComponent>
                            <ButtonComponent
                                type="submit"
                                variant="outlined"
                                startIcon={<AddIcon sx={{ color: theme.palette.primary.main }} />}
                                sx={{
                                    width: '104px',
                                    height: '52px',
                                    borderRadius: '12px',
                                    padding: '10px 18px',
                                    bgcolor: theme.palette.primary.main,
                                    boxShadow: theme.shadows[1],
                                    border: `1px solid ${alpha(theme.palette.gery.black, 0.14)}`,
                                    color: theme.palette.gery.white,
                                    '&:hover': { color: theme.palette.primary.main },
                                }}
                                onClick={handleSubmit ? () => handleSubmit() : null}
                            >
                                Save
                            </ButtonComponent>
                        </FooterPart>
                    </>
                </Form>

            )}
        </Formik>
    )
}

export default AddAppointment;



