import React, { useState } from 'react'
import { HistoryContainerOne, Texts, InnerContainer, HistoryContainerTwo, Details, Label, HeaderText, Headers, Paragraph, HeaderTextContainer, Border, CircleContainer, LabelText, CircleInner } from './Style';
import DateTimePicker from 'src/components/App/DatePicker';
import theme, { palette } from 'src/config/theme';
import { alpha } from '@mui/material';
import InputSelect from 'src/components/shared/Form/Select';
import { Box } from '@mui/material';
function History() {

  const All = [
    { label: 'All Events', value: 'all event' },
    { label: 'Appointments', value: 'appointments' },
    { label: 'Notes', value: 'notes' },
    { label: 'Prescription', value: 'prescription' },
  ]
  const [select, setSelect] = useState(null);
  const [selectValue, setSelectValue] = useState('');
  const handleChange = (value) => {
    setSelectValue(value);
    console.log("Selected Value:", value);
  };

  return (
    <>


      <HistoryContainerOne>
        <Texts>
          Filter Activities By:
        </Texts>
        <InnerContainer>
          <Box sx={{ width: '320px' }}>
            <DateTimePicker
              // label="Select Dategroup"
              value={select}
              onChange={setSelect}
              type="date"

              sx={{
                width: '320px',
                height: "52px",
                borderRadius: '12px',
                // padding: '10px auto',
                backgroundColor: theme.palette.gery.white,
                border: `1px solid ${alpha(theme.palette.gery.black, 0.14)}`,
                boxShadow: theme.shadows[1],
              }}
            />
          </Box>
          <InputSelect
            value={selectValue}
            options={All}
            onChange={handleChange}
            variant="outlined"
            placeholder="Choose"
            sx={{
              width: '250px',
              boxShadow: theme.shadows[1],
              '& .MuiOutlinedInput-root': {
                height: '52px',
                display: 'flex',
                alignItems: 'center',
                // border: `1px solid ${alpha(theme.palette.gery.black, 0.14)}`,
                borderRadius: '12px',
              },
            }}
          />
        </InnerContainer>

      </HistoryContainerOne>
      <HistoryContainerTwo>
        <Details>
          <LabelText>

            <Label>
              12th Oct, 10:00am
            </Label>
          </LabelText>
          <HeaderTextContainer>
            <Border>

            </Border>
            <CircleContainer>
              <CircleInner></CircleInner>
            </CircleContainer>
            <HeaderText>
              <Headers>
                Appointment Added
              </Headers>
              <Paragraph>
                The patients that helps Practices serve more people with the
                same resources they have now, or less
              </Paragraph>

            </HeaderText>
          </HeaderTextContainer>

        </Details>
        <Details>
          <LabelText>


            <Label>
              12th Oct, 10:00am
            </Label>
          </LabelText>
          <HeaderTextContainer sx={{ height: '180px' }}>
            <Border>

            </Border>
            <CircleContainer>
              <CircleInner></CircleInner>
            </CircleContainer>
            <HeaderText>
              <Headers>
                Prescription Added
              </Headers>
              <Paragraph sx={{}}>
                Prescription:
              </Paragraph>
              <Paragraph>
                LapidCrim 50mg (1-1-0) <br />
                Sinthol 50mg (1-0-1) <br />
                Dolo 350 (1-1-1)
              </Paragraph>

            </HeaderText>
          </HeaderTextContainer>

        </Details>
        <Details>
          <LabelText>

            <Label>
              12th Oct, 10:00am
            </Label>
          </LabelText>
          <HeaderTextContainer sx={{ height: '130px' }}>
            <Border>

            </Border>
            <CircleContainer>
              <CircleInner></CircleInner>
            </CircleContainer>
            <HeaderText>
              <Headers>
                Notes Added
              </Headers>
              <Paragraph>
                Patient have diabetes.
              </Paragraph>

            </HeaderText>
          </HeaderTextContainer>

        </Details>
        <Details>
          <LabelText>

            <Label>
              12th Oct, 10:00am
            </Label>
          </LabelText>
          <HeaderTextContainer sx={{ height: '100px' }}>
            <Border>

            </Border>
            <CircleContainer>
              <CircleInner></CircleInner>
            </CircleContainer>
            <HeaderText>
              <Box sx={{ display: 'flex', gap: '10px' }}>
                <Headers>
                  Payments Generated of Appointment
                </Headers>
                <Headers sx={{ color: palette.primary.main, borderBottom: `2px solid ${palette.primary.main}` }}>
                  Download Bill
                </Headers>
              </Box>

            </HeaderText>
          </HeaderTextContainer>

        </Details>
        <Details>
          <LabelText>

            <Label>
              12th Oct, 10:00am
            </Label>
          </LabelText>
          <HeaderTextContainer>
            <Border>

            </Border>
            <CircleContainer>
              <CircleInner></CircleInner>
            </CircleContainer>
            <HeaderText>
              <Headers>
                New Patient Added
              </Headers>
              <Paragraph>
                The patients that helps Practices serve more people with the
                same resources they have now, or less              </Paragraph>

            </HeaderText>
          </HeaderTextContainer>

        </Details>
        <Details >
          <LabelText>

            <Label>
              12th Oct, 10:00am
            </Label>
          </LabelText>
          <HeaderTextContainer sx={{ height: '60px' }}>
            <Border>

            </Border>
            <CircleContainer>
              <CircleInner></CircleInner>
            </CircleContainer>
            <HeaderText>
              <Box sx={{ display: 'flex', gap: '10px' }}>
                <Headers>
                  Payments Generated of Appointment
                </Headers>
                <Headers sx={{ color: palette.primary.main, borderBottom: `2px solid ${palette.primary.main}` }}>
                  Download Bill
                </Headers>
              </Box>

            </HeaderText>
          </HeaderTextContainer>

        </Details>
      </HistoryContainerTwo>

    </>

  )
}

export default History;
