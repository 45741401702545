import React, { useState } from 'react';
import { AddContainer, Component3, Texts, HeaderPart, FooterPart } from './Style';
import InputSelect from 'src/components/shared/Form/Select';
import theme from 'src/config/theme';
import { alpha } from '@mui/material';
import InputComponent from 'src/components/shared/Form/Input';
import NumberInput from 'src/components/shared/Form/Number';
import DateTimePicker from 'src/components/App/DatePicker/index.js';
import { ReactComponent as CloseIcon } from '../../../../../../src/assets/images/close-circle.svg';
import { ReactComponent as AddIcon } from '../../../../../../src/assets/images/add-square.svg';
import ButtonComponent from 'src/components/shared/Button';
import { palette, shadows } from 'src/config/theme.js';


function TreatmentTabForm({ onClose }) {
    const [followUpCount, setFollowUpCount] = useState(1);
    const [treatmentSchedule, setTreatmentSchedule] = useState({
        date: null,
        time: null,
    });
    const handleScheduleChange = (key, value) => {
        setTreatmentSchedule((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const [select, setSelect] = useState({
        type: 'visiting',
        days: '',
    })
    const handleChange = (name, value) => {
        setSelect((prev) => ({
            ...prev,
            [name]: value,
        }))
    }

    const Types = [
        { label: 'Visiting', value: 'visiting' },
        { label: 'Sitting', value: 'sitting' },
    ];
    const Days = [
        { label: 'Monday', value: 'mon' },
        { label: 'Tuesday', value: 'Tue' },
        { label: 'Wednesday', value: 'Wed' },
        { label: 'Thursday', value: 'Thu' },
        { label: 'Friday', value: 'fri' },
        { label: 'Saturday', value: 'sat' },
        { label: 'Sunday', value: 'sun' },
    ]
    return (
        <>
            <HeaderPart>
                Add Treatment
                <CloseIcon onClick={onClose} style={{ cursor: 'pointer' }} />
            </HeaderPart>
            <AddContainer>
                <Component3>
                    <Texts>Type</Texts>
                    <InputSelect
                        value={select.type}
                        options={Types}
                        onChange={(value) => handleChange('type', value)}
                        variant="outlined"
                        // placeholder="Select"
                        sx={{
                            // width: '200px',
                            boxShadow: theme.shadows[1],
                            '& .MuiOutlinedInput-root': {
                                height: '52px',
                                display: 'flex',
                                alignItems: 'center',
                                // border: `1px solid ${alpha(theme.palette.gery.black, 0.14)}`,
                                borderRadius: '12px',
                            },
                        }}
                    />

                </Component3>
                <Component3>
                    <Texts>Treatment Name</Texts>
                    <InputComponent
                        placeholder="Enter"
                        variant="outlined"
                        sx={{
                            backgroundColor: theme.palette.gery.white,
                            boxShadow: theme.shadows[1],
                            '& .MuiOutlinedInput-root': {
                                // padding: '10px 18px',
                                borderRadius: '12px',
                                height: '52px',
                                // border: `1px solid ${alpha(palette.gery.black, 0.14)}`,
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: `1px solid ${alpha(theme.palette.gery.black, 0.14)}`,
                            },
                        }}
                    />
                </Component3>
                <Component3>
                    <Texts>Total Visits</Texts>
                    <NumberInput
                        fullWidth
                        width="430px" height="50px"
                        value={followUpCount}
                        onChange={setFollowUpCount}
                    />
                </Component3>

                {select.type === 'visiting' ? (
                    <>
                        <Component3>
                            <Texts>When visit</Texts>
                            <InputSelect
                                placeholder="Select Multiple Days"
                                value={select.days}
                                options={Days}
                                onChange={(value) => handleChange('days', value)}
                                variant="outlined"
                                sx={{
                                    // width: '200px',
                                    boxShadow: theme.shadows[1],
                                    '& .MuiOutlinedInput-root': {
                                        height: '52px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        // border: `1px solid ${alpha(theme.palette.gery.black, 0.14)}`,
                                        borderRadius: '12px',
                                    },
                                }}


                            />
                        </Component3>
                        <Component3>
                            <Texts>Time slot</Texts>
                            <DateTimePicker
                                value={treatmentSchedule.time}
                                onChange={(value) => handleScheduleChange('time', value)}
                                type="time"
                            />

                        </Component3>
                    </>
                ) : <>
                    <Component3>
                        <Texts>First Treatment Date</Texts>
                        <DateTimePicker
                            // label="Date of Birth"
                            value={treatmentSchedule.date}
                            onChange={(value) => handleScheduleChange('date', value)}
                            fullWidth
                            type="date"
                            sx={{
                                height: "52px",
                                borderRadius: '12px',
                                // padding: '10px 16px',
                            }}
                        />
                    </Component3>
                    <Component3>
                        <Texts>First Treatment Time slot</Texts>
                        <DateTimePicker
                            value={treatmentSchedule.time}
                            onChange={(value) => handleScheduleChange('time', value)}
                            type="time"
                        />

                    </Component3>
                </>}

            </AddContainer>
            <FooterPart >
                <ButtonComponent
                    variant="none"
                    startIcon={<CloseIcon sx={{ color: palette.primary.main }} />}
                    onClick={onClose}
                >
                    Cancel
                </ButtonComponent>
                <ButtonComponent
                    type="submit"
                    variant="outlined"
                    startIcon={<AddIcon sx={{ color: palette.primary.main }} />}
                    sx={{
                        width: '104px',
                        height: '52px',
                        borderRadius: '12px',
                        padding: '10px 18px',
                        bgcolor: palette.primary.main,
                        boxShadow: shadows[1],
                        border: `1px solid ${alpha(palette.gery.black, 0.14)}`,
                        color: palette.gery.white,
                        '&:hover': { color: palette.primary.main },
                    }}
                // onClick={handleSubmit ? () => handleSubmit() : null}
                >
                    Save
                </ButtonComponent>
            </FooterPart>
        </>
    )
}

export default TreatmentTabForm
