import React, { useState } from "react";
import CustomTable from "src/components/App/AppGrid/CustomTable/CustomTable";
import { Avatar, Chip, Box, alpha } from "@mui/material";
import theme from "src/config/theme";
import ButtonComponent from "src/components/shared/Button";
import DialogBox from "src/components/shared/Dialog/DialogBox";
import { ReactComponent as Edit } from '../../../../../assets/images/edit-2.svg';
import { ReactComponent as Delete } from '../../../../../assets/images/trash.svg';
import { ReactComponent as Add } from '../../../../../assets/images/add-square.svg';
import { ReactComponent as View } from '../../../../../assets/images/eye.svg';
import { ReactComponent as Download } from '../../../../../assets/images/document-download.svg'
import ReportTabForm from "../Forms/ReportTabForm";
import NoDataFound from "../../NoDataFound/NoDataFound";


const columns = [
  { id: "reports", label: "Reports", sx: { width: "320px", minWidth: "150px", textAlign: 'left' } },
  { id: "reportName", label: "Report Name", sx: { width: "930px", textAlign: 'left' }, },
  { id: "addedDate", label: "Added Date", sx: { "& .css-axw7ok": { justifyContent: 'center' } } },
];
const data = [
  {
    reports: 'Blood Report',
    reportName: 'ABC.pdf',
    addedDate: '15th Oct, 10:00am',
  },

];
const actions = [
  { label: "View", icon: <View /> },
  { label: "Download", icon: <Download /> },
  { label: "Edit", icon: <Edit />, onClick: (row) => alert(`Viewing ${row.patient}`) },
  { label: "Delete", icon: <Delete />, onClick: (row) => alert(`Attending ${row.patient}`) },
];

const Reports = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Box sx={{
        height: '756px',
        boxShadow: theme.shadows[1],
        borderRadius: '10px',
        border: `1px solid ${alpha(theme.palette.gery.black, 0.1)}`,
        backgroundColor: theme.palette.gery.white,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative'
      }}>
        <CustomTable
          columns={columns}
          data={data}
          actions={actions}
          headerButton={
            <ButtonComponent
              variant="contained"
              endIcon={<Add />}
              onClick={handleOpen}
              sx={{
                background: theme.palette.primary.main,
                border: `1px solid ${alpha(theme.palette.gery.black, 0.14)}`,
                boxShadow: theme.shadows[1],
                width: '137px',
                height: '36px',
                padding: '6px 6px 6px 10px',
                borderRadius: '12px',
                ...theme.typography.h5,
              }}
            >Add Report</ButtonComponent>
          }
          noDataComponent={
            <NoDataFound
              title="No Reports Found!"
              buttonText="Add Report"
              buttonIcon={Add}
              onClick={handleOpen}
            />
          }
        />
        <DialogBox open={open} onClose={handleClose} title="Add Report">
          <ReportTabForm onClose={handleClose} />
        </DialogBox>
      </Box>
    </>
  )
};

export default Reports;

