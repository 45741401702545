import { styled } from "@mui/material/styles";
import { palette, shadows, typography } from "src/config/theme";
import { Avatar, Typography, alpha, Box, Chip } from "@mui/material";
import SwitchComponent from "src/components/shared/Form/Switch";
import theme from 'src/config/theme';
import { Link } from "react-router-dom";
import ButtonComponent from "src/components/shared/Button";

export const Texts = styled(Typography)({
    color: palette.gery.black,
    ...theme.typography.smallRegular,
});


export const AddContainer = styled(Box)(({ isExpanded, theme }) => ({
    backgroundColor: theme.palette.gery.white,
    height: 'auto',
    padding: '24px 20px 28px 20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    transition: 'height 0.3s ease-in-out'
}));


export const InnerComponent = styled(Box)({
    display: 'flex',
    gap: '4px',
    flexDirection: 'column',
    height: '76px',
    width: '204px',
})
export const Component3 = styled(Box)({
    // height: '76px',
    display: 'flex',
    gap: '4px',
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: '430px',
    height: '76px'
});
export const Component4 = styled(Box)({
    width: '430px',
    // height: '144px',
    display: 'flex',
    gap: '4px',
    flexDirection: 'column'
});

export const PatientViewContainer = styled(Box)({
    width: '141px',
    height: '52px',
    borderRadius: '12px',
    border: `1px solid ${alpha(palette.gery.black, 0.14)}`,
    boxShadow: shadows[1],
    padding: '10px 18px',
    backgroundColor: palette.gery.white,
    display: 'flex',
    gap: '6px',
    alignItems: 'center',
    justifyContent: 'center'

})

export const Text = styled(Typography)({
    color: palette.gery.black,
    ...theme.typography.h5,
})

export const Switch = styled(Box)({
    width: '42px'
})

export const Component5 = styled(Box)({
    width: '430px',
    height: '76px',
    display: 'flex',
    alignItems: 'center',
    gap: '22px',
});
export const DropZone = styled(Box)({
    border: `1px solid ${alpha(theme.palette.gery.black, 0.14)}`,
    height: '180px',
    padding: '16px 18px',
    borderRadius: "12px",
    padding: "20px",
    textAlign: "center",
    cursor: "pointer",
    backgroundColor: theme.palette.primary.light4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '14px',
});

export const NextFollowUpBox = styled(Box)({
    width: '430px',
    height: '120px',
    borderRadius: '12px',
    padding: '16px 18px',
    backgroundColor: theme.palette.primary.light4,
    border: `1px solid ${alpha(theme.palette.gery.black, 0.14)}`,
    boxShadow: theme.shadows[1],
});

export const NextFollowUpText = styled(Typography)({
    ...theme.typography.body1,
    color: `${alpha(theme.palette.gery.black, 0.7)}`
})
export const SittingOneBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

})

export const SittingText = styled(Typography)({
    ...theme.typography.body1,
    color: theme.palette.gery.black
})
export const StatusContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
})
export const Status = styled(Chip)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 18px 8px 10px',
    borderRadius: '100px',
    background: 'linear-gradient(0deg, rgba(150, 57, 150, 0.12) 0%, rgba(150, 57, 150, 0.12) 100%), #FFF',
    ...typography.small,
    color: palette.action.purpleDark
})

export const StatusIcon = styled(Avatar)({
    width: '24px',
    height: '24px',
    color: 'green',
    backgroundColor: 'transparent',
    cursor: 'pointer'
})

export const LinkText = styled(Typography)({
    ...typography.h6,
    color: palette.primary.main,
    lineHeight: 'normal !important',
    borderBottom: `2px solid ${palette.primary.main}`,
    cursor: 'pointer'
})
export const AvatarIcon = styled(Avatar)({
    width: '64px',
    height: '64px',
    padding: '10px',
    borderRadius: '40px',
    background: 'linear-gradient(0deg, rgba(150, 57, 57, 0.18), rgba(150, 57, 57, 0.18)), #FFFFFF',
    marginBottom: '5px'
})

export const VisitComponent = styled(Box)({
    width: '430px',
    // height: '144px',
    display: 'flex',
    gap: '24px',
    flexDirection: 'column'

})

export const EditContainer = styled(Box)({
    display: 'flex',
    gap: '10px',
    width: '950px',
    height: '428px',
})
export const EditLine = styled(Box)({
    height: '428px',
    border: `1px solid ${alpha(theme.palette.gery.black, 0.14)}`,
})

export const ProfileContainer = styled(Box)({
    width: '120px',
    height: '144px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '-16px',
    position: 'relative'
})

export const ProfileAvatar = styled(Avatar)({
    width: '120px',
    height: '120px',
    borderRadius: '12px',
    padding: '10px',
    backgroundColor: theme.palette.primary.light3,
    fontSize: '44px',
    color: theme.palette.primary.main,
})

export const ProfileIcon = styled(Avatar)({
    width: '40px',
    height: '40px',
    borderRadius: '100px',
    border: `1.5px solid ${theme.palette.gery.white}  `,
    backgroundColor: theme.palette.primary.light3,
    color: theme.palette.primary.main,
    position: 'absolute',
    bottom: -10,
    cursor: 'pointer'
})
export const TagsContainer = styled(Box)({
    width: '420px',
    height: '76px',
    display: 'flex',
    flexDirection: 'column !important',
    gap: '4px',

})
export const Component2 = styled(Box)({
    height: '76px',
    display: 'flex',
    gap: '22px',
    justifyContent: 'space-between',
    width: '420px'
});
export const HightContainer = styled(Box)({
    width: '198px',
    height: '52px',
    borderRadius: '1px',
    padding: '10px 18px',
    display: 'flex',
    gap: '20px',
    backgroundColor: theme.palette.gery.white,
    border: `1px solid ${alpha(palette.gery.black, 0.14)}`,
    borderRadius: '12px',
    boxShadow: theme.shadows[1],
    alignItems: 'center'
})
export const HightInner = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '61px',
    height: '22px',
})

export const HeaderPart = styled(Box)({

    backgroundColor: theme.palette.primary.light3,
    color: theme.palette.gery.black,
    ...theme.typography.h3,
    borderBottom: `1px solid ${theme.palette.primary.light1} !important`,
    padding: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '64px',
})

export const FooterPart = styled(Box)({
    height: "100px",
    padding: '24px 20px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '16px',
    borderTop: `1px solid ${theme.palette.primary.light1} !important`
})

export const CancelButton = styled(ButtonComponent)({

})

export const SaveButton = styled(ButtonComponent)({
    width: '104px',
    height: '52px',
    borderRadius: '12px',
    padding: '10px 18px',
    backgroundColor: palette.primary.main,
    boxShadow: shadows[1],
    border: `1px solid ${alpha(palette.gery.black, 0.14)}`,
    color: palette.gery.white,
    '&:hover': { color: palette.primary.main },

})


