import React, { useState } from 'react';
import { AddContainer, Texts, Component4, Component3, HeaderPart, FooterPart } from './Style.js';
import { useTheme } from '@emotion/react';
import InputComponent from 'src/components/shared/Form/Input/index.js';
import { palette, shadows } from 'src/config/theme.js';
import { alpha } from '@mui/material';
import DateTimePicker from 'src/components/App/DatePicker/index.js';
import { ReactComponent as CloseIcon } from '../../../../../../src/assets/images/close-circle.svg';
import { ReactComponent as AddIcon } from '../../../../../../src/assets/images/add-square.svg';
import ButtonComponent from 'src/components/shared/Button/index.js';
import { postMedicalRecordsRequest } from 'src/modules/app/store/appActions.js';



function AppointmentTabForm({ onClose }) {
    const theme = useTheme();
    const [dob, setDob] = useState(null);

    const [appointmentTime, setAppointmentTime] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);

    const handleTimeChange = (newTime) => {
        setSelectedTime(newTime);
    };
    const [selectValue, setSelectValue] = useState('');
    const [selectValue2, setSelectValue2] = useState('');

    const handleChange2 = (value) => {
        setSelectValue2(value);
    }

    const handleChange = (value) => {
        setSelectValue(value);
    };

    return (

        <>
            <HeaderPart>
                Add Appointment
                <CloseIcon onClick={onClose} style={{ cursor: 'pointer' }} />
            </HeaderPart>
            <AddContainer >


                <Component3>

                    <Texts>Appointment Date</Texts>
                    <DateTimePicker
                        // label="Date of Birth"
                        value={appointmentTime}
                        onChange={setAppointmentTime}
                        fullWidth
                        type="date"
                        sx={{
                            height: "52px",
                            borderRadius: '12px',
                            // padding: '10px 16px',
                        }}
                    />



                </Component3>
                <Component3>
                    <Texts>Appointment Timeslot</Texts>
                    <DateTimePicker
                        value={selectedTime}
                        onChange={handleTimeChange}
                        type="time"
                    />
                </Component3>
                <Component4>
                    <Texts>Symptoms</Texts>
                    <InputComponent
                        variant="outlined"
                        placeholder="Enter"
                        fullWidth
                        multiline
                        rows={4}
                        sx={{
                            backgroundColor: palette.gery.white,
                            boxShadow: shadows[1],
                            '& .MuiOutlinedInput-root': {
                                padding: '16px 18px',
                                borderRadius: '12px',
                                height: '120px',
                                alignItems: 'flex-start',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: `1px solid ${alpha(palette.gery.black, 0.14)}`,
                            },
                        }}
                    />
                </Component4>
            </AddContainer>

            <FooterPart >
                <ButtonComponent
                    variant="none"
                    startIcon={<CloseIcon sx={{ color: theme.palette.primary.main }} />}
                    onClick={onClose}
                >
                    Cancel
                </ButtonComponent>
                <ButtonComponent
                    type="submit"
                    variant="outlined"
                    startIcon={<AddIcon sx={{ color: theme.palette.primary.main }} />}
                    sx={{
                        width: '104px',
                        height: '52px',
                        borderRadius: '12px',
                        padding: '10px 18px',
                        bgcolor: theme.palette.primary.main,
                        boxShadow: theme.shadows[1],
                        border: `1px solid ${alpha(theme.palette.gery.black, 0.14)}`,
                        color: theme.palette.gery.white,
                        '&:hover': { color: theme.palette.primary.main },
                    }}
                // onClick={handleSubmit ? () => handleSubmit() : null}
                >
                    Save
                </ButtonComponent>
            </FooterPart>
        </>
    )
}

export default AppointmentTabForm;



