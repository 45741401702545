import React, { useState } from 'react'
import { AddContainer, SittingOneBox, SittingText, Status, StatusContainer, VisitComponent, StatusIcon, LinkText, HeaderPart, FooterPart } from './Style'
import { ReactComponent as TickCircle } from '../../../../../assets/images/tick-circle.svg';
import { palette, shadows } from 'src/config/theme';
import { ReactComponent as CloseIcon } from '../../../../../../src/assets/images/close-circle.svg';
import { ReactComponent as AddIcon } from '../../../../../../src/assets/images/add-square.svg';
import ButtonComponent from 'src/components/shared/Button';
import { alpha } from '@mui/material';

function TypeVisiting({ onClose }) {
    const [isCompleted, setIsCompleted] = useState(false);
    const handleStatusClick = () => {
        setIsCompleted(true);
    };

    return (
        <>
            <HeaderPart>
                Blood Report Treatments
                <CloseIcon onClick={onClose} style={{ cursor: 'pointer' }} />
            </HeaderPart>
            <AddContainer>
                <VisitComponent>
                    <SittingOneBox>
                        <SittingText>
                            1. 15th Oct, 10:00am
                        </SittingText>
                        <StatusContainer>
                            <Status
                                label={isCompleted ? 'Completed' : 'Pending'}
                                sx={{
                                    background: isCompleted ? 'linear-gradient(0deg, rgba(57, 150, 57, 0.18), rgba(57, 150, 57, 0.18))' : 'linear-gradient(0deg, rgba(150, 57, 150, 0.12) 0%, rgba(150, 57, 150, 0.12) 100%), #FFF',
                                    color: isCompleted ? palette.action.green : palette.action.purpleDark,
                                }}
                            />
                            {!isCompleted && (
                                <StatusIcon onClick={handleStatusClick} style={{ cursor: 'pointer' }}>
                                    <TickCircle />
                                </StatusIcon>
                            )}

                        </StatusContainer>

                    </SittingOneBox>
                    <SittingOneBox>
                        <SittingText>
                            2. 15th Oct, 10:00am
                        </SittingText>
                        <StatusContainer>
                            <Status label='Pending' />
                        </StatusContainer>

                    </SittingOneBox>
                    <SittingOneBox>
                        <SittingText>
                            3. 15th Oct, 10:00am
                        </SittingText>
                        <StatusContainer>
                            <Status label='Pending' />
                        </StatusContainer>

                    </SittingOneBox>
                    <SittingOneBox>
                        <SittingText>
                            4. 15th Oct, 10:00am
                        </SittingText>
                        <StatusContainer>
                            <Status label='Pending' />
                        </StatusContainer>

                    </SittingOneBox>
                    <SittingOneBox>
                        <SittingText>
                            5. 15th Oct, 10:00am
                        </SittingText>
                        <StatusContainer>
                            <Status label='Pending' />
                        </StatusContainer>

                    </SittingOneBox>
                </VisitComponent>


            </AddContainer>
            <FooterPart >
                <ButtonComponent
                    variant="none"
                    startIcon={<CloseIcon sx={{ color: palette.primary.main }} />}
                    onClick={onClose}
                >
                    Cancel
                </ButtonComponent>
                <ButtonComponent
                    type="submit"
                    variant="outlined"
                    startIcon={<AddIcon sx={{ color: palette.primary.main }} />}
                    sx={{
                        width: '104px',
                        height: '52px',
                        borderRadius: '12px',
                        padding: '10px 18px',
                        bgcolor: palette.primary.main,
                        boxShadow: shadows[1],
                        border: `1px solid ${alpha(palette.gery.black, 0.14)}`,
                        color: palette.gery.white,
                        '&:hover': { color: palette.primary.main },
                    }}
                // onClick={handleSubmit ? () => handleSubmit() : null}
                >
                    Save
                </ButtonComponent>
            </FooterPart>
        </>
    )
}

export default TypeVisiting;
