import { color, display, styled } from '@mui/system';
import images from 'src/config/images';
import ListItemButton from '@mui/material/ListItemButton';
import theme, { palette, typography } from 'src/config/theme';
import { Box, alpha } from '@mui/material';
export const LogoContainer = styled(Box)({
  width: '280px',
  height: '84px',
  borderWidth: '0px 1px 1px 0px',
  borderStyle: 'solid',
  borderColor: `${alpha(palette.gery.black, 0.1)}`,
  backgroundColor: palette.gery.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
})
export const Logo = styled('img')({
  // width: '189px',
  height: '65px'
});
Logo.defaultProps = {
  src: images.logoW,
};
export const Logo2 = styled('img')({
  width: '70px',
  height: '22px',
  margin: '0 auto',
  // paddingTop: 15,
  // paddingBottom: 15,
});
Logo2.defaultProps = {
  src: images.logo,
};
export const ListItem = styled(ListItemButton)(({ isPermanentOpen }) => ({
  '& .MuiListItemText-root': {
    color: theme.palette.gery.black,
    // opacity: isPermanentOpen ? 0.6 : 0,
    transition: 'opacity 0.1s ease-in-out',
    '& .MuiTypography-root': {
      fontSize: typography.h5.fontSize,
      lineHeight: typography.h5.lineHeight,
    },
  },
  '& .MuiListItemIcon-root': {
    minWidth: '44px',
    opacity: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  padding: isPermanentOpen ? '12px 24px' : '12px',
  justifyContent: isPermanentOpen ? 'flex-start' : 'center',
  '&.active': {
    backgroundColor: theme.palette.primary.light1,
    '& .MuiListItemText-root': {
      opacity: 1,
      color: theme.palette.primary.main
    },
    '& .MuiListItemIcon-root': {
      opacity: 1,
      color: theme.palette.primary.main,

      '& svg': {
        color: theme.palette.primary.main,
        fill: theme.palette.primary.main,
      },

    },

    '&::before': {
      display: 'none',
    },
    '&::after': {
      display: 'none',
    },
  },
}));
